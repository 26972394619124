import { Route, Routes } from 'react-router-dom'
import App from '../components/App'
import Character from '../components/Character/Character'

export const CharacterRoutes = (): React.ReactElement => (
  <Routes>
    <Route path={'/characters'} element={<App />}>
      <Route path='' element={<Character />} />
    </Route>
  </Routes>
)
