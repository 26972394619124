import { useEffect } from 'react'
import { Row, Col, Card, Table, Button } from 'react-bootstrap'
import Loading from '../Loading/Loading'
import { useGetLessonsPlansQuery } from '../../store/api/lessonplans/lessonplans.api'
import LessonPlansTable from './LessonPlansTable/LessonPlansTable'
import { useNavigate } from 'react-router-dom'

const LessonPlans = (): JSX.Element => {
  const { isLoading, data: lessonsPlans, refetch } = useGetLessonsPlansQuery()
  const navigate = useNavigate()
  useEffect(() => {
    refetch()
  }, [])

  return (
    <div>
      {isLoading && <Loading />}
      <Row className='row-sm mt-5'>
        <Col lg={12}>
          <Card className='custom-card'>
            <Card.Header>
              <Col xs={6} md={12}>
                <h3 className='card-title mb-1'>Lessons Plans</h3>
              </Col>
              <Col className='d-flex'>
                <Button onClick={() => navigate('/lessons-plans/create')}>
                  Create Lessons Plans
                </Button>
              </Col>
            </Card.Header>
            <Card.Body>
              <div className='table-responsive'>
                <Table className='table table-bordered text-nowrap text-md-nowrap mg-b-0'>
                  <thead>
                    <tr className='text-center'>
                      <th>lesson Plan Id</th>
                      <th>Name</th>
                      <th>overview</th>
                      <th>badge</th>
                      <th>takeaways</th>
                      <th>outcomes</th>
                      <th>Created at</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {lessonsPlans?.length
                      ? lessonsPlans?.map(lessonPlans => {
                          return (
                            <LessonPlansTable
                              key={lessonPlans.id}
                              lessonPlans={lessonPlans}
                              refetch={refetch}
                            />
                          )
                        })
                      : null}
                  </tbody>
                </Table>
              </div>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </div>
  )
}

export default LessonPlans
