import { FC } from 'react'
import { IUpdateActivateProps } from './modal'
import {
  useUpdateActivateMutation,
  useUpdateDeactivateMutation
} from '../../store/api/users/users.api'
import { toast } from 'react-toastify'

const UpdateActivate: FC<IUpdateActivateProps> = ({ checked, id, type, refetch }) => {
  const [updateActivate] = useUpdateActivateMutation()
  const [updateDeactivate] = useUpdateDeactivateMutation()

  const updateActivateToggleFunc = async (isChecked: boolean, id: number): Promise<void> => {
    try {
      if (isChecked) {
        await updateActivate({ type, id })
      } else {
        await updateDeactivate({ type, id })
      }
      toast.success(`${isChecked ? 'Activated' : 'Deactivated'} successfully.`)
    } catch (error) {
      console.log(error)
    }

    refetch()
  }

  return (
    <div className='form-switch mt-1'>
      <input
        className='form-check-input'
        type='checkbox'
        id='flexSwitchCheckChecked'
        onChange={({ target }) => updateActivateToggleFunc(target.checked, id)}
        defaultChecked={checked}
      />
    </div>
  )
}

export default UpdateActivate
