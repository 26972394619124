import React, { useEffect } from 'react'
import { Col, Row, Card } from 'react-bootstrap'
import PageHeader from '../../layouts/PageHeader/PageHeader'
import {
  useGetClassesLessonStatsQuery,
  useGetGlobalStatsQuery,
  useGetLatestUsersQuery,
  useGetLessonsStatsQuery,
  useGetResourcesStatsQuery
} from '../../store/api/deshboard/deshboard.api'
import DeshboardGlobalStats from './DeshboardGlobalStats/DeshboardGlobalStats'
import LatestNewUsers from './LatestNewUsers/LatestNewUsers'
import LessonClassActivity from './LessonClassActivity/LessonClassActivity'
import NewLessonsStats from './NewLessonsStats/NewLessonsStats'
import NewResourcesStats from './NewResourcesStats/NewResourcesStats'

export default function Dashboard(): JSX.Element {
  const globalStats = useGetGlobalStatsQuery()
  const LstestUsers = useGetLatestUsersQuery()
  const ClassesLessonStats = useGetClassesLessonStatsQuery()
  const LessonsStats = useGetLessonsStatsQuery()
  const ResourcesStats = useGetResourcesStatsQuery()

  useEffect(() => {
    globalStats.refetch()
    LstestUsers.refetch()
    ClassesLessonStats.refetch()
    LessonsStats.refetch()
    ResourcesStats.refetch()
  }, [])

  return (
    <div>
      <PageHeader
        componentName='Dashboard 01'
        staticName='Home'
        activePage='Dashboard 01'
        createPageUrl=''
      />
      <Row>
        <Col lg={12} md={12} sm={12} xl={12}>
          <Row>
            {globalStats?.data?.length > 0 &&
              globalStats?.data?.map((item, index) => (
                <DeshboardGlobalStats globalStats={item} key={index} />
              ))}
          </Row>
        </Col>
      </Row>
      <Row>
        <Col xl={4} md={12}>
          <Card className=' overflow-hidden'>
            <Card.Header className='card-header'>
              <div>
                <h3 className='card-title'>Latest New Users</h3>
              </div>
            </Card.Header>
            <Card.Body className='card-body pb-0 pt-4 card-Scroll overflow-auto'>
              {LstestUsers?.data?.length > 0 &&
                LstestUsers?.data?.map(LstestUser => (
                  <LatestNewUsers key={LstestUser.id} LstestUser={LstestUser} />
                ))}
            </Card.Body>
          </Card>
        </Col>
        <Col xl={4} md={12} className='col-xl-4 col-md-12'>
          <Card className='card'>
            <Card.Header className='card-header'>
              <h4 className='card-title fw-semibold '>Lessons Stats</h4>
            </Card.Header>
            <Card.Body className='card-body pb-0 card-Scroll overflow-auto'>
              <ul className='task-list '>
                {LessonsStats?.data?.length > 0 &&
                  LessonsStats?.data?.map(lessonsStat => (
                    <NewLessonsStats key={lessonsStat.id} lessonsStat={lessonsStat} />
                  ))}
              </ul>
            </Card.Body>
          </Card>
        </Col>
        <Col xl={4} md={12}>
          <Card>
            <Card.Header className='card-header'>
              <h4 className='card-title fw-semibold '>Lesson Class Activity</h4>
            </Card.Header>
            <Card.Body className='card-body pb-0 card-Scroll overflow-auto'>
              <ul className='task-list '>
                {ClassesLessonStats?.data?.length > 0 &&
                  ClassesLessonStats?.data?.map(Classlesson => (
                    <LessonClassActivity key={Classlesson.id} Classlesson={Classlesson} />
                  ))}
              </ul>
            </Card.Body>
          </Card>
        </Col>
        <Col xl={4} md={12}>
          <Card className=' overflow-hidden'>
            <Card.Header className='card-header'>
              <div>
                <h3 className='card-title'>Resources Stats</h3>
              </div>
            </Card.Header>
            <Card.Body className='card-body pb-0 pt-4 card-Scroll overflow-auto'>
              {ResourcesStats?.data?.length > 0 &&
                ResourcesStats?.data?.map(resourceStat => (
                  <NewResourcesStats key={resourceStat.id} resourceStat={resourceStat} />
                ))}
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </div>
  )
}
