import { FC } from 'react'
import { stringToDate } from '../../../utils/date'
import { IGetClassesLessonStats } from '../../../store/api/deshboard/model'

const LessonClassActivity: FC<{ Classlesson: IGetClassesLessonStats }> = ({
  Classlesson
}): JSX.Element => {
  return (
    <li>
      <i className='task-icon bg-primary'></i>
      <p>
        <b>{Classlesson?.lesson}</b> lesson assigned to a
        <br />
        <b>{Classlesson.class}</b> class
      </p>
      <p className='text-muted fs-12'>{stringToDate(Classlesson.created_at)}</p>
    </li>
  )
}

export default LessonClassActivity
