import React, { FC, useState } from 'react'
import { Card, Col, FormGroup, Row } from 'react-bootstrap'
import Modal from '../../../ui/modal/Modal'
import { ILessonPlansActivitiesList } from '../../../store/api/lessonplans/model'

const LessonPlansActivitiesList: FC<ILessonPlansActivitiesList> = ({
  lessonPlansActivities,
  setLessonPlansActivities
}): JSX.Element => {
  const formRef = React.createRef<HTMLFormElement>()
  const [isEditChoice, setIsEditChoice] = useState<boolean>(false)
  const [idChoice, setIdChoice] = useState<number>(null)

  const handleClick = (): void => {
    setLessonPlansActivities(
      lessonPlansActivities.map((choice, index) => {
        if (index === idChoice) {
          return {
            id: choice.id,
            text: formRef?.current.description.value
          }
        }
        return choice
      })
    )
    setIsEditChoice(false)
  }
  return (
    <>
      {lessonPlansActivities.length
        ? lessonPlansActivities?.map(
            (choice, index) =>
              choice.text && (
                <div key={index} className='question-answer-list text-dark mt-3 border'>
                  <span>{choice.text}</span>
                  <button
                    type='button'
                    onClick={() => {
                      setIdChoice(index)
                      setIsEditChoice(true)
                    }}
                    className='text-info'
                  >
                    Edit
                  </button>
                  <button
                    type='button'
                    onClick={() =>
                      setLessonPlansActivities(prev => {
                        return [...prev.filter(element => element.text !== choice.text)]
                      })
                    }
                    className='text-danger mx-1'
                  >
                    X
                  </button>
                </div>
              )
          )
        : null}
      {isEditChoice && (
        <Modal>
          <Card>
            <Card.Header>
              <h4 className='text-dark'>Edit Choice</h4>
            </Card.Header>
            <form ref={formRef} onSubmit={e => e.preventDefault()}>
              <Card.Body>
                <Row>
                  <Col xs={6} md={12}>
                    <FormGroup>
                      <label className='text-dark  mt-5' htmlFor='exampleInputname'>
                        Description
                      </label>
                      <input
                        name='description'
                        className='form-control'
                        defaultValue={
                          lessonPlansActivities.find((_, index) => index === idChoice)?.text
                        }
                      />
                    </FormGroup>
                  </Col>
                </Row>
              </Card.Body>
              <Card.Footer>
                <button onClick={handleClick} type='button' className='btn btn-success mt-1 me-2'>
                  Save
                </button>
                <button
                  type='button'
                  className='btn btn-danger mt-1 me-2'
                  onClick={() => setIsEditChoice(false)}
                >
                  Cancel
                </button>
              </Card.Footer>
            </form>
          </Card>
        </Modal>
      )}
    </>
  )
}

export default LessonPlansActivitiesList
