import React, { useEffect, useState } from 'react'
import { Button, Card, Col, FormGroup, Row } from 'react-bootstrap'
import 'react-calendar/dist/Calendar.css'
import 'react-clock/dist/Clock.css'
import 'react-datetime-picker/dist/DateTimePicker.css'
import { Params, useNavigate, useParams } from 'react-router-dom'
import { toast } from 'react-toastify'
import {
  useCreateMeditationMutation,
  useGetMeditationByIdQuery,
  useUpdateMeditationMutation
} from '../../../store/api/meditation/meditation.api'
import { IGetMeditionQuestion, TPostMeditationQuestion } from '../../../store/api/meditation/model'
import { IValidationRequired } from '../../../validation/model'
import { responseError, validationRequaired } from '../../../validation/validationRequaired'

const MeditationCreate = (): JSX.Element => {
  const { id }: Readonly<Params<string>> = useParams()
  const [updateMeditation] = useUpdateMeditationMutation()
  const [createMeditation] = useCreateMeditationMutation()
  const [errorRequaired, seterrorRequaired] = useState<IValidationRequired>({})
  const formRef = React.createRef<HTMLFormElement>()
  const navigate = useNavigate()

  const [meditation, setMeditation] = useState<IGetMeditionQuestion>()
  const { data, refetch } = useGetMeditationByIdQuery(id, {
    skip: !id
  })

  useEffect(() => {
    setMeditation(data)
  }, [data])

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>): Promise<void> => {
    event.preventDefault()

    const data: TPostMeditationQuestion = {
      name: formRef?.current.namee.value,
      duration: formRef?.current.duration.value,
      source: formRef?.current.source.value,
      url: formRef?.current.url.value,
      file: formRef?.current.audio.files[0]
    }
    if (id) {
      data.id = meditation.id
      const res = await updateMeditation(data)
      if ('error' in res) {
        responseError(res)
      } else {
        toast.success('Meditation was updated')
        navigate('/meditation')
      }
    } else {
      seterrorRequaired(validationRequaired(data))
      if (!Object.values(validationRequaired(data)).length) {
        const res = await createMeditation(data)
        if ('error' in res) {
          responseError(res)
        } else {
          toast.success('Meditation was created')
          navigate('/meditation')
        }
      }
    }
  }

  useEffect(() => {
    if (id) {
      refetch()
    }
  }, [id])

  return (
    <div className='m-5'>
      <Row>
        <Col lg={12} xl={12} md={12} sm={12}>
          <Card>
            <Card.Header>
              <div>
                <h2>{meditation ? 'Edit Meditation' : 'Create Meditation'} </h2>
              </div>
            </Card.Header>
            <form ref={formRef} onSubmit={e => handleSubmit(e)}>
              <Card.Body>
                <Row>
                  <Col xs={6} md={12}>
                    <FormGroup>
                      <label className='text-dark mt-5' htmlFor='exampleInputname'>
                        Name
                      </label>
                      <input
                        defaultValue={meditation?.name}
                        name='namee'
                        type='text'
                        className='form-control'
                        placeholder='Name'
                        onClick={() => seterrorRequaired({ ...errorRequaired, name: '' })}
                      />
                    </FormGroup>
                    {errorRequaired?.name && <span className='text-danger'>Name is required</span>}
                  </Col>

                  <Col xs={6} md={12}>
                    <FormGroup>
                      <label className='text-dark mt-5' htmlFor='exampleInputname'>
                        Duration
                      </label>
                      <input
                        defaultValue={meditation?.duration}
                        name='duration'
                        type='text'
                        className='form-control'
                        placeholder='Duration'
                        onClick={() => seterrorRequaired({ ...errorRequaired, duration: '' })}
                      />
                    </FormGroup>
                    {errorRequaired?.duration && (
                      <span className='text-danger'>Duration is required</span>
                    )}
                  </Col>
                  <Col xs={6} md={12}>
                    <FormGroup>
                      <label className='text-dark mt-5' htmlFor='exampleInputname'>
                        Source
                      </label>
                      <input
                        defaultValue={meditation?.source}
                        name='source'
                        type='text'
                        className='form-control'
                        placeholder='Source'
                        onClick={() => seterrorRequaired({ ...errorRequaired, source: '' })}
                      />
                    </FormGroup>
                    {errorRequaired?.source && (
                      <span className='text-danger'>Source is required</span>
                    )}
                  </Col>
                  <Col xs={6} md={12}>
                    <FormGroup>
                      <label className='text-dark mt-5' htmlFor='exampleInputname'>
                        Gif
                      </label>
                      <input
                        defaultValue={meditation?.url}
                        name='url'
                        type='text'
                        className='form-control'
                        placeholder='Url'
                        onClick={() => seterrorRequaired({ ...errorRequaired, url: '' })}
                      />
                    </FormGroup>
                    {errorRequaired?.url && <span className='text-danger'>Url is required</span>}
                  </Col>
                  <Col xs={6} md={12}>
                    <FormGroup>
                      <label className='text-dark mt-5' htmlFor='file'>
                        Audio
                      </label>
                      <input
                        id='Image'
                        name='audio'
                        type='file'
                        className='form-control'
                        placeholder='File Url'
                        accept='audio/*'
                        onClick={() => seterrorRequaired({ ...errorRequaired, file: '' })}
                      />
                    </FormGroup>
                    {errorRequaired?.file && <span className='text-danger'>File is required</span>}
                  </Col>
                </Row>
              </Card.Body>
              <Card.Footer className='text-end'>
                <button type='submit' className='btn btn-success mt-1 me-2'>
                  Save
                </button>
                <Button
                  onClick={() => navigate('/meditation')}
                  type='button'
                  className='btn btn-danger mt-1 me-2'
                >
                  Cancel
                </Button>
              </Card.Footer>
            </form>
          </Card>
        </Col>
      </Row>
    </div>
  )
}

export default MeditationCreate
