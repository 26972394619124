import { FC } from 'react'
import { IGetLatestUsers } from '../../../store/api/deshboard/model'
import { stringToDate } from '../../../utils/date'

const LatestNewUsers: FC<{ LstestUser: IGetLatestUsers }> = ({ LstestUser }): JSX.Element => {
  return (
    <div className='activity1'>
      <div className='activity-blog'>
        <div className='activity-img brround bg-primary-transparent text-primary'>
          <i className='fa fa-user-plus fs-20'></i>
        </div>
        <div className='activity-details d-flex'>
          <div>
            <b>
              <span className='text-dark fs-12'>{LstestUser.fullName} </span>
            </b>
            {LstestUser.email}
            <span className='d-flex text-muted fs-11'>{stringToDate(LstestUser.created_at)}</span>
          </div>
          <div className='ms-auto fs-13 text-dark fw-semibold'>
            <span className='badge bg-primary text-white'>{LstestUser.role.name}</span>
          </div>
        </div>
      </div>
    </div>
  )
}

export default LatestNewUsers
