import { IGetCategory } from '../../store/api/category/model'
import { IGetClassesData } from '../../store/api/class/model'
import { IGetLessonData } from '../../store/api/lesson/model'
import { IPackage } from '../../store/api/packages/model'
import { IGetQuiz } from '../../store/api/quiz/model'
import { IGetResourceDate } from '../../store/api/resource/model'
import { IGetSchool } from '../../store/api/school/model'
import { IGetSectionsClass } from '../../store/api/section/model'
import { IRoleId, IUserData } from '../../store/api/users/model'
import { ISelectData } from '../../ui/select/model'

export const selectedDataTake = (
  data:
    | IUserData[]
    | IPackage[]
    | IGetCategory[]
    | IGetLessonData[]
    | IGetQuiz[]
    | IGetClassesData[]
    | IGetResourceDate[]
    | IRoleId[]
    | IGetSchool[]
    | IGetSectionsClass[],
  setData: React.Dispatch<React.SetStateAction<ISelectData[]>>
): void => {
  if (data?.length > 0) {
    data?.forEach(element => {
      setData(prev => {
        return [
          ...prev,
          {
            label: String(element.id) + '. ' + element.name,
            value: element.id
          }
        ]
      })
    })
  }
}
