import { createApi } from '@reduxjs/toolkit/query/react'
import { IEditProfile } from './model'
import { baseQueryWithReauth } from '../apiSlice'

export const profileApi = createApi({
  reducerPath: 'profile/api',
  baseQuery: baseQueryWithReauth,
  endpoints: build => ({
    updateProfile: build.mutation<string, IEditProfile>({
      query: (data: IEditProfile) => ({
        url: '/user/change-password',
        method: 'PUT',
        body: data
      })
    })
  })
})
export const { useUpdateProfileMutation } = profileApi
