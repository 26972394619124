import { TData } from './model'

export const formDataCreate = (data: TData): FormData => {
  const formData = new FormData()
  Object.keys(data).forEach(key => {
    if (data[key]) {
      if (typeof data[key] === 'number') {
        formData.append(key, data[key].toString())
      } else if (Array.isArray(data[key]) && data[key].length > 0) {
        data[key].forEach(item => {
          formData.append(key.toString() + '[]', item)
        })
      } else if (
        typeof data[key] === 'object' &&
        !Array.isArray(data[key]) &&
        !data[key].lastModified
      ) {
        formData.append(key.toString(), JSON.stringify(data[key]))
      } else {
        formData.append(key.toString(), data[key])
      }
    }
  })
  return formData
}
