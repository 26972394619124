import { FC } from 'react'
import { Card, Button } from 'react-bootstrap'
import { ModalHeader } from '../../ui/modal/ModalHeader'
import { IRemoveItemModal } from './model'
import Modal from '../../ui/modal/Modal'
import { CustomMessageSuccsesOrError } from '../CustomMessageSuccsesOrError'

const RemoveItemModal: FC<IRemoveItemModal> = ({
  id,
  deleteItem,
  reload,
  onClose
}): JSX.Element => {
  const removeItemRequest = async (): Promise<void> => {
    try {
      const res = await deleteItem(String(id))
      CustomMessageSuccsesOrError(res)
      onClose()
    } catch (error) {
      console.log(error)
    }
    reload()
  }

  return (
    <Modal>
      <div className='py-3'>
        <ModalHeader>
          <div>
            <h2>Are you sure?</h2>
          </div>
        </ModalHeader>
        <Card className='text-center shadow-none'>
          <h5>Do you really want to delete these records? This process cannot be undone.</h5>
        </Card>
        <Card.Footer className='text-center'>
          <Button className='mx-2' onClick={() => onClose()}>
            Cancel
          </Button>
          <Button className='mx-2' variant='danger' onClick={() => removeItemRequest()}>
            Delete
          </Button>
        </Card.Footer>
      </div>
    </Modal>
  )
}

export default RemoveItemModal
