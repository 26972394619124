import Cookies from 'js-cookie'

export const setInCookies = (name: string, value: string): void => {
  Cookies.set(name, value)
}

export const getInCookies = (name: string): string | undefined => {
  return Cookies.get(name)
}

export const deleteInCookies = (name: string): void => {
  Cookies.remove(name)
}
export const setInCookiesbutCustmTime = (name: string, value: string): void => {
  Cookies.set(name, value, { expires: 15 })
}
