import { createApi } from '@reduxjs/toolkit/query/react'
import { baseQueryWithReauth } from '../apiSlice'
import { ILessonPlans, ILessonPlansPOST } from './model'

export const lessonPlansApi = createApi({
  reducerPath: 'lessonplans/api',
  baseQuery: baseQueryWithReauth,
  endpoints: build => ({
    getLessonsPlans: build.query<ILessonPlans[], void>({
      query: () => ({ url: '/admin/lesson-plans' })
    }),
    getLessonPlansById: build.query<ILessonPlans, string>({
      query: (id: string) => ({
        url: `/admin/lesson-plans/${id}`
      })
    }),
    createLessonPlans: build.mutation<void, ILessonPlansPOST>({
      query: (lessonPlans: ILessonPlansPOST) => {
        return {
          url: '/admin/lesson-plans',
          method: 'POST',
          body: lessonPlans
        }
      }
    }),
    updateLessonPlans: build.mutation<void, ILessonPlansPOST>({
      query: (lessonPlans: ILessonPlansPOST) => ({
        url: '/admin/lesson-plans',
        method: 'PUT',
        body: lessonPlans
      })
    }),
    deleteLessonPlans: build.mutation<void, string>({
      query: (id: string) => ({ url: `/admin/lesson-plans/${id}`, method: 'DELETE' })
    })
  })
})

export const {
  useGetLessonsPlansQuery,
  useGetLessonPlansByIdQuery,
  useCreateLessonPlansMutation,
  useUpdateLessonPlansMutation,
  useDeleteLessonPlansMutation
} = lessonPlansApi
