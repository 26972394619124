import { FC } from 'react'
import { stringToDate } from '../../../utils/date'
import { IGetResourcesStats } from '../../../store/api/deshboard/model'

const NewResourcesStats: FC<{ resourceStat: IGetResourcesStats }> = ({
  resourceStat
}): JSX.Element => {
  return (
    <div className='activity1'>
      <div className='activity-blog'>
        <div className='activity-img brround bg-primary-transparent text-primary'>
          <svg
            xmlns='http://www.w3.org/2000/svg'
            className='fs-20'
            height='1em'
            viewBox='0 0 448 512'
          >
            <path
              className='text-primary'
              fill='#6259ca'
              stroke='none'
              d='M64 32C28.7 32 0 60.7 0 96V416c0 35.3 28.7 64 64 64H384c35.3 0 64-28.7 64-64V96c0-35.3-28.7-64-64-64H64zM200 344V280H136c-13.3 0-24-10.7-24-24s10.7-24 24-24h64V168c0-13.3 10.7-24 24-24s24 10.7 24 24v64h64c13.3 0 24 10.7 24 24s-10.7 24-24 24H248v64c0 13.3-10.7 24-24 24s-24-10.7-24-24z'
            />
          </svg>
        </div>
        <div className='activity-details d-flex'>
          <div>
            <b>
              <span className='text-dark fs-12'>{resourceStat.name}</span>
            </b>
            <span className='d-flex text-muted fs-11'>{stringToDate(resourceStat.created_at)}</span>
          </div>
        </div>
      </div>
    </div>
  )
}

export default NewResourcesStats
