import { Button, Card, Col, Row, Table } from 'react-bootstrap'
import { ChangeEvent, useEffect, useRef, useState } from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom'
import {
  useDeleteResourceMutation,
  useGetResourceQuery,
  useSendExelFileMutation
} from '../../store/api/resource/resource.api'
import { CustomMessageSuccsesOrError } from '../../utils/CustomMessageSuccsesOrError'
import { DefaultParams, EXCEL_FORMAT, PAGESIZE } from '../../utils/constants'
import ResourceTable from './ResourceTable/ResourceTable'
import Loading from '../Loading/Loading'
import Modal from '../../ui/modal/Modal'
import { ModalHeader } from '../../ui/modal/ModalHeader'
import { UseToggleModalHOC } from '../../hooks/toggleModal'
import Pagination from '../../ui/Pagination/Pagination'
import { searchParamsData } from '../../utils/searchParamsData'
import { ISearchParams } from '../../ui/filter/model'
import Filters from '../../ui/filter/Filters'

const Resource = (): JSX.Element => {
  const inputRef = useRef<HTMLInputElement>()
  const [searchParams, setSearchParams] = useSearchParams(DefaultParams)
  const navigate = useNavigate()
  const [sendExel] = useSendExelFileMutation()
  const [resourceIds, setResourceIds] = useState<number[]>([])
  const [deleteResource] = useDeleteResourceMutation()
  const {
    data: resources,
    isLoading,
    refetch
  } = useGetResourceQuery(searchParamsData(searchParams, {} as ISearchParams))
  const { isModalVisible, toggleModal } = UseToggleModalHOC()

  const handleFileChange = async (event: ChangeEvent<HTMLInputElement>): Promise<void> => {
    const selectedFile = event.target.files?.[0]
    const formData = new FormData()

    if (selectedFile) {
      formData.append('file', selectedFile)
      const res = await sendExel(formData)
      CustomMessageSuccsesOrError(res)
      refetch()

      event.target.value = ''
    }
  }

  const fetchDeleteResource = async (ids: number[]): Promise<void> => {
    await deleteResource(ids)
    toggleModal()
    refetch()
  }

  useEffect(() => {
    refetch()
  }, [])

  return (
    <div className='mt-8'>
      {isLoading && <Loading />}
      <Filters query={searchParams} setQuery={setSearchParams} />
      <Row className=' row-sm'>
        <Col lg={12}>
          <Card className='custom-card'>
            <Card.Header>
              <Col xs={6} md={10}>
                <h3 className='card-title mb-1'>Resource</h3>
              </Col>
              <Col className='d-flex'>
                <Button variant='danger' onClick={() => toggleModal()}>
                  Delete Resources
                </Button>

                <div className='mx-3'>
                  <input
                    ref={inputRef}
                    onChange={e => handleFileChange(e)}
                    className='d-none'
                    type='file'
                    accept={EXCEL_FORMAT}
                    defaultValue={''}
                  />
                  <button
                    type='button'
                    onClick={() => inputRef?.current.click()}
                    className='btn btn-success'
                  >
                    <span>
                      <i className='fe fe-log-in'></i>&nbsp;
                    </span>
                    Import
                  </button>
                </div>
                <Button onClick={() => navigate('/resources/create')}>Create Resource</Button>
              </Col>
            </Card.Header>
            <Card.Body>
              <div className='table-responsive'>
                <Table className='table table-bordered text-nowrap text-md-nowrap mg-b-0'>
                  <thead>
                    <tr className='text-center'>
                      <th></th>
                      <th>order</th>
                      <th>name</th>
                      <th>title</th>
                      <th>package</th>
                      <th>photo</th>
                      <th>file url</th>
                      <th>for Teacher</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {resources?.date.length > 0
                      ? resources?.date.map(resource => {
                          return (
                            <ResourceTable
                              key={resource.id}
                              resource={resource}
                              setResourceIds={setResourceIds}
                              resourceIds={resourceIds}
                              toggleModal={toggleModal}
                            />
                          )
                        })
                      : null}
                  </tbody>
                </Table>
              </div>
            </Card.Body>
            {resources?.count > PAGESIZE ? (
              <Pagination
                setQuery={setSearchParams}
                numPages={Math.ceil(resources?.count / PAGESIZE)}
                query={searchParams}
              />
            ) : null}
          </Card>
        </Col>
      </Row>
      {isModalVisible ? (
        <Modal>
          <div className='py-3'>
            <ModalHeader>
              <div>
                <h2>Are you sure?</h2>
              </div>
            </ModalHeader>
            <Card className='text-center shadow-none'>
              <h5>Do you really want to delete these records? This process cannot be undone.</h5>
            </Card>
            <Card.Footer className='text-center'>
              <Button className='mx-2' onClick={() => toggleModal()}>
                Cancel
              </Button>
              <Button
                className='mx-2'
                variant='danger'
                onClick={() => {
                  fetchDeleteResource(resourceIds)
                }}
              >
                Delete
              </Button>
            </Card.Footer>
          </div>
        </Modal>
      ) : null}
    </div>
  )
}

export default Resource
