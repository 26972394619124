import { createApi } from '@reduxjs/toolkit/query/react'
import { ICreateGroup, IGetGroup } from './model'
import { baseQueryWithReauth } from '../apiSlice'

export const groupApi = createApi({
  reducerPath: 'group/Api',
  baseQuery: baseQueryWithReauth,
  endpoints: build => ({
    getGroups: build.query<IGetGroup[], void>({
      query: () => ({
        url: '/admin/group'
      })
    }),
    getGroupById: build.query<IGetGroup, string>({
      query: (id: string) => `/admin/group/${id}`
    }),
    createGroup: build.mutation<string, ICreateGroup>({
      query: (groupData: ICreateGroup) => ({
        url: 'admin/group',
        method: 'POST',
        body: groupData
      })
    }),
    updateGroup: build.mutation<void, ICreateGroup>({
      query: (group: ICreateGroup) => ({
        url: '/admin/group',
        method: 'PUT',
        body: group
      })
    }),
    deleteGroup: build.mutation<void, string>({
      query: (id: string) => ({
        url: `/admin/group/${id}`,
        method: 'DELETE'
      })
    })
  })
})

export const {
  useGetGroupsQuery,
  useGetGroupByIdQuery,
  useCreateGroupMutation,
  useUpdateGroupMutation,
  useDeleteGroupMutation
} = groupApi
