import React, { useState } from 'react'
import { Button, Card } from 'react-bootstrap'
import 'react-toastify/dist/ReactToastify.css'
import { useActions } from '../../hooks/actions'
import { useAdminLoginMutation } from '../../store/api/admin/admin.api'
import { ISignInRes } from '../../store/api/admin/model'
import { togglePasswordIcon } from '../../utils/togglePasswordIcon'
import { responseError } from '../../validation/validationRequaired'
import { ISignIn } from './model'
export default function Login(): JSX.Element {
  const { addAdminInfo } = useActions()
  const [adminLogin] = useAdminLoginMutation()
  const signInRef = React.createRef<HTMLFormElement>()
  const [typePassword, setTypePassword] = useState<string>('password')

  const handleSignIn = async (adminData: ISignIn): Promise<void> => {
    const res = await adminLogin(adminData)

    if ('error' in res) {
      responseError(res)
    } else if ('data' in res) {
      addAdminInfo(res.data as ISignInRes)
      window.location.assign('/')
    }
  }
  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>): Promise<void> => {
    e.preventDefault()
    const data = {
      email: signInRef?.current?.email.value,
      password: signInRef?.current?.password.value
    }
    if (data.password) {
      await handleSignIn(data)
    }
  }

  return (
    <div className='login-img'>
      <div className='page'>
        <div>
          <div className='container-login100'>
            <div className='wrap-login100 p-0'>
              <Card.Body>
                <form
                  className='login100-form validate-form'
                  ref={signInRef}
                  onSubmit={e => handleSubmit(e)}
                >
                  <span className='login100-form-title'>Login</span>
                  <div className='wrap-input100 validate-input'>
                    <input className='input100' type='text' name='email' placeholder='Email' />
                    <span className='focus-input100'></span>
                    <span className='symbol-input100'>
                      <i className='zmdi zmdi-email' aria-hidden='true'></i>
                    </span>
                  </div>

                  <div className='wrap-input100 validate-input position-relative'>
                    <input
                      className='input100'
                      type={typePassword === 'password' ? 'password' : 'text'}
                      name='password'
                      placeholder='Password'
                    />
                    <span className='symbol-input100'>
                      <i className='zmdi zmdi-lock' aria-hidden='true'></i>
                    </span>
                    <i
                      onClick={() => togglePasswordIcon(setTypePassword, typePassword)}
                      style={{ right: '5px', top: '15px' }}
                      className={`fe ${
                        typePassword === 'password' ? 'fe-eye-off' : 'fe-eye'
                      } position-absolute top-50 translate-middle cursor-pointer`}
                    ></i>
                  </div>
                  <div className='container-login100-form-btn'>
                    <Button type='submit' className='login100-form-btn btn-primary'>
                      Login
                    </Button>
                  </div>
                </form>
              </Card.Body>
              <Card.Footer></Card.Footer>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
