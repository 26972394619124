import { useState } from 'react'

export const UseToggleModalHOC = (): { isModalVisible: boolean; toggleModal: () => void } => {
  const [isModalVisible, setIsModalVisible] = useState<boolean>(false)

  const toggleModal = (): void => {
    setIsModalVisible(prevState => !prevState)
  }

  return { isModalVisible, toggleModal }
}
