export const getImageUrl = (e: React.ChangeEvent<HTMLInputElement>): string | undefined => {
  if (e.target.files && e?.target?.files.length !== 0) {
    const imageUrl = URL.createObjectURL(e.target.files[0])
    return imageUrl
  }
}

export const showImage = (previwUrl: string | undefined): JSX.Element => {
  if (previwUrl) {
    return (
      <div className='avatar avatar-lg  brround cover-image bg-primary mr-10'>
        <img
          style={{ width: '200px' }}
          src={previwUrl}
          className='avatar avatar-lg  brround cover-image bg-primary'
          alt=''
        />
      </div>
    )
  }
  return <div></div>
}
