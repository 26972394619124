import React, { useEffect, useLayoutEffect, useState } from 'react'
import { Card, Col, FormGroup, Row } from 'react-bootstrap'
import { Params, useNavigate, useParams } from 'react-router-dom'
import { CKEditor } from '@ckeditor/ckeditor5-react'
import ClassicEditor from '@ckeditor/ckeditor5-build-classic'
import { IGetResourceDate, TCreateResource } from '../../../store/api/resource/model'
import {
  useCreateResourceMutation,
  useGetResourceByIdQuery,
  useUpdateResourceMutation
} from '../../../store/api/resource/resource.api'
import { ISelectData } from '../../../ui/select/model'
import { useGetCategorysQuery } from '../../../store/api/category/category.api'
import SingleSelect from '../../../ui/select/SingleSelect'
import { useGetLessonQuery } from '../../../store/api/lesson/lesson.api'
import { useGetQuizQuery } from '../../../store/api/quiz/quiz.api'
import { BASE_URL, EditorConfigurationInResourceText, GRADE } from '../../../utils/constants'
import { selectedDataTake } from '../../../utils/selectedDataTake/selectedDataTake'
import { responseError, validationRequaired } from '../../../validation/validationRequaired'
import { toast } from 'react-toastify'
import { IValidationRequired } from '../../../validation/model'
import { closeValidateRequiredError } from '../../../validation/validation'
import { useGetPackagesQuery } from '../../../store/api/packages/packages.api'
import MultiSelect from '../../../ui/select/MultiSelect'
import { extractValues } from '../../../utils/extractValues'
import { createSingleSelectData } from '../../../utils/createSingleSelectData'
import Categorys from './Categorys/Categorys'

const ResourceCreate = (): JSX.Element => {
  const { id }: Readonly<Params<string>> = useParams()
  const navigation = useNavigate()
  const [createResource] = useCreateResourceMutation()
  const [updateResource] = useUpdateResourceMutation()
  const [selectedFile, setSelectedFile] = useState<File | null>(null)
  const formRef = React.createRef<HTMLFormElement>()
  const { data, refetch } = useGetResourceByIdQuery(Number(id), {
    skip: !id
  })
  const [resourceById, setResourceById] = useState<IGetResourceDate>()
  const dataPackages = useGetPackagesQuery()
  const { data: category, refetch: categoryRefetch } = useGetCategorysQuery()
  const [categoryData, setCategoryData] = useState<ISelectData[]>([])
  const [categorySelected, setCategorySelected] = useState<ISelectData[]>()
  const [gradeSelected, setGradeSelected] = useState<ISelectData[]>()
  const [grades, setGrades] = useState<ISelectData[]>(GRADE)

  const { data: lessons, refetch: lessonRefetch } = useGetLessonQuery({
    page: 1,
    pageSize: 200
  })
  const [lessonsData, setLessonsData] = useState<ISelectData[]>([])
  const [lessonSelected, setLessonSelected] = useState<ISelectData>()

  const { data: quizzes, refetch: quizzesRefetch } = useGetQuizQuery()
  const [quizzesData, setQuizzesData] = useState<ISelectData[]>([])
  const [quizzSelected, setQuizzSelected] = useState<ISelectData>({})
  const [selectedFileUrl, setSelectedFileUrl] = useState<string | null>()
  const [isQuiz, setIsQuiz] = useState<boolean>()
  const [isFile, setIsFile] = useState<boolean>()
  const [image, setImage] = useState<File | null>(null)
  const [errorRequired, setErrorRequired] = useState<IValidationRequired>({})
  const [packages, setPackages] = useState<ISelectData[]>([])
  const [packagesSelected, setPackagesSelected] = useState<ISelectData>()
  const [isPackagesRequired, setIsPackagesQuired] = useState<boolean>(false)
  const [ckEditor, setCkEditor] = useState<string>('')
  const [categorys, setCategorys] = useState<string[]>([''])
  const [newGrade, setNewGrade] = useState<string>('')

  useEffect(() => {
    setResourceById(data)
  }, [data])

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>): Promise<void> => {
    event.preventDefault()
    const newResourceData: TCreateResource = {
      name: formRef?.current.namee.value,
      title: formRef?.current.titlee.value,
      forTeacher: formRef?.current.forTeacher.value,
      description: formRef?.current.description.value,
      packageId: packagesSelected?.value
    }
    typeof packagesSelected === 'undefined' && setIsPackagesQuired(true)
    setErrorRequired(validationRequaired(newResourceData))

    if (!Object.values(validationRequaired(newResourceData)).length) {
      const {
        current: { xp, duration, order }
      } = formRef
      newResourceData.grade =
        gradeSelected?.length > 0 ? extractValues(gradeSelected).join(',') : ''

      if (categorys?.length) {
        newResourceData.category = categorys.filter(e => e.length > 0)
      }
      if (selectedFileUrl || resourceById?.fileUrl) {
        newResourceData.fileUrl = selectedFileUrl || resourceById.fileUrl
      }
      if (selectedFile?.name.length > 0) {
        newResourceData.file = selectedFile
      }
      if (quizzSelected?.value) {
        newResourceData.quizId = quizzSelected?.value
      }
      if (categorySelected?.length > 0) {
        newResourceData.categoryIds = extractValues(categorySelected)
      }
      if (lessonSelected?.value) {
        newResourceData.lessonId = lessonSelected?.value
      }
      if (image?.name.length > 0) {
        newResourceData.image = image
      }
      if (xp.value.length > 0) {
        newResourceData.xp = xp.value
      }
      if (ckEditor.length > 0) {
        newResourceData.text = ckEditor
      }
      if (duration.value.length > 0) {
        newResourceData.duration = duration.value
      }
      if (order) {
        newResourceData.order = order.value
      }

      try {
        const res = id
          ? await updateResource({ ...newResourceData, id: Number(id) })
          : await createResource(newResourceData)
        const message = (res as unknown as { data }).data.message as string
        toast.success(message)
        navigation(-1)
      } catch (error) {
        responseError(error)
      }
    }
  }

  useEffect(() => {
    if (id) {
      refetch()
    }
  }, [id])

  const quizOrFileOrUrl = (resourceById: IGetResourceDate): void => {
    if (resourceById?.quiz) {
      setIsQuiz(true)
      setIsFile(false)
    } else if (resourceById?.fileUrl?.length > 0 && resourceById?.fileUrl?.includes(BASE_URL)) {
      setIsQuiz(false)
      setIsFile(true)
    } else if (resourceById?.fileUrl?.length > 0 && !resourceById?.fileUrl?.includes(BASE_URL)) {
      setIsQuiz(false)
      setIsFile(false)
    }
  }

  useLayoutEffect(() => {
    if (resourceById) {
      quizOrFileOrUrl(resourceById)
      if (resourceById.quiz) {
        setQuizzSelected(createSingleSelectData(resourceById?.quiz.title, resourceById.quiz.id))
      }
      if (resourceById.package) {
        setPackagesSelected(
          createSingleSelectData(resourceById.package.name, resourceById.package.id)
        )
      }
      if (resourceById.categories?.length > 0) {
        setCategorySelected(
          resourceById.categories.map(item => {
            return createSingleSelectData(item.name, item.id)
          })
        )
      }
      if (resourceById.grade) {
        const gradeArray = resourceById.grade.split(',')

        setGradeSelected(
          gradeArray.map(item => {
            return {
              label: item,
              value: Number(item)
            }
          })
        )
      }
      if (resourceById.lesson) {
        setLessonSelected(createSingleSelectData(resourceById.lesson.name, resourceById.lesson.id))
      }
      if (!resourceById?.fileUrl?.includes(BASE_URL) && formRef?.current.fileUrl?.value) {
        formRef.current.fileUrl.value = resourceById?.fileUrl
      }
    }
  }, [resourceById])

  const handleImageChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    if (event.target.files && event.target.files.length > 0) {
      setImage(event.target.files[0])
    }
  }

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    if (event.target.files && event.target.files.length > 0) {
      setSelectedFile(event.target.files[0])
      setSelectedFileUrl(null)
      setQuizzSelected({})
    }
  }

  const handleFileUrlChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    setSelectedFileUrl(event.target.value)
    setSelectedFile(null)
    setQuizzSelected({})
  }

  useEffect(() => {
    categoryRefetch()
    lessonRefetch()
    quizzesRefetch()
  }, [])

  useEffect(() => {
    selectedDataTake(dataPackages?.data, setPackages)
  }, [dataPackages.data])

  useEffect(() => {
    selectedDataTake(category, setCategoryData)
  }, [category])

  useEffect(() => {
    selectedDataTake(lessons?.data, setLessonsData)
  }, [lessons])

  useEffect(() => {
    if (quizzes?.length > 0) {
      quizzes.forEach(quizItem => {
        setQuizzesData(prev => {
          return [...prev, createSingleSelectData(quizItem.title, quizItem.id)]
        })
      })
    }
  }, [quizzes])

  useEffect(() => {
    if ('label' in quizzSelected) {
      setSelectedFileUrl(null)
      setSelectedFile(null)
    }
  }, [quizzSelected])

  const addCategory = (): void => {
    setCategorys(prev => [...prev, ''])
  }

  const addGrade = (): void => {
    const trimmedGrade = newGrade.trim()

    if (!trimmedGrade) {
      toast.error('Input must not be empty.')
      return
    }
    const newGradeObject = { label: trimmedGrade, value: Number(trimmedGrade) }

    if (
      grades.some(
        grade => grade.label === newGradeObject.label && grade.value === newGradeObject.value
      )
    ) {
      toast.warning('Grade is already there')
    } else {
      setGrades(prevGrades => [...prevGrades, newGradeObject])
      setNewGrade('')
      toast.success('Grade added successfully!')
    }
  }

  return (
    <div>
      <Card.Header>
        <div>
          <h2>{id ? 'Edit Resource' : 'Create Resource '} </h2>
        </div>
      </Card.Header>
      <Row>
        <Col lg={12} xl={12} md={12} sm={12}>
          <Card>
            <form ref={formRef} onSubmit={e => handleSubmit(e)}>
              <Card.Body>
                <Row>
                  <Col xs={6} md={5}>
                    <FormGroup>
                      <label className='text-dark' htmlFor='name'>
                        Name
                      </label>
                      <input
                        defaultValue={resourceById?.name}
                        id='name'
                        name='namee'
                        type='text'
                        className='form-control'
                        placeholder='Name'
                        onClick={() =>
                          closeValidateRequiredError('name', errorRequired, setErrorRequired)
                        }
                      />
                    </FormGroup>
                    {errorRequired?.name && (
                      <span className='text-danger'>{errorRequired?.name}</span>
                    )}
                  </Col>
                  <Col xs={6} md={5}>
                    <FormGroup>
                      <label className='text-dark' htmlFor='title'>
                        Title
                      </label>
                      <input
                        defaultValue={resourceById?.title}
                        id='title'
                        name='titlee'
                        type='text'
                        className='form-control'
                        placeholder='Title'
                        onClick={() =>
                          closeValidateRequiredError('title', errorRequired, setErrorRequired)
                        }
                      />
                    </FormGroup>
                    {errorRequired?.title && (
                      <span className='text-danger'>{errorRequired?.title}</span>
                    )}
                  </Col>
                  <Col xs={6} md={5}>
                    <FormGroup>
                      <label className='text-dark mt-5' htmlFor='text'>
                        Text
                      </label>
                      <CKEditor
                        editor={ClassicEditor}
                        config={EditorConfigurationInResourceText}
                        data={resourceById?.text || ''}
                        id='text'
                        onChange={(_, editor) => setCkEditor(editor.getData())}
                      />
                    </FormGroup>
                  </Col>
                  <Col xs={6} md={5}>
                    <FormGroup>
                      <label className='text-dark mt-5' htmlFor='order'>
                        Order
                      </label>
                      <input
                        defaultValue={resourceById?.order}
                        type='number'
                        name='order'
                        className='form-control'
                        placeholder='Order'
                      />
                    </FormGroup>
                  </Col>
                  <Col xs={6} md={5}>
                    <FormGroup>
                      <label className='text-dark mt-5' htmlFor='duration'>
                        Duration
                      </label>
                      <input
                        defaultValue={resourceById?.duration}
                        id='duration'
                        name='duration'
                        type='text'
                        className='form-control'
                        placeholder='Duration in Minutes'
                      />
                    </FormGroup>
                  </Col>
                  <Col xs={6} md={5}>
                    <label className='text-dark mt-5' htmlFor='exampleInputname'>
                      Packages
                    </label>
                    <SingleSelect
                      data={packages}
                      editData={packagesSelected}
                      setData={setPackagesSelected}
                    />
                    {isPackagesRequired && (
                      <span className='text-danger'>packages is required</span>
                    )}
                  </Col>
                  <Col xs={6} md={5}>
                    <label className='text-dark mt-5' htmlFor='forTeacher'>
                      ForTeacher
                    </label>
                    <select
                      value={resourceById?.forTeacher ? 'true' : 'false'}
                      className='form-control text-dark'
                      name='forTeacher'
                      onChange={() => {
                        setResourceById({
                          ...resourceById,
                          forTeacher: !resourceById?.forTeacher
                        })
                      }}
                    >
                      <option value={'false'}>false</option>
                      <option value={'true'}>true</option>
                    </select>
                  </Col>
                  <Col xs={6} md={5}>
                    <FormGroup>
                      <label className='text-dark mt-5' htmlFor='description'>
                        Description
                      </label>
                      <textarea
                        defaultValue={resourceById?.description}
                        id='description'
                        name='description'
                        className='form-control'
                        placeholder='Description'
                        onClick={() =>
                          closeValidateRequiredError('description', errorRequired, setErrorRequired)
                        }
                      />
                    </FormGroup>
                    {errorRequired?.description && (
                      <span className='text-danger'>{errorRequired?.description}</span>
                    )}
                  </Col>
                  <Col xs={6} md={5}>
                    <FormGroup>
                      <label className='text-dark mt-5' htmlFor='image'>
                        Image
                      </label>
                      <input
                        id='image'
                        name='image'
                        type='file'
                        accept='image/*'
                        className='form-control'
                        placeholder='image'
                        onChange={e => handleImageChange(e)}
                      />
                    </FormGroup>
                  </Col>
                  <Col xs={6} md={5} className='text-dark mt-5'>
                    <button
                      type='button'
                      className='btn btn-light mt-5 me-2'
                      onClick={() => {
                        setIsQuiz(true)
                        setIsFile(false)
                      }}
                    >
                      Quizz
                    </button>
                    <button
                      type='button'
                      className='btn btn-light mt-5 me-2'
                      onClick={() => {
                        setIsQuiz(false)
                        setIsFile(true)
                      }}
                    >
                      File
                    </button>
                    <button
                      type='button'
                      className='btn btn-light mt-5 me-2'
                      onClick={() => {
                        setIsQuiz(false)
                        setIsFile(false)
                      }}
                    >
                      File URl
                    </button>
                  </Col>
                  <Col xs={6} md={5}>
                    <label className='text-dark mt-5' htmlFor='exampleInputname'>
                      Category
                    </label>
                    <MultiSelect
                      data={categoryData}
                      editData={categorySelected}
                      setData={setCategorySelected}
                    />
                  </Col>
                  {isFile && !isQuiz && (
                    <>
                      <Col xs={6} md={5}>
                        <FormGroup>
                          <label className='text-dark mt-5' htmlFor='file'>
                            File
                          </label>
                          <input
                            id='file'
                            name='file'
                            type='file'
                            className='form-control'
                            placeholder='File Url'
                            onChange={e => handleFileChange(e)}
                          />
                        </FormGroup>
                      </Col>
                    </>
                  )}
                  {!isFile && !isQuiz && (
                    <Col xs={6} md={5}>
                      <FormGroup>
                        <label className='text-dark mt-5' htmlFor='file'>
                          File Url
                        </label>
                        <input
                          onChange={e => handleFileUrlChange(e)}
                          defaultValue={resourceById?.fileUrl}
                          id='file'
                          name='fileUrl'
                          className='form-control'
                          placeholder='File Url'
                        />
                      </FormGroup>
                    </Col>
                  )}
                  {isQuiz && (
                    <Col xs={6} md={5}>
                      <label className='text-dark mt-5' htmlFor='exampleInputname'>
                        Quiz
                      </label>
                      <SingleSelect
                        data={quizzesData}
                        editData={quizzSelected.label && quizzSelected}
                        setData={setQuizzSelected}
                      />
                    </Col>
                  )}
                  <Col xs={6} md={5}>
                    <label className='text-dark mt-5' htmlFor='exampleInputname'>
                      Lesson
                    </label>
                    <SingleSelect
                      data={lessonsData}
                      editData={lessonSelected}
                      setData={setLessonSelected}
                    />
                  </Col>
                  <Col xs={6} md={5}>
                    <div className='d-flex align-items-center mt-4 gap-2 mb-3'>
                      <label className='text-dark mb-0' htmlFor='exampleInputname'>
                        For Grades
                      </label>
                      <input
                        type='number'
                        className='form-control w-10'
                        value={newGrade}
                        onChange={({ target }) => setNewGrade(target.value)}
                        style={{ height: '25px' }}
                      />
                      <i
                        onClick={() => addGrade()}
                        className={'side-menu__icon fa fa-plus'}
                        style={{ cursor: 'pointer' }}
                      ></i>
                    </div>
                    <MultiSelect
                      data={grades}
                      editData={gradeSelected}
                      setData={setGradeSelected}
                    />
                  </Col>
                  <Col xs={6} md={5}>
                    <FormGroup>
                      <label className='text-dark mt-5' htmlFor='xp'>
                        XP Points
                      </label>
                      <input
                        defaultValue={resourceById?.xp}
                        id='xp'
                        name='xp'
                        type='text'
                        className='form-control'
                        placeholder='Xp'
                      />
                    </FormGroup>
                  </Col>
                  <Col xs={6} md={5}>
                    <FormGroup>
                      <label className='text-dark mt-5' htmlFor='image'>
                        Category
                      </label>
                      <i onClick={addCategory} className={'side-menu__icon fa fa-plus mx-3'}></i>
                      {categorys.length
                        ? categorys.map((_, index) => (
                            <Categorys key={index} setCategorys={setCategorys} index={index} />
                          ))
                        : null}
                    </FormGroup>
                  </Col>
                </Row>
              </Card.Body>
              <Card.Footer className='text-end'>
                <button className='btn btn-success mt-1 me-2'>Save</button>
                <button className='btn btn-danger mt-1 me-2' onClick={() => navigation(-1)}>
                  Cancel
                </button>
              </Card.Footer>
            </form>
          </Card>
        </Col>
      </Row>
    </div>
  )
}

export default ResourceCreate
