import { Route, Routes } from 'react-router-dom'
import App from '../components/App'
import Mood from '../components/Mood/Mood'

export const MoodRoutes = (): React.ReactElement => (
  <Routes>
    <Route path={'/moods'} element={<App />}>
      <Route path='' element={<Mood />} />
    </Route>
  </Routes>
)
