export const lessMore = (
  text: string,
  selected: string[],
  setSelected: React.Dispatch<React.SetStateAction<string[]>>
): void => {
  if (!selected.includes(text)) {
    setSelected(prev => [...prev, text])
  } else {
    setSelected(prev => prev.filter(item => item !== text))
  }
}
