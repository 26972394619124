import { Button, Card, Col, FormGroup, Row } from 'react-bootstrap'
import Modal from '../../../ui/modal/Modal'
import { IValidationRequired } from '../../../validation/model'
import React, { FC, useState } from 'react'
import { ModalHeader } from '../../../ui/modal/ModalHeader'
import { closeValidateRequiredError } from '../../../validation/validation'
import { ICategoryModal, ICreateCategory } from '../../../store/api/category/model'
import {
  useCreateCategoryMutation,
  useUpdateCategoryMutation
} from '../../../store/api/category/category.api'
import { responseError, validationRequaired } from '../../../validation/validationRequaired'
import { toast } from 'react-toastify'

const CategoryCreate: FC<ICategoryModal> = ({ onClose, editCategory }): JSX.Element => {
  const formRef = React.createRef<HTMLFormElement>()
  const [errorRequaired, setErrorRequaired] = useState<IValidationRequired>({})
  const [createCategory] = useCreateCategoryMutation()
  const [updateCategory] = useUpdateCategoryMutation()

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>): Promise<void> => {
    event.preventDefault()
    const newData: ICreateCategory = { name: formRef?.current.headline.value }

    setErrorRequaired(validationRequaired(newData))
    if (!Object.values(validationRequaired(newData)).length) {
      if (editCategory?.id) {
        newData.id = editCategory.id
        const res = await updateCategory(newData)
        if ('error' in res) {
          responseError(res)
        } else {
          toast.success('Category was updated')
          onClose()
        }
      } else {
        const res = await createCategory(newData)
        if ('error' in res) {
          responseError(res)
        } else {
          toast.success('Category was created')
          onClose()
        }
      }
    }
  }

  return (
    <Modal>
      <div>
        <ModalHeader>
          <div>
            <h2>{editCategory ? 'Edit Category' : 'Create Category'} </h2>
          </div>
        </ModalHeader>
        <Row>
          <Col lg={12} xl={12} md={12} sm={12}>
            <Card>
              <form ref={formRef} onSubmit={e => handleSubmit(e)}>
                <Card.Body>
                  <Row>
                    <Col xs={6} md={12}>
                      <FormGroup>
                        <label className='text-dark' htmlFor='exampleInputname'>
                          Name
                        </label>
                        <input
                          defaultValue={editCategory?.name}
                          name='headline'
                          type='text'
                          className='form-control'
                          placeholder='User Name'
                          onClick={() =>
                            closeValidateRequiredError('name', errorRequaired, setErrorRequaired)
                          }
                        />
                      </FormGroup>
                      {errorRequaired?.name && (
                        <span className='text-danger'>{errorRequaired?.name}</span>
                      )}
                    </Col>
                  </Row>
                </Card.Body>
                <Card.Footer className='text-end'>
                  <button className='btn btn-success mt-1 me-2'>Save</button>
                  <Button className='btn btn-danger mt-1 me-2' onClick={onClose}>
                    Cancel
                  </Button>
                </Card.Footer>
              </form>
            </Card>
          </Col>
        </Row>
      </div>
    </Modal>
  )
}

export default CategoryCreate
