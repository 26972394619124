import { Route, Routes } from 'react-router-dom'
import Question from '../components/Question/Question'
import App from '../components/App'
import QuestionChoice from '../components/Question/QuestionChoice'
import QuestionCreate from '../components/Question/QuestionCreate/QuestionCreate'

export const QuestionRoutes = (): React.ReactElement => {
  return (
    <Routes>
      <Route path={'/questions'} element={<App />}>
        <Route path='' element={<Question />} />
        <Route path='/questions/:id/choice' element={<QuestionChoice />} />
        <Route path='/questions/create' element={<QuestionCreate />} />
        <Route path='/questions/edit/:id' element={<QuestionCreate />} />
      </Route>
    </Routes>
  )
}
