import { ISearchParams } from '../ui/filter/model'

export const searchParamsData = (
  searchParams: URLSearchParams,
  requestData: ISearchParams
): ISearchParams => {
  for (const [key, value] of searchParams.entries()) {
    if (value) {
      requestData[key] = value
    }
  }
  return requestData
}
