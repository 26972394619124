import { createApi } from '@reduxjs/toolkit/query/react'
import { baseQueryWithReauth } from '../apiSlice'
import { IGetWellnessCheckinsByFilter, IWellnessCheckins } from './model'
import { ISearchParams } from '../../../ui/filter/model'

export const wellnessCheckinsApi = createApi({
  reducerPath: 'wellnesscheckins/api',
  baseQuery: baseQueryWithReauth,
  endpoints: build => ({
    GetWellnessCheckins: build.query<IWellnessCheckins[], ISearchParams>({
      query: (params: ISearchParams) => ({
        url: '/admin/wellness-checkin',
        params: { ...params }
      })
    }),
    GetWellnessCheckinsByFilter: build.query<IGetWellnessCheckinsByFilter[], void>({
      query: () => '/admin/wellness-checkin-users'
    })
  })
})

export const { useGetWellnessCheckinsQuery, useGetWellnessCheckinsByFilterQuery } =
  wellnessCheckinsApi
