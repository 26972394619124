import { Route, Routes } from 'react-router-dom'
import * as React from 'react'
import App from '../components/App'
import WellnessCheckins from '../components/WellnessCheckins/WellnessCheckins'

export const WellnessCheckinsRoutes = (): React.ReactElement => {
  return (
    <Routes>
      <Route path={'/wellness-checkins'} element={<App />}>
        <Route path='' element={<WellnessCheckins />} />
      </Route>
    </Routes>
  )
}
