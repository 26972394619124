import { FC } from 'react'
import { Button, ButtonGroup, OverlayTrigger, Tooltip } from 'react-bootstrap'
import { useNavigate } from 'react-router-dom'
import { useDeleteMeditationMutation } from '../../../store/api/meditation/meditation.api'
import { IGetMeditionQuestionData } from '../../../store/api/meditation/model'
import { UseToggleModalHOC } from '../../../hooks/toggleModal'
import RemoveItemModal from '../../../utils/RemoveItemModal/removeItemModal'

const MeditationTable: FC<IGetMeditionQuestionData> = ({ Meditation, refetch }): JSX.Element => {
  const [deleteMeditation] = useDeleteMeditationMutation()
  const navigate = useNavigate()
  const { isModalVisible, toggleModal } = UseToggleModalHOC()

  return (
    <tr key={Meditation?.id} className='text-center align-middle'>
      <td>{Meditation?.id}</td>
      <td>
        <audio controls>
          <source src={Meditation.fileUrl} type='audio/ogg' />
        </audio>
      </td>
      <td>
        <img
          height={50}
          width={50}
          style={{
            border: 'none'
          }}
          src={Meditation.url}
          alt=''
        />
      </td>
      <td>{Meditation?.name}</td>
      <td>{Meditation?.source}</td>
      <td>{Meditation?.duration}</td>
      <td className='align-middle'>
        <span className='text-center align-middle'>
          <ButtonGroup size='sm' className='flex-nowrap'>
            <OverlayTrigger placement='top' overlay={<Tooltip>Edit</Tooltip>}>
              <Button onClick={() => navigate(`/meditation/edit/${Meditation?.id}`)}>Edit</Button>
            </OverlayTrigger>
            <OverlayTrigger placement='top' overlay={<Tooltip>Delete</Tooltip>}>
              <Button onClick={() => toggleModal()}>Delete</Button>
            </OverlayTrigger>
          </ButtonGroup>
        </span>
      </td>
      {isModalVisible && (
        <RemoveItemModal
          id={Meditation.id}
          deleteItem={deleteMeditation}
          reload={refetch}
          onClose={() => toggleModal()}
        />
      )}
    </tr>
  )
}

export default MeditationTable
