import {
  Button,
  ButtonGroup,
  Card,
  Col,
  OverlayTrigger,
  Row,
  Table,
  Tooltip
} from 'react-bootstrap'
import { useEffect, useState } from 'react'
import { stringToDate } from '../../utils/date'
import { useDeleteMoodMutation, useGetMoodQuery } from '../../store/api/mood/mood.api'
import { IGetMood } from '../../store/api/mood/model'
import { modalConditionOpen } from '../../ui/modal/toggleModal'
import MoodCreate from './MoodCreate/MoodCreate'
import Loading from '../Loading/Loading'
import { UseToggleModalHOC } from '../../hooks/toggleModal'
import RemoveItemModal from '../../utils/RemoveItemModal/removeItemModal'

const Mood = (): JSX.Element => {
  const [isOpenCreateModal, setIsOpenCreateModal] = useState<boolean>()
  const [edit, setEdit] = useState<IGetMood>()
  const { data: moods, isLoading, refetch } = useGetMoodQuery()
  const [deleteChallenge] = useDeleteMoodMutation()
  const { isModalVisible, toggleModal } = UseToggleModalHOC()

  useEffect(() => {
    refetch()
  }, [isOpenCreateModal])
  return (
    <div className='mt-8'>
      {isLoading && <Loading />}
      <Row className=' row-sm'>
        <Col lg={12}>
          <Card className='custom-card'>
            <Card.Header>
              <Col xs={6} md={11}>
                <h3 className='card-title mb-1'>Mood</h3>
              </Col>
              <div>
                <Button onClick={() => modalConditionOpen(setIsOpenCreateModal, setEdit, 'create')}>
                  Create Mood
                </Button>
              </div>
            </Card.Header>
            <Card.Body>
              <div className='table-responsive'>
                <Table className='table table-bordered text-nowrap text-md-nowrap mg-b-0'>
                  <thead>
                    <tr className='text-center'>
                      <th>ID</th>
                      <th>Name</th>
                      <th>Photo</th>
                      <th>create at</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {moods?.length > 0 &&
                      moods?.map(mood => {
                        return (
                          <tr className='text-center' key={mood.id}>
                            <td>{mood.id}</td>
                            <td>{mood.name}</td>
                            <td>
                              <img style={{ width: '100px' }} src={mood.fileUrl} alt='' />
                            </td>
                            <td>{stringToDate(mood.created_at)}</td>
                            <td>
                              <span className='text-center align-middle'>
                                <ButtonGroup size='sm' className='flex-nowrap'>
                                  <OverlayTrigger placement='top' overlay={<Tooltip>Edit</Tooltip>}>
                                    <Button
                                      onClick={() =>
                                        modalConditionOpen(
                                          setIsOpenCreateModal,
                                          setEdit,
                                          'edit',
                                          mood
                                        )
                                      }
                                    >
                                      Edit
                                    </Button>
                                  </OverlayTrigger>
                                  <OverlayTrigger
                                    placement='top'
                                    overlay={<Tooltip>Delete</Tooltip>}
                                  >
                                    <Button onClick={() => toggleModal()}>Delete</Button>
                                  </OverlayTrigger>
                                </ButtonGroup>
                              </span>
                            </td>
                            {isModalVisible && (
                              <RemoveItemModal
                                id={mood.id}
                                deleteItem={deleteChallenge}
                                reload={refetch}
                                onClose={() => toggleModal()}
                              />
                            )}
                          </tr>
                        )
                      })}
                  </tbody>
                </Table>
              </div>
            </Card.Body>
          </Card>
        </Col>
      </Row>
      {isOpenCreateModal && (
        <MoodCreate editData={edit} setIsOpenCreateModal={setIsOpenCreateModal} />
      )}
    </div>
  )
}

export default Mood
