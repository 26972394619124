export interface IWellnessCheckins {
  id: number
  slug: string
  created_at: string
  user: IWellnessCheckinsUSer
}

interface IWellnessCheckinsUSer {
  email: string
  fullName: string
  id: number
  package: IUserPackage
  role: IUserRole
}
interface IUserPackage {
  name: string
}
interface IUserRole {
  name: string
}

export interface IChangeSelect {
  value: number
  lable: string
}

export interface IGetWellnessCheckinsByFilter {
  fullName: string
  id: number
}

export enum WELLNESS_CHECKINS_SLUG_TYPU {
  verysad = 'Very Sad ',
  sad = 'Sad',
  normal = 'Normal',
  heppy = 'Happy',
  veryhappy = 'Very Happy'
}
