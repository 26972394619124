import { createApi } from '@reduxjs/toolkit/query/react'
import { baseQueryWithReauth } from '../apiSlice'
import {
  IGetClassesLessonStats,
  IGetGlobalStats,
  IGetLatestUsers,
  IGetLessonsStats,
  IGetResourcesStats
} from './model'
export const dashboardAPi = createApi({
  reducerPath: 'global-stats/Api',
  baseQuery: baseQueryWithReauth,
  endpoints: build => ({
    getGlobalStats: build.query<IGetGlobalStats[], void>({
      query: () => '/admin/global-stats'
    }),
    getLatestUsers: build.query<IGetLatestUsers[], void>({
      query: () => '/admin/latest-users'
    }),
    getClassesLessonStats: build.query<IGetClassesLessonStats[], void>({
      query: () => '/admin/classes-lesson-stats'
    }),
    getLessonsStats: build.query<IGetLessonsStats[], void>({
      query: () => '/admin/lesson-stats'
    }),
    getResourcesStats: build.query<IGetResourcesStats[], void>({
      query: () => '/admin/resources-stats'
    })
  })
})
export const {
  useGetGlobalStatsQuery,
  useGetLatestUsersQuery,
  useGetClassesLessonStatsQuery,
  useGetLessonsStatsQuery,
  useGetResourcesStatsQuery
} = dashboardAPi
