import { createApi } from '@reduxjs/toolkit/query/react'
import { baseQueryWithReauth } from '../apiSlice'
import { IGetCharacter } from './model'

export const characterApi = createApi({
  reducerPath: 'character/api',
  baseQuery: baseQueryWithReauth,
  endpoints: build => ({
    getCharacters: build.query<IGetCharacter[], void>({
      query: () => ({
        url: 'admin/character'
      })
    }),
    createCharacters: build.mutation<void, FormData>({
      query: (data: FormData) => ({
        url: 'admin/character',
        method: 'POST',
        body: data
      })
    }),
    deleteCharacters: build.mutation<void, string>({
      query: (id: string) => ({
        url: `admin/character/${id}`,
        method: 'DELETE'
      })
    })
  })
})

export const { useGetCharactersQuery, useCreateCharactersMutation, useDeleteCharactersMutation } =
  characterApi
