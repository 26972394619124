import { createApi } from '@reduxjs/toolkit/query/react'
import { ICreateSchool, IGetSchool } from './model'
import { baseQueryWithReauth } from '../apiSlice'
import { TSendExcelResponsOrError } from '../users/model'

export const schoolApi = createApi({
  reducerPath: 'school/api',
  baseQuery: baseQueryWithReauth,
  endpoints: build => ({
    getSchool: build.query<IGetSchool[], void>({
      query: () => ({ url: '/admin/school' })
    }),

    getSchoolById: build.query<IGetSchool, number>({
      query: (id: number) => ({ url: `/admin/school/${id}` })
    }),

    createSchool: build.mutation<void, ICreateSchool>({
      query: (data: ICreateSchool) => ({
        url: '/admin/school',
        method: 'POST',
        body: data
      })
    }),

    updateSchool: build.mutation<void, ICreateSchool>({
      query: (data: ICreateSchool) => ({
        url: '/admin/school',
        method: 'PUT',
        body: data
      })
    }),

    sendExelFile: build.mutation<TSendExcelResponsOrError, FormData>({
      query: (exelData: FormData) => ({
        url: '/admin/import-schools',
        method: 'POST',
        body: exelData
      })
    }),
    deleteSchool: build.mutation<void, string>({
      query: (id: string) => ({ url: `/admin/school/${id}`, method: 'DELETE' })
    })
  })
})

export const {
  useGetSchoolQuery,
  useGetSchoolByIdQuery,
  useCreateSchoolMutation,
  useUpdateSchoolMutation,
  useDeleteSchoolMutation,
  useSendExelFileMutation
} = schoolApi
