import { Route, Routes } from 'react-router-dom'
import App from '../components/App'
import Surveys from '../components/Surveys/Surveys'
import CreateSurvey from '../components/Surveys/CreateSurvey/CreateSurvey'

export const SurveyRoutes = (): React.ReactElement => {
  return (
    <Routes>
      <Route path={'/surveys'} element={<App />}>
        <Route path='' element={<Surveys />} />
        <Route path='create' element={<CreateSurvey />} />
        <Route path='edit/:id' element={<CreateSurvey />} />
      </Route>
    </Routes>
  )
}
