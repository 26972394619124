import { Route, Routes } from 'react-router-dom'
import * as React from 'react'
import Users from '../components/Users/Users'
import App from '../components/App'
import UsersCreate from '../components/Users/UsersCreate/UsersCreate'

export const UsersRoutes = (): React.ReactElement => {
  return (
    <Routes>
      <Route path={'/users'} element={<App />}>
        <Route path='' element={<Users />} />
        <Route path='/users/teachers/3' element={<Users />} />
        <Route path='/users/students/2' element={<Users />} />
        <Route path='/users/parents/4' element={<Users />} />
        <Route path='/users/School/leaderships/1' element={<Users />} />
        <Route path='/users/create' element={<UsersCreate />} />
        <Route path='/users/edit/:id' element={<UsersCreate />} />
      </Route>
    </Routes>
  )
}
