import { Route, Routes } from 'react-router-dom'
import * as React from 'react'
import Lesson from '../components/Lesson/Lesson'
import App from '../components/App'
import LessonCreate from '../components/Lesson/LessonCreate/LessonCreate'

export const LessonRoutes = (): React.ReactElement => {
  return (
    <Routes>
      <Route path={'/lesson'} element={<App />}>
        <Route path='' element={<Lesson />} />
        <Route path={'/lesson/create'} element={<LessonCreate />} />
        <Route path={'/lesson/edit/:id'} element={<LessonCreate />} />
      </Route>
    </Routes>
  )
}
