import { useEffect } from 'react'
import { Button, Card, Col, Row, Table } from 'react-bootstrap'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { useGetSectionsQuery } from '../../store/api/section/section.api'
import Loading from '../Loading/Loading'
import SectionTable from './SectionTable/SectionTable'
import Pagination from '../../ui/Pagination/Pagination'
import { ISearchParams } from '../../ui/filter/model'
import { searchParamsData } from '../../utils/searchParamsData'
import { DefaultParams, PAGESIZE } from '../../utils/constants'

const Section = (): JSX.Element => {
  const navigate = useNavigate()
  const [searchParams, setSearchParams] = useSearchParams(DefaultParams)
  const requestData: ISearchParams = {}
  const {
    data: sections,
    refetch,
    isLoading
  } = useGetSectionsQuery(searchParamsData(searchParams, requestData))

  useEffect(() => {
    refetch()
  }, [])

  return (
    <div className='mt-8'>
      {isLoading && <Loading />}
      <Row className=' row-sm'>
        <Col lg={12}>
          <Card className='custom-card'>
            <Card.Header>
              <Col xs={6} md={10}>
                <h3 className='card-title mb-1'>Sections</h3>
              </Col>
              <Col className='d-flex justify-content-end'>
                <Button onClick={() => navigate('/section/create')}>Create Section</Button>
              </Col>
            </Card.Header>
            <Card.Body>
              <div className='table-responsive'>
                <Table className='table table-bordered text-nowrap text-md-nowrap mg-b-0'>
                  <thead>
                    <tr className='text-center'>
                      <th>Name</th>
                      <th>Class Name</th>
                      <th>School Name</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {sections?.data?.length > 0
                      ? sections.data.map(section => (
                          <SectionTable key={section.id} section={section} refetch={refetch} />
                        ))
                      : null}
                  </tbody>
                </Table>
              </div>
            </Card.Body>
            <Pagination
              setQuery={setSearchParams}
              numPages={Math.ceil(sections?.total / PAGESIZE)}
              query={searchParams}
            />
          </Card>
        </Col>
      </Row>
    </div>
  )
}

export default Section
