import { Route, Routes } from 'react-router-dom'
import * as React from 'react'
import App from '../components/App'
import Meditation from '../components/Meditation/Meditation'
import MeditationCreate from '../components/Meditation/MeditationCreate/MeditationCreate'

export const MeditationRoutes = (): React.ReactElement => {
  return (
    <Routes>
      <Route path={'/meditation'} element={<App />}>
        <Route path='' element={<Meditation />} />
        <Route path={'/meditation/create'} element={<MeditationCreate />} />
        <Route path={'/meditation/edit/:id'} element={<MeditationCreate />} />
      </Route>
    </Routes>
  )
}
