import { FC } from 'react'
import { Button, ButtonGroup, OverlayTrigger, Tooltip } from 'react-bootstrap'
import { useNavigate } from 'react-router-dom'
import { ISchoolTableProps } from '../../../store/api/school/model'
import { useDeleteSchoolMutation } from '../../../store/api/school/school.api'
import { UseToggleModalHOC } from '../../../hooks/toggleModal'
import RemoveItemModal from '../../../utils/RemoveItemModal/removeItemModal'
import UpdateActivate from '../../../ui/UpdateActivate/UpdateActivate'
import { ACTIVATE_TYPE } from '../../../utils/constants'

const SchoolTable: FC<ISchoolTableProps> = ({ school, refetch }): JSX.Element => {
  const navigate = useNavigate()
  const [deleteSchool] = useDeleteSchoolMutation()
  const { isModalVisible, toggleModal } = UseToggleModalHOC()

  return (
    <tr className='text-center' key={school.id}>
      <td>{school.id}</td>
      <td>{school.name}</td>
      <td>{school.email}</td>
      <td>{school.contactPersonName}</td>
      <td>{school.address}</td>
      <td>{school.phoneNumber}</td>
      <td>{school.position}</td>
      <td>
        <span className='d-flex aline-items-center justify-content-between'>
          <span className='w-15'></span>
          <ButtonGroup size='sm' className='flex-nowrap p-1'>
            <OverlayTrigger placement='top' overlay={<Tooltip>Edit</Tooltip>}>
              <Button onClick={() => navigate(`/schools/edit/${school.id}`)}>Edit</Button>
            </OverlayTrigger>
            <OverlayTrigger placement='top' overlay={<Tooltip>Delete</Tooltip>}>
              <Button onClick={() => toggleModal()}>Delete</Button>
            </OverlayTrigger>
          </ButtonGroup>
          <div className='w-15 f-flex aline-items-center justify-content-between'>
            <UpdateActivate
              checked={school.isActive}
              id={school.id}
              type={ACTIVATE_TYPE.School}
              refetch={refetch}
            />
          </div>
        </span>
      </td>
      {isModalVisible && (
        <RemoveItemModal
          id={school.id}
          deleteItem={deleteSchool}
          reload={refetch}
          onClose={() => toggleModal()}
        />
      )}
    </tr>
  )
}
export default SchoolTable
