import { FC } from 'react'
import { Button, ButtonGroup, OverlayTrigger, Tooltip } from 'react-bootstrap'
import { useNavigate } from 'react-router-dom'
import { IWelcomeTablesProps } from '../../../store/api/welcome/model'
import { useDeleteWelcomeMutation } from '../../../store/api/welcome/welcome.api'
import { UseToggleModalHOC } from '../../../hooks/toggleModal'
import RemoveItemModal from '../../../utils/RemoveItemModal/removeItemModal'

const WelcomeTable: FC<IWelcomeTablesProps> = ({ welcome, refetch }): JSX.Element => {
  const [deleteWelcome] = useDeleteWelcomeMutation()
  const navigate = useNavigate()
  const { isModalVisible, toggleModal } = UseToggleModalHOC()

  return (
    <tr className='text-center' key={welcome.id}>
      <td>{welcome.id}</td>
      <td>{welcome.question}</td>
      <td>
        <span className='text-center align-middle'>
          <ButtonGroup size='sm' className='flex-nowrap'>
            <OverlayTrigger placement='top' overlay={<Tooltip>View</Tooltip>}>
              <Button onClick={() => navigate(`/welcome/${welcome.id}/choice`)}>View</Button>
            </OverlayTrigger>
            <OverlayTrigger placement='top' overlay={<Tooltip>Edit</Tooltip>}>
              <Button onClick={() => navigate(`/welcome/edit/${welcome.id}`)}>Edit</Button>
            </OverlayTrigger>
            <OverlayTrigger placement='top' overlay={<Tooltip>Delete</Tooltip>}>
              <Button onClick={() => toggleModal()}>Delete</Button>
            </OverlayTrigger>
          </ButtonGroup>
        </span>
      </td>
      {isModalVisible && (
        <RemoveItemModal
          id={welcome.id}
          deleteItem={deleteWelcome}
          reload={refetch}
          onClose={() => toggleModal()}
        />
      )}
    </tr>
  )
}

export default WelcomeTable
