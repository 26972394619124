import { createApi } from '@reduxjs/toolkit/query/react'
import { formDataCreate } from '../../../utils/formDataUpload/formDataUpload'
import { IGetMood, TCreateMood } from './model'
import { baseQueryWithReauth } from '../apiSlice'

export const moodApi = createApi({
  reducerPath: 'mood/api',
  baseQuery: baseQueryWithReauth,
  endpoints: build => ({
    getMood: build.query<IGetMood[], void>({
      query: () => ({
        url: 'admin/mood'
      })
    }),
    createMood: build.mutation<string, TCreateMood>({
      query: (data: TCreateMood) => ({
        url: 'admin/mood',
        method: 'POST',
        body: formDataCreate(data)
      })
    }),
    updateMood: build.mutation<string, TCreateMood>({
      query: (data: TCreateMood) => ({
        url: `admin/mood/${data.id}`,
        method: 'PUT',
        body: formDataCreate(data)
      })
    }),
    deleteMood: build.mutation<void, string>({
      query: (id: string) => ({
        url: `/admin/mood/${id}`,
        method: 'DELETE'
      })
    })
  })
})
export const {
  useGetMoodQuery,
  useCreateMoodMutation,
  useUpdateMoodMutation,
  useDeleteMoodMutation
} = moodApi
