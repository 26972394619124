import { createApi } from '@reduxjs/toolkit/query/react'
import { IGetTeacher } from './model'
import { baseQueryWithReauth } from '../apiSlice'

export const teacherApi = createApi({
  reducerPath: 'teacher/api',
  baseQuery: baseQueryWithReauth,
  endpoints: build => ({
    getTeachers: build.query<IGetTeacher[], void>({
      query: () => '/admin/teachers'
    })
  })
})

export const { useGetTeachersQuery } = teacherApi
