import React, { Fragment } from 'react'
import { createRoot } from 'react-dom/client'
import Routes from '../src/route/routes'
import { Provider } from 'react-redux'
import { store } from './store'

import './index.scss'
import { ToastContainer } from 'react-toastify'

const container: HTMLElement | null = document.getElementById('root')
const root = createRoot(container as HTMLElement)

root.render(
  <Provider store={store}>
    <Fragment>
      <Routes />
      <ToastContainer />
    </Fragment>
  </Provider>
)
