import { Route, Routes } from 'react-router-dom'
import App from '../components/App'
import Section from '../components/Sections/Section'
import SectionCreate from '../components/Sections/SectionCreate/SectionCreate'

export const SectionsRoutes = (): React.ReactElement => {
  return (
    <Routes>
      <Route path={'/section'} element={<App />}>
        <Route path='' element={<Section />} />
        <Route path='/section/create' element={<SectionCreate />} />
        <Route path='/section/edit/:id' element={<SectionCreate />} />
      </Route>
    </Routes>
  )
}
