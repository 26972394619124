import React, { useEffect, useState } from 'react'
import { Card, Col, FormGroup, Row } from 'react-bootstrap'
// import { closeValidateRequiredError } from '../../../validation/validation'
import { Params, useNavigate, useParams } from 'react-router-dom'
import { toast } from 'react-toastify'
import {
  IChoices,
  IGetWelcomeQuestion,
  TCreateWelcomeQuestion
} from '../../../store/api/welcome/model'
import {
  useCreateWelcomeMutation,
  useGetWelcomeByIdQuery,
  useUpdateWelcomeMutation
} from '../../../store/api/welcome/welcome.api'
import { IValidationRequired } from '../../../validation/model'
import { closeValidateRequiredError } from '../../../validation/validation'
import { responseError, validationRequaired } from '../../../validation/validationRequaired'
import WelcomeChoiceList from './WelcomeChoiceList/WelcomeChoiceList'
import { DeepDiveMetricsType } from '../../../utils/constants'

const WelcomeCreate = (): JSX.Element => {
  const { id }: Readonly<Params<string>> = useParams()
  const navigate = useNavigate()
  const formRef = React.createRef<HTMLFormElement>()
  const { data, refetch } = useGetWelcomeByIdQuery(id, {
    skip: !id
  })
  const [isChoiceValid, setIsChoiceValid] = useState<boolean>(false)
  const [choiceData, setChoiceData] = useState<IChoices[]>([])
  const [errorRequired, setErrorRequired] = useState<IValidationRequired>({})
  const [createWelcome] = useCreateWelcomeMutation()
  const [updateWelcome] = useUpdateWelcomeMutation()
  const [welcome, setWelcome] = useState<IGetWelcomeQuestion>()
  const [metricValue, setMetricValue] = useState<string>(Object.keys(DeepDiveMetricsType)[0])

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>): Promise<void> => {
    event.preventDefault()

    const newWelcomeData: TCreateWelcomeQuestion = {
      question: formRef.current.question.value,
      choices: choiceData,
      metric: metricValue
    }

    setErrorRequired(validationRequaired(newWelcomeData))
    if (!Object.values(validationRequaired(newWelcomeData)).length) {
      if (id) {
        newWelcomeData.id = id
        const res = updateWelcome(newWelcomeData)
        if ('error' in res) {
          responseError(res)
        } else {
          toast.success('Question was updated')
          navigate(-1)
        }
      } else {
        const res = await createWelcome(newWelcomeData)
        if ('error' in res) {
          responseError(res)
        } else {
          toast.success('Question was created')
          navigate(-1)
        }
      }
    }
  }

  const handleChangeChoice = (): void => {
    if (formRef.current.text.value.length < 1 || formRef.current.text.value.length < 1) {
      setIsChoiceValid(true)
    } else {
      setIsChoiceValid(false)
      setChoiceData(prev => [
        ...prev,
        {
          text: formRef.current.text.value,
          tag: formRef.current.tag.value,
          isCorrect: formRef.current.isCorrect.checked
        }
      ])
    }
    formRef.current.text.value = ''
    formRef.current.tag.value = ''
    formRef.current.isCorrect.checked = false
  }
  useEffect(() => {
    if (id) {
      refetch()
    }
  }, [id])

  useEffect(() => {
    setWelcome(data)
  }, [data])

  useEffect(() => {
    setChoiceData([])
    if (welcome) {
      welcome.choices?.length > 0 &&
        welcome.choices.forEach(element => {
          setChoiceData(prev => [
            ...prev,
            {
              id: element.id,
              welcomeQuestionId: welcome.id,
              text: element.text,
              isCorrect: element.isCorrect,
              tag: element?.tag?.tag + ' ' + `(${element?.tag?.tagValue})`
            }
          ])
        })
      setMetricValue(welcome.metric)
    }
  }, [welcome])

  return (
    <div>
      <Card.Header>
        <div>
          <h2>{id ? 'Edit Welcome' : 'Create Welcome'} </h2>
        </div>
      </Card.Header>
      <Row>
        <Col lg={12} xl={12} md={12} sm={12}>
          <Card>
            <form ref={formRef} onSubmit={e => handleSubmit(e)}>
              <Card.Body>
                <Row>
                  <Col xs={6} md={12}>
                    <FormGroup>
                      <label className='text-dark' htmlFor='exampleInputname'>
                        Question
                      </label>
                      <input
                        defaultValue={welcome?.question}
                        name='question'
                        className='form-control'
                        placeholder='Question'
                        onClick={() =>
                          closeValidateRequiredError('question', errorRequired, setErrorRequired)
                        }
                      />
                    </FormGroup>
                    {errorRequired.question && (
                      <span className='text-danger'>{errorRequired.question}</span>
                    )}
                  </Col>

                  <Col xs={6} md={12}>
                    <Col xs={6} md={12}>
                      <label className='text-dark mt-5'>Choices</label>
                    </Col>
                    <Col xs={6} md={12}>
                      <FormGroup>
                        <label className='text-dark  mt-4' htmlFor='exampleInputname'>
                          Text
                        </label>
                        <input
                          onChange={() => setIsChoiceValid(false)}
                          name='text'
                          type='text'
                          className='form-control'
                          placeholder='Text'
                        />
                      </FormGroup>
                    </Col>
                    <Col xs={6} md={2}>
                      <FormGroup>
                        <label className='text-dark  mt-4' htmlFor='exampleInputname'>
                          Metric
                        </label>
                        <select
                          value={metricValue}
                          className='form-select'
                          onChange={({ target }) => setMetricValue(target.value)}
                        >
                          {Object.entries(DeepDiveMetricsType).map(([key, value]) => (
                            <option value={key}>{value}</option>
                          ))}
                        </select>
                      </FormGroup>
                      {errorRequired.metric && (
                        <span className='text-danger'>{errorRequired.metric}</span>
                      )}
                    </Col>
                    <Col xs={6} md={12}>
                      <FormGroup>
                        <label className='text-dark  mt-4' htmlFor='exampleInputname'>
                          Tag
                        </label>
                        <input
                          onChange={() => setIsChoiceValid(false)}
                          name='tag'
                          type='text'
                          className='form-control'
                          placeholder='Tag'
                        />
                      </FormGroup>
                      {isChoiceValid && <span className='text-danger'> choice is required</span>}
                    </Col>
                    <div className='form-check mt-3'>
                      <label className='form-check-label' htmlFor='isCorrect'>
                        Is Correct
                      </label>
                      <input
                        className='form-check-input ms-3'
                        name='isCorrect'
                        type='checkbox'
                        value=''
                        id='isCorrect'
                      />
                    </div>
                    <button onClick={handleChangeChoice} type='button' className='btn btn-info m-2'>
                      Add Answer
                    </button>

                    <WelcomeChoiceList choiceData={choiceData} setChoiceData={setChoiceData} />
                  </Col>
                </Row>
              </Card.Body>
              <Card.Footer className='text-end'>
                <button className='btn btn-success mt-1 me-2'>Save</button>
                <button
                  type='button'
                  className='btn btn-danger mt-1 me-2'
                  onClick={() => navigate(-1)}
                >
                  Cancel
                </button>
              </Card.Footer>
            </form>
          </Card>
        </Col>
      </Row>
    </div>
  )
}

export default WelcomeCreate
