import { createApi } from '@reduxjs/toolkit/dist/query/react'
import { formDataCreate } from '../../../utils/formDataUpload/formDataUpload'
import { IGetMeditionQuestion, TPostMeditationQuestion } from './model'
import { baseQueryWithReauth } from '../apiSlice'

export const meditationApi = createApi({
  reducerPath: 'meditation/api',
  baseQuery: baseQueryWithReauth,
  endpoints: build => ({
    getMeditation: build.query<IGetMeditionQuestion[], void>({
      query: () => ({ url: '/admin/meditation' })
    }),

    getMeditationById: build.query<IGetMeditionQuestion, string>({
      query: (id: string) => ({ url: `/admin/meditation/${id}` })
    }),

    createMeditation: build.mutation<void, TPostMeditationQuestion>({
      query: (data: TPostMeditationQuestion) => ({
        url: 'admin/meditation',
        method: 'POST',
        body: formDataCreate(data)
      })
    }),

    updateMeditation: build.mutation<void, TPostMeditationQuestion>({
      query: (data: TPostMeditationQuestion) => ({
        url: `/admin/meditation/${data.id}`,
        method: 'PUT',
        body: formDataCreate(data)
      })
    }),
    deleteMeditation: build.mutation<void, string>({
      query: (id: string) => ({ url: `/admin/meditation/${id}`, method: 'DELETE' })
    })
  })
})

export const {
  useGetMeditationQuery,
  useGetMeditationByIdQuery,
  useDeleteMeditationMutation,
  useCreateMeditationMutation,
  useUpdateMeditationMutation
} = meditationApi
