import React, { Fragment } from 'react'
import { Outlet } from 'react-router-dom'
import Header from '../layouts/Header/Header'
import Sidebar from '../layouts/SideBar/SideBar'

function App(): JSX.Element {
  return (
    <Fragment>
      <div className='horizontalMenucontainer'>
        <div className='page'>
          <div className='page-main'>
            <Header />
            <Sidebar />
            <div className='main-content app-content '>
              <div className='side-app'>
                <div className='main-container container-fluid'>
                  <Outlet />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  )
}

export default App
