import { FC } from 'react'
import { stringToDate } from '../../../utils/date'
import {
  IWellnessCheckins,
  WELLNESS_CHECKINS_SLUG_TYPU
} from '../../../store/api/WellnessCheckins/model'

const WellnessCheckinsTable: FC<{ WellnessCheckins: IWellnessCheckins }> = ({
  WellnessCheckins
}): JSX.Element => {
  return (
    <tr className='text-center' key={WellnessCheckins.id}>
      <td>{WellnessCheckins.id}</td>
      <td>{WellnessCheckins.user.fullName}</td>
      <td>{WellnessCheckins.user.email}</td>
      <td>{WELLNESS_CHECKINS_SLUG_TYPU[WellnessCheckins.slug]}</td>
      <td>{WellnessCheckins.user.role.name}</td>
      <td>{WellnessCheckins.user.package?.name}</td>
      <td>{stringToDate(WellnessCheckins.created_at)}</td>
    </tr>
  )
}

export default WellnessCheckinsTable
