import { Card, Col, FormGroup, Row } from 'react-bootstrap'
import Modal from '../../../ui/modal/Modal'
import { ModalHeader } from '../../../ui/modal/ModalHeader'
import React, { FC, useState } from 'react'
import { useCreateCharactersMutation } from '../../../store/api/ character/character.api'
import { responseError, validationRequaired } from '../../../validation/validationRequaired'
import { IValidationRequired } from '../../../validation/model'
import { TCreateCharacter } from '../../../store/api/ character/model'
import { closeValidateRequiredError } from '../../../validation/validation'
import { formDataCreate } from '../../../utils/formDataUpload/formDataUpload'
import { getImageUrl, showImage } from '../../../utils/imagesFunction'
import { toast } from 'react-toastify'

const CharacterCreate: FC<{ onClose: () => void }> = ({ onClose }): JSX.Element => {
  const formRef = React.createRef<HTMLFormElement>()
  const [selectedFile, setSelectedFile] = React.useState<File | null>(null)
  const [imagePreview, setImagePreview] = useState<string>('')
  const [isImageUpload, setIsImageUpload] = useState<boolean>(false)
  const [errorRequaired, seterrorRequaired] = useState<IValidationRequired>({})
  const [createCharacter] = useCreateCharactersMutation()
  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>): Promise<void> => {
    event.preventDefault()
    const newCharacter: TCreateCharacter = {
      name: formRef?.current.namee.value,
      file: selectedFile
    }

    seterrorRequaired(validationRequaired(newCharacter))
    if (!Object.values(validationRequaired(newCharacter)).length) {
      const res = await createCharacter(formDataCreate(newCharacter))
      if ('error' in res) {
        responseError(res)
      } else {
        toast.success('Character was created')
        onClose()
      }
    }
  }
  const selectImage = (e: React.ChangeEvent<HTMLInputElement>): void => {
    setImagePreview(getImageUrl(e) ?? '')
    setIsImageUpload(true)
  }
  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    if (event.target.files && event.target.files.length > 0) {
      setSelectedFile(event.target.files[0])
    }
    selectImage(event)
  }
  return (
    <Modal>
      <div>
        <ModalHeader>
          <div>
            <h2>Create Character</h2>
          </div>
        </ModalHeader>
        <Row>
          <Col lg={12} xl={12} md={12} sm={12}>
            <Card>
              <form ref={formRef} onSubmit={e => handleSubmit(e)}>
                <Card.Body>
                  <Row>
                    <Col xs={6} md={12}>
                      <FormGroup>
                        <label className='text-dark' htmlFor='exampleInputname'>
                          Name
                        </label>
                        <input
                          name='namee'
                          type='text'
                          className='form-control'
                          placeholder='Name'
                          onClick={() =>
                            closeValidateRequiredError('name', errorRequaired, seterrorRequaired)
                          }
                        />
                      </FormGroup>
                      {errorRequaired.name && (
                        <span className='text-danger'>{errorRequaired.name}</span>
                      )}
                    </Col>
                    <Col xs={6} md={12}>
                      <FormGroup>
                        <label className='text-dark mt-5' htmlFor='file'>
                          File
                        </label>
                        <input
                          id='file'
                          name='fileUrl'
                          type='file'
                          className='form-control'
                          placeholder='File Url'
                          accept='image/*'
                          onChange={e => {
                            handleFileChange(e)
                            closeValidateRequiredError('file', errorRequaired, seterrorRequaired)
                          }}
                        />
                      </FormGroup>
                      {errorRequaired.file && (
                        <span className='text-danger'>{errorRequaired.file}</span>
                      )}
                    </Col>
                    <div>{isImageUpload && showImage(imagePreview)}</div>
                  </Row>
                </Card.Body>
                <Card.Footer className='text-end'>
                  <button className='btn btn-success mt-1 me-2'>Save</button>
                  <button type='button' className='btn btn-danger mt-1 me-2' onClick={onClose}>
                    Cancel
                  </button>
                </Card.Footer>
              </form>
            </Card>
          </Col>
        </Row>
      </div>
    </Modal>
  )
}
export default CharacterCreate
