import { FC, useState } from 'react'
import { Button, ButtonGroup, OverlayTrigger, Tooltip } from 'react-bootstrap'
import { useNavigate } from 'react-router-dom'
import { lessMore } from '../../../ui/lessMore/lessMore'
import { isSelectedDescription } from '../../../utils/IsSelectedDescription'
import { IResourceTableProps } from './model'

const ResourceTable: FC<IResourceTableProps> = ({
  resource,
  setResourceIds,
  resourceIds,
  toggleModal
}): JSX.Element => {
  const navigate = useNavigate()

  const [selectedDescription, setSelectedDescription] = useState<string[]>([])

  const handleCheckboxChange = (): void => {
    const { id } = resource
    if (!resourceIds.includes(id)) {
      setResourceIds(prev => [...prev, id])
    } else {
      setResourceIds(prev => prev.filter(resourceId => resourceId !== id))
    }
  }

  return (
    <tr
      className='text-center'
      style={{ cursor: 'pointer' }}
      key={resource.id}
      onClick={() => handleCheckboxChange()}
    >
      <td>
        <input
          type='checkbox'
          checked={resourceIds.includes(resource.id)}
          onChange={() => handleCheckboxChange()}
        />
      </td>
      <td>{resource.order}</td>
      <td className='text-start'>{resource.name}</td>
      <td className='text-start'>{resource?.title}</td>
      <td>{resource?.package?.name}</td>
      <td>
        <img style={{ width: '100px' }} src={resource.imageUrl && resource.imageUrl} alt='' />
      </td>
      <td style={{ width: '150px' }}>
        <div
          style={{
            whiteSpace: isSelectedDescription(resource.fileUrl, selectedDescription)
              ? 'normal'
              : 'nowrap',
            maxWidth: '150px'
          }}
          className={
            isSelectedDescription(resource.fileUrl, selectedDescription)
              ? 'text-center'
              : 'd-inline-block text-truncate text-center'
          }
        >
          {resource.fileUrl && resource.fileUrl}
        </div>
        {resource.fileUrl && resource.fileUrl?.length > 14 && (
          <button
            className='text-center text-info'
            onClick={() => lessMore(resource.fileUrl, selectedDescription, setSelectedDescription)}
          >
            {isSelectedDescription(resource.fileUrl, selectedDescription) ? 'less' : 'more'}
          </button>
        )}
      </td>
      <td>
        {resource.forTeacher ? (
          <i className='dropdown-icon fe fe-check' style={{ color: 'green' }}></i>
        ) : (
          <i className='dropdown-icon fe fe-x' style={{ color: 'red' }}></i>
        )}
      </td>
      <td>
        <span className='text-center align-middle'>
          <ButtonGroup size='sm' className='flex-nowrap'>
            <OverlayTrigger placement='top' overlay={<Tooltip>Edit</Tooltip>}>
              <Button onClick={() => navigate(`/resources/edit/${resource.id}`)}>Edit</Button>
            </OverlayTrigger>
            <OverlayTrigger placement='top' overlay={<Tooltip>Delete</Tooltip>}>
              <Button
                onClick={() => {
                  toggleModal()
                  setResourceIds([resource.id])
                }}
              >
                Delete
              </Button>
            </OverlayTrigger>
          </ButtonGroup>
        </span>
      </td>
    </tr>
  )
}

export default ResourceTable
