import { FC } from 'react'
import { getInCookies } from '../utils/localStorageFunc'
import { Navigate } from 'react-router-dom'

const ProtectedRoute: FC<{ children: JSX.Element }> = ({ children }): JSX.Element => {
  if (getInCookies('access_token')) {
    return children
  }

  return <Navigate to='/auth/signin' />
}

export default ProtectedRoute
