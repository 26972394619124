import React, { FC, useState } from 'react'
import { Card, Col, FormGroup, Row } from 'react-bootstrap'
import { IValidationRequired } from '../../../validation/model'
import { responseError, validationRequaired } from '../../../validation/validationRequaired'
import { closeValidateRequiredError } from '../../../validation/validation'
import { TCreateMood, IGetMood } from '../../../store/api/mood/model'
import { useCreateMoodMutation, useUpdateMoodMutation } from '../../../store/api/mood/mood.api'
import { ModalHeader } from '../../../ui/modal/ModalHeader'
import Modal from '../../../ui/modal/Modal'
import { getImageUrl, showImage } from '../../../utils/imagesFunction'
import { toast } from 'react-toastify'
const MoodCreate: FC<{
  editData: IGetMood
  setIsOpenCreateModal: React.Dispatch<React.SetStateAction<boolean>>
}> = ({ editData, setIsOpenCreateModal }): JSX.Element => {
  const formRef = React.createRef<HTMLFormElement>()
  const [errorRequaired, seterrorRequaired] = useState<IValidationRequired>({})
  const [selectedFile, setSelectedFile] = useState<File | null>(null)
  const [imagePreview, setImagePreview] = useState<string>('')
  const [isImageUpload, setIsImageUpload] = useState<boolean>(false)

  const [createMood] = useCreateMoodMutation()
  const [updateMood] = useUpdateMoodMutation()
  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>): Promise<void> => {
    event.preventDefault()
    const newData: TCreateMood = {
      name: formRef?.current.namee.value,
      file: selectedFile,
      color: formRef?.current.color.value
    }
    seterrorRequaired(validationRequaired(newData))
    if (!Object.values(validationRequaired(newData)).length) {
      if (editData?.id) {
        newData.id = editData?.id
        const res = await updateMood(newData)
        if ('error' in res) {
          responseError(res)
        } else {
          toast.success('Mood was updated')
          setIsOpenCreateModal(false)
        }
      } else {
        const res = await createMood(newData)
        if ('error' in res) {
          responseError(res)
        } else {
          toast.success('Mood was created')
          setIsOpenCreateModal(false)
        }
      }
    }
  }

  const selectImage = (e: React.ChangeEvent<HTMLInputElement>): void => {
    setImagePreview(getImageUrl(e) ?? '')
    setIsImageUpload(true)
  }
  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    if (event.target.files && event.target.files.length > 0) {
      setSelectedFile(event.target.files[0])
    }
    selectImage(event)
  }

  return (
    <Modal>
      <div>
        <ModalHeader>
          <div>
            <h2>{editData?.id ? 'Edit Mood' : 'Create Mood'} </h2>
          </div>
        </ModalHeader>
        <Row>
          <Col lg={12} xl={12} md={12} sm={12}>
            <Card>
              <form ref={formRef} onSubmit={e => handleSubmit(e)}>
                <Card.Body>
                  <Row>
                    <Col xs={6} md={12}>
                      <FormGroup>
                        <label className='text-dark' htmlFor='name'>
                          Name
                        </label>
                        <input
                          defaultValue={editData?.name}
                          id='namee'
                          name='namee'
                          type='text'
                          className='form-control'
                          placeholder='Name'
                          onClick={() =>
                            closeValidateRequiredError('name', errorRequaired, seterrorRequaired)
                          }
                        />
                      </FormGroup>
                      {errorRequaired.name && (
                        <span className='text-danger'>{errorRequaired.name}</span>
                      )}
                    </Col>
                    <Col xs={6} md={12}>
                      <FormGroup>
                        <label className='text-dark' htmlFor='color'>
                          Description
                        </label>
                        <input
                          defaultValue={editData?.color}
                          id='color'
                          name='color'
                          type='text'
                          className='form-control'
                          placeholder='color'
                          onClick={() =>
                            closeValidateRequiredError('color', errorRequaired, seterrorRequaired)
                          }
                        />
                      </FormGroup>
                      {errorRequaired.color && (
                        <span className='text-danger'>{errorRequaired.color}</span>
                      )}
                    </Col>
                    <Col xs={6} md={12}>
                      <FormGroup>
                        <label className='text-dark mt-5' htmlFor='file'>
                          File
                        </label>
                        <input
                          id='file'
                          name='fileUrl'
                          type='file'
                          className='form-control'
                          placeholder='File Url'
                          accept='image/*'
                          onClick={() =>
                            closeValidateRequiredError('file', errorRequaired, seterrorRequaired)
                          }
                          onChange={e => handleFileChange(e)}
                        />
                      </FormGroup>
                      {errorRequaired.file && (
                        <span className='text-danger'>{errorRequaired.file}</span>
                      )}
                    </Col>
                    <div>
                      {isImageUpload && showImage(imagePreview)}
                      {editData?.fileUrl && !isImageUpload && (
                        <div className='avatar avatar-lg  brround cover-image bg-primary mr-10'>
                          <img
                            style={{ width: '200px' }}
                            src={editData?.fileUrl}
                            className='avatar avatar-lg  brround cover-image bg-primary'
                            alt=''
                          />
                        </div>
                      )}
                    </div>
                  </Row>
                </Card.Body>
                <Card.Footer className='text-end'>
                  <button className='btn btn-success mt-1 me-2'>Save</button>
                  <button
                    onClick={() => setIsOpenCreateModal(false)}
                    type='button'
                    className='btn btn-danger mt-1 me-2'
                  >
                    Cancel
                  </button>
                </Card.Footer>
              </form>
            </Card>
          </Col>
        </Row>
      </div>
    </Modal>
  )
}

export default MoodCreate
