import { BrowserRouter, Route, Routes } from 'react-router-dom'
import React, { Fragment } from 'react'
import App from '../components/App'
import Dashboard from '../components/Dashboard/Dashboard'
import { AuthRoutes } from './authRoutes'
import ProtectedRoute from '../hoc/ProtectedRoute'
import { LessonRoutes } from './lessonRoutes'
import { UsersRoutes } from './usersRoutes'
import { ClassRoutes } from './classRoutes'
import { CharacterRoutes } from './characterRoutes'
import { QuestionRoutes } from './questionRoutes'
import { ResourceRoutes } from './resourceRoutes'
import { GroupsRoutes } from './groupsRoutes'
import { QuizRoutes } from './quizRoutes'
import { MoodRoutes } from './moodRoutes'
import Profile from '../components/Profile/Profile'
import { SchoolsRoutes } from './schoolRoutes'
import { WelcomeRoutes } from './welcomeRoutes'
import { MeditationRoutes } from './MeditationRoutes'
import { WellnessCheckinsRoutes } from './WellnessCheckinsRouters'
import { LessonPlansRoutes } from './lessonPlansRoutes'
import { SectionsRoutes } from './sectionsRoutes'
import { SurveyRoutes } from './surveyRoutes'
const AppRoutes = (): React.ReactElement<React.ReactFragment> => {
  return (
    <BrowserRouter>
      <ProtectedRoute>
        <Fragment>
          <Routes>
            <Route path='/' element={<App />}>
              <Route path='/' element={<Dashboard />} />
              <Route path='/dashboard' element={<Dashboard />} />
              <Route path='/profile' element={<Profile />} />
            </Route>
          </Routes>
          <LessonRoutes />
          <LessonPlansRoutes />
          <UsersRoutes />
          <WellnessCheckinsRoutes />
          <ClassRoutes />
          <GroupsRoutes />
          <CharacterRoutes />
          <QuizRoutes />
          <QuestionRoutes />
          <MoodRoutes />
          <ResourceRoutes />
          <SchoolsRoutes />
          <WelcomeRoutes />
          <MeditationRoutes />
          <SectionsRoutes />
          <SurveyRoutes />
        </Fragment>
      </ProtectedRoute>
      <AuthRoutes />
    </BrowserRouter>
  )
}
export default AppRoutes
