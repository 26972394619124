import { Route, Routes } from 'react-router-dom'
import App from '../components/App'
import Resource from '../components/Resource/Resource'
import ResourceCreate from '../components/Resource/ResourceCreate/ResourceCreate'
import Category from '../components/Category/Category'

export const ResourceRoutes = (): React.ReactElement => {
  return (
    <Routes>
      <Route path={'/resources'} element={<App />}>
        <Route path='' element={<Resource />} />
        <Route path='/resources/create' element={<ResourceCreate />} />
        <Route path='/resources/edit/:id' element={<ResourceCreate />} />
        <Route path='/resources/category' element={<Category />} />
      </Route>
    </Routes>
  )
}
