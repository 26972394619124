import React, { FC, useState } from 'react'
import { Card, Col, FormGroup, Row } from 'react-bootstrap'
import { IChoices, TWelcomeChoiceListProps } from '../../../../store/api/welcome/model'
import Modal from '../../../../ui/modal/Modal'

const WelcomeChoiceList: FC<TWelcomeChoiceListProps> = ({
  choiceData,
  setChoiceData
}): JSX.Element => {
  const formRef = React.createRef<HTMLFormElement>()
  const [isEditChoice, setIsEditChoice] = useState<boolean>(false)
  const [idChoice, setIdChoice] = useState<number>(null)

  const handleClick = (): void => {
    setIsEditChoice(false)
    setChoiceData(prev => {
      const updatedChoiceData: IChoices[] = prev.map((item, index) => {
        if (index === idChoice) {
          return {
            id: item.id,
            welcomeQuestionId: item?.welcomeQuestionId,
            text: formRef.current.text.value,
            tag: formRef.current.tag.value,
            isCorrect: formRef.current.isCorrect.checked
          }
        }
        return item
      })

      return updatedChoiceData
    })
  }

  return (
    <>
      {choiceData.length
        ? choiceData?.map((choice, index) => (
            <div key={index} className='question-answer-list text-dark mt-3 border'>
              <p>Text - {choice.text}</p>
              <p>Tag - {choice.tag}</p>
              <p>
                is Correct - <input type='checkbox' readOnly checked={choice.isCorrect} />
              </p>
              <button
                type='button'
                onClick={() => {
                  setIdChoice(index)
                  setIsEditChoice(true)
                }}
                className='text-info'
              >
                Edit
              </button>
              <button
                type='button'
                onClick={() =>
                  setChoiceData(prev => {
                    return [...prev.filter((element, i) => i !== index)]
                  })
                }
                className='text-danger mx-1'
              >
                X
              </button>
            </div>
          ))
        : null}
      {isEditChoice && (
        <Modal>
          <Card>
            <Card.Header>
              <h4 className='text-dark'>Edit Choice</h4>
            </Card.Header>
            <form ref={formRef} onSubmit={event => event.preventDefault()}>
              <Card.Body>
                <Row>
                  <Col xs={6} md={12}>
                    <FormGroup>
                      <label className='text-dark  mt-5' htmlFor='text'>
                        Text
                      </label>
                      <input
                        id='text'
                        name='text'
                        type='text'
                        className='form-control'
                        defaultValue={choiceData[idChoice].text}
                      />
                    </FormGroup>
                  </Col>
                  <Col xs={6} md={12}>
                    <FormGroup>
                      <label className='text-dark  mt-5' htmlFor='tag'>
                        Tag
                      </label>
                      <input
                        id='tag'
                        name='tag'
                        type='text'
                        className='form-control'
                        defaultValue={choiceData[idChoice].tag}
                      />
                    </FormGroup>
                  </Col>
                  <div className='form-check mt-3'>
                    <label className='form-check-label' htmlFor='isCorrect'>
                      Is Correct
                    </label>
                    <input
                      className='form-check-input ms-3'
                      name='isCorrect'
                      type='checkbox'
                      defaultChecked={choiceData[idChoice].isCorrect}
                      readOnly
                      id='isCorrect'
                    />
                  </div>
                </Row>
              </Card.Body>
              <Card.Footer>
                <button onClick={handleClick} type='button' className='btn btn-success mt-1 me-2'>
                  Save
                </button>
                <button
                  type='button'
                  className='btn btn-danger mt-1 me-2'
                  onClick={() => setIsEditChoice(false)}
                >
                  Cancel
                </button>
              </Card.Footer>
            </form>
          </Card>
        </Modal>
      )}
    </>
  )
}

export default WelcomeChoiceList
