import { FC } from 'react'
import { stringToDate } from '../../../utils/date'
import { Button, ButtonGroup, OverlayTrigger, Tooltip } from 'react-bootstrap'
import { useDeleteCategoryMutation } from '../../../store/api/category/category.api'
import { ICategoryTableProps } from '../../../store/api/category/model'
import { UseToggleModalHOC } from '../../../hooks/toggleModal'
import RemoveItemModal from '../../../utils/RemoveItemModal/removeItemModal'

const CategoryTable: FC<ICategoryTableProps> = ({
  category,
  refetch,
  setEditCategory,
  setOpenCreateModal
}): JSX.Element => {
  const [deleteCategory] = useDeleteCategoryMutation()
  const { isModalVisible, toggleModal } = UseToggleModalHOC()
  return (
    <tr className='text-center' key={category.id}>
      <td>{category.id}</td>
      <td>{category.name}</td>
      <td>{stringToDate(category.created_at)}</td>
      <td>{stringToDate(category.updated_at)}</td>
      <td>
        <span className='text-center align-middle'>
          <ButtonGroup size='sm' className='flex-nowrap'>
            <OverlayTrigger placement='top' overlay={<Tooltip>Edit</Tooltip>}>
              <Button
                onClick={() => {
                  setEditCategory({ ...category })
                  setOpenCreateModal(true)
                }}
              >
                Edit
              </Button>
            </OverlayTrigger>
            <OverlayTrigger placement='top' overlay={<Tooltip>Delete</Tooltip>}>
              <Button onClick={() => toggleModal()}>Delete</Button>
            </OverlayTrigger>
          </ButtonGroup>
        </span>
      </td>
      {isModalVisible && (
        <RemoveItemModal
          id={category.id}
          deleteItem={deleteCategory}
          reload={refetch}
          onClose={() => toggleModal()}
        />
      )}
    </tr>
  )
}
export default CategoryTable
