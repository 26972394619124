import { FC, useState } from 'react'
import { useDeleteLessonMutation } from '../../../store/api/lesson/lesson.api'
import { ILessonTable } from '../../../store/api/lesson/model'
import { stringToDate } from '../../../utils/date'
import { Button, ButtonGroup, OverlayTrigger, Tooltip } from 'react-bootstrap'
import { useNavigate } from 'react-router-dom'
import { lessMore } from '../../../ui/lessMore/lessMore'
import { isSelectedDescription } from '../../../utils/IsSelectedDescription'
import { UseToggleModalHOC } from '../../../hooks/toggleModal'
import RemoveItemModal from '../../../utils/RemoveItemModal/removeItemModal'

const LessonTable: FC<ILessonTable> = ({ lesson, refetch }): JSX.Element => {
  const [deleteLesson] = useDeleteLessonMutation()
  const navigate = useNavigate()
  const [selectedDescription, setSelectedDescription] = useState<string[]>([])
  const { isModalVisible, toggleModal } = UseToggleModalHOC()

  return (
    <tr key={lesson.id} className='text-center align-middle'>
      <td>{lesson.order}</td>
      <td style={{ width: '90px' }}>
        <div
          style={{
            whiteSpace: isSelectedDescription(lesson.name, selectedDescription)
              ? 'normal'
              : 'nowrap',
            maxWidth: '90px'
          }}
          className={
            isSelectedDescription(lesson.name, selectedDescription)
              ? 'text-center'
              : 'd-inline-block text-truncate text-center'
          }
        >
          {lesson.name}
        </div>
        {lesson.name?.length > 14 && (
          <button
            className='text-center text-info'
            onClick={() => lessMore(lesson.name, selectedDescription, setSelectedDescription)}
          >
            {isSelectedDescription(lesson.name, selectedDescription) ? 'less' : 'more'}
          </button>
        )}
      </td>
      <td>
        <img style={{ width: '50px' }} src={lesson?.imageUrl} alt='' />
      </td>
      <td style={{ width: '150px' }}>
        <div
          style={{
            whiteSpace: isSelectedDescription(lesson.description, selectedDescription)
              ? 'normal'
              : 'nowrap',
            maxWidth: '150px'
          }}
          className={
            isSelectedDescription(lesson.description, selectedDescription)
              ? 'text-center'
              : 'd-inline-block text-truncate text-center'
          }
        >
          {lesson.description}
        </div>
        {lesson.description?.length > 14 && (
          <button
            className='text-center text-info'
            onClick={() =>
              lessMore(lesson.description, selectedDescription, setSelectedDescription)
            }
          >
            {isSelectedDescription(lesson.description, selectedDescription) ? 'less' : 'more'}
          </button>
        )}
      </td>
      <td>{lesson.duration && lesson.duration}</td>
      <td style={{ width: '150px' }}>
        <div
          style={{
            whiteSpace: isSelectedDescription(lesson.url, selectedDescription)
              ? 'normal'
              : 'nowrap',
            maxWidth: '150px'
          }}
          className={
            isSelectedDescription(lesson.url, selectedDescription)
              ? 'text-center'
              : 'd-inline-block text-truncate text-center'
          }
        >
          {lesson.url && lesson.url}
        </div>
        {lesson.url?.length > 14 && (
          <button
            className='text-center text-info'
            onClick={() => lessMore(lesson.url, selectedDescription, setSelectedDescription)}
          >
            {isSelectedDescription(lesson.url, selectedDescription) ? 'less' : 'more'}
          </button>
        )}
      </td>
      <td className='align-middle'>{stringToDate(lesson.startDate)}</td>
      <td className='align-middle'>{stringToDate(lesson.created_at)}</td>
      <td className='align-middle'>
        <span className='text-center align-middle'>
          <ButtonGroup size='sm' className='flex-nowrap'>
            <OverlayTrigger placement='top' overlay={<Tooltip>Edit</Tooltip>}>
              <Button onClick={() => navigate(`/lesson/edit/${lesson.id}`)}>Edit</Button>
            </OverlayTrigger>
            <OverlayTrigger placement='top' overlay={<Tooltip>View</Tooltip>}>
              <Button onClick={() => toggleModal()}>Delete</Button>
            </OverlayTrigger>
          </ButtonGroup>
        </span>
      </td>
      {isModalVisible && (
        <RemoveItemModal
          id={lesson.id}
          deleteItem={deleteLesson}
          reload={refetch}
          onClose={() => toggleModal()}
        />
      )}
    </tr>
  )
}

export default LessonTable
