export const togglePasswordIcon = (
  setTypePassword: React.Dispatch<React.SetStateAction<string>>,
  typePassword: string
): void => {
  if (typePassword === 'password') {
    setTypePassword('text')
  } else if (typePassword === 'text') {
    setTypePassword('password')
  }
}
