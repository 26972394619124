import { Route, Routes } from 'react-router-dom'
import Groups from '../components/Groups/Groups'
import App from '../components/App'
import GroupCreate from '../components/Groups/GroupCreate/GroupCreate'

export const GroupsRoutes = (): React.ReactElement => {
  return (
    <Routes>
      <Route path='/groups' element={<App />}>
        <Route path='' element={<Groups />} />
        <Route path='/groups/create' element={<GroupCreate />} />
        <Route path='/groups/edit/:id' element={<GroupCreate />} />
      </Route>
    </Routes>
  )
}
