import React, { useEffect, useState } from 'react'
import { Button, Card, Col, FormGroup, Row } from 'react-bootstrap'
import 'react-calendar/dist/Calendar.css'
import 'react-clock/dist/Clock.css'
import DateTimePicker from 'react-datetime-picker'
import 'react-datetime-picker/dist/DateTimePicker.css'
import { Params, useNavigate, useParams } from 'react-router-dom'
import { toast } from 'react-toastify'
import { useGetSchoolQuery } from '../../../store/api/school/school.api'
import { ICreateUser, IUserById } from '../../../store/api/users/model'
import {
  useCreateUserMutation,
  useGetUserByIdQuery,
  useGetUserRoleQuery,
  useUpdateUserMutation
} from '../../../store/api/users/users.api'
import SingleSelect from '../../../ui/select/SingleSelect'
import { ISelectData } from '../../../ui/select/model'
import { selectedDataTake } from '../../../utils/selectedDataTake/selectedDataTake'
import { IValidationRequired, TKeyValueObj } from '../../../validation/model'
import {
  closeValidateRequiredError,
  closeValidateSintaxError
} from '../../../validation/validation'
import { responseError, validationRequaired } from '../../../validation/validationRequaired'
import { useGetPackagesQuery } from '../../../store/api/packages/packages.api'
import { useGetSectionGradesQuery } from '../../../store/api/section/section.api'
import { GENDER, USER_ROLE } from '../../../utils/constants'
import { IGetSectionGradesInput } from '../../../store/api/section/model'
import Select from 'react-select'
import { createSingleSelectData } from '../../../utils/createSingleSelectData'

const UsersCreate = (): JSX.Element => {
  const navigate = useNavigate()
  const { id }: Readonly<Params<string>> = useParams()
  const formRef = React.createRef<HTMLFormElement>()
  const [validationErr, setValidationErr] = useState<TKeyValueObj>()
  const [errorRequired, setErrorRequired] = useState<IValidationRequired>({})
  const [isPackagesRequired, setIsPackagesRequired] = useState<boolean>(false)
  const [isRequiredSchool, setIsRequiredSchool] = useState<boolean>(false)
  const [isClassRequired, setIsClassRequired] = useState<boolean>(false)

  const { data, refetch: refetchUser } = useGetUserByIdQuery(id, {
    skip: !id
  })
  const [sectionGradesInput, setSectionGradesInput] = useState<IGetSectionGradesInput | undefined>()
  const { data: getSectionGradesData } = useGetSectionGradesQuery(sectionGradesInput, {
    skip: !sectionGradesInput?.schoolId
  })
  const dataPackages = useGetPackagesQuery()
  const { data: schools } = useGetSchoolQuery()
  const { data: roles } = useGetUserRoleQuery()
  const [createUser] = useCreateUserMutation()
  const [updateUser] = useUpdateUserMutation()

  const [userData, setUserData] = useState<IUserById>()
  const [startValue, setStartValue] = useState<Date | null>(new Date())
  const [selectRoleId, setSelectRoleId] = useState<number>(Number(USER_ROLE.Admin))
  const [section, setSection] = useState<ISelectData[]>([])
  const [sectionSelect, setSectionSelect] = useState<ISelectData>()

  const [schoolData, setSchoolData] = useState<ISelectData[]>([])
  const [schoolSelected, setSchoolSelected] = useState<ISelectData>()

  const [packagesSelected, setPackagesSelected] = useState<ISelectData>()
  const [packages, setPackages] = useState<ISelectData[]>([])

  const [genderSelected, setGendersSelected] = useState<ISelectData>()

  const [classes, setClasses] = useState<ISelectData[]>([])
  const [classSelect, setClassSelect] = useState<ISelectData | null>(null)

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>): Promise<void> => {
    e.preventDefault()
    const userInform: ICreateUser = {
      email: formRef?.current?.email.value.trim(),
      fullName: formRef?.current?.fullName.value,
      roleId: Number(formRef?.current?.roleId?.value),
      isActive: formRef?.current?.isActive.value,
      dateOfBirth: startValue.toISOString(),
      packageId: packagesSelected?.value
    }

    const roleIdValue = formRef?.current?.roleId?.value
    const isUserInStudentOrTeacher =
      roleIdValue === USER_ROLE.Student || roleIdValue === USER_ROLE.Teacher

    if (formRef?.current?.schoolNumber?.value.length > 0 && roleIdValue === USER_ROLE.Student) {
      userInform.schoolInnerId = formRef?.current?.schoolNumber?.value
    }
    if (roleIdValue === USER_ROLE.Teacher) {
      userInform.position = formRef.current?.position?.value
    }
    if (genderSelected?.label) {
      userInform.gender = genderSelected?.label
    }

    if (isUserInStudentOrTeacher && schoolSelected?.value) {
      userInform.schoolId = schoolSelected?.value
    }
    if (roleIdValue === USER_ROLE.Student) {
      userInform.classId = classSelect?.value
      if (sectionGradesInput?.schoolId && sectionSelect?.value) {
        userInform.sectionId = sectionSelect?.value
      }
    }

    if (
      !userData?.googleId &&
      (!id ||
        (id &&
          (formRef?.current?.password?.value !== '' ||
            formRef?.current?.confirmPassword?.value !== '')))
    ) {
      userInform.password = formRef?.current?.password?.value
      userInform.confirmPassword = formRef?.current?.confirmPassword?.value
    }

    let isSchool = true
    typeof schoolSelected === 'undefined' && setIsRequiredSchool(true)
    if (!classSelect) {
      setIsClassRequired(true)
    }
    if (isUserInStudentOrTeacher && typeof schoolSelected === 'undefined') {
      isSchool = false
    }

    typeof packagesSelected === 'undefined' && setIsPackagesRequired(true)
    setErrorRequired(validationRequaired(userInform))
    if (!Object.values(validationRequaired(userInform)).length && isSchool) {
      if (id) {
        userInform.id = Number(id)
        const res = await updateUser(userInform)
        if ('error' in res) {
          responseError(res)
        } else {
          toast.success('User was updated')
          navigate(-1)
        }
      } else {
        const res = await createUser(userInform)
        if ('error' in res) {
          responseError(res)
        } else {
          toast.success('User was created')
          navigate(-1)
        }
      }
    }
  }

  const toggleIsActive = (): boolean => {
    if (id === undefined && userData?.isActive === undefined) {
      setUserData(prev => ({ ...prev, isActive: !userData?.isActive }))
      return true
    } else {
      return userData?.isActive ?? false
    }
  }

  useEffect(() => {
    if (userData) {
      setSelectRoleId(userData.roleId)
      if (
        userData.roleId === Number(USER_ROLE.Student) ||
        userData.roleId === Number(USER_ROLE.Teacher)
      ) {
        setSchoolSelected(createSingleSelectData(userData?.school?.name, userData?.school?.id))
      }
      if (userData.package) {
        setPackagesSelected(createSingleSelectData(userData.package.name, userData.package.id))
      }
      if (userData?.student?.class) {
        setClassSelect(
          createSingleSelectData(userData.student.class.name, userData.student.class.id)
        )
      }
      if (userData?.student?.section && userData.roleId === Number(USER_ROLE.Student)) {
        setSectionSelect(
          createSingleSelectData(userData?.student?.section.name, userData?.student?.section.id)
        )
      }
      if (userData.gender) {
        const findGander = GENDER.find(gender => gender.label === userData.gender)
        if (findGander) {
          setGendersSelected(createSingleSelectData(userData.gender, findGander.value))
        }
      }
    }
  }, [userData])

  useEffect(() => {
    if (schoolSelected?.value) {
      setSectionGradesInput({
        schoolId: schoolSelected?.value
      })
    }
  }, [schoolSelected])

  useEffect(() => {
    if (classSelect?.value) {
      setSectionGradesInput(prev => ({
        ...prev,
        classId: classSelect?.value
      }))
      setIsClassRequired(false)
    }
  }, [classSelect])

  useEffect(() => {
    if (getSectionGradesData?.classes) {
      setClasses([])
      selectedDataTake(getSectionGradesData?.classes, setClasses)
    }
  }, [getSectionGradesData?.classes])

  useEffect(() => {
    if (getSectionGradesData?.sections) {
      selectedDataTake(getSectionGradesData?.sections, setSection)
    }
  }, [getSectionGradesData?.sections])

  useEffect(() => {
    selectedDataTake(schools, setSchoolData)
  }, [schools])

  useEffect(() => {
    selectedDataTake(dataPackages?.data, setPackages)
  }, [dataPackages.data])

  useEffect(() => {
    if (id) {
      refetchUser()
    }
  }, [id])

  useEffect(() => {
    if (data) {
      setUserData(data)
    }
  }, [data])

  return (
    <div className='m-5'>
      <Card.Header>
        <div>
          <h2>{id ? 'Edit User' : 'Create user'}</h2>
        </div>
      </Card.Header>
      <Row>
        <Col lg={12} xl={12} md={12} sm={12}>
          <Card>
            <form ref={formRef} onSubmit={e => handleSubmit(e)}>
              <Card.Body>
                <Row>
                  <Col xs={6} md={12}>
                    <FormGroup>
                      <label className='text-dark' htmlFor='exampleInputname'>
                        Email
                      </label>
                      <input
                        defaultValue={userData?.email}
                        name='email'
                        type='text'
                        className='form-control'
                        placeholder='User Email'
                        onClick={() => {
                          closeValidateRequiredError('email', errorRequired, setErrorRequired)
                          closeValidateSintaxError(setValidationErr)
                        }}
                      />
                    </FormGroup>
                    {errorRequired?.email && (
                      <span className='text-danger'>{errorRequired?.email}</span>
                    )}
                    {validationErr?.email === false && !errorRequired?.email ? (
                      <span className='text-danger'>
                        he provided email address format is not valid
                      </span>
                    ) : (
                      <></>
                    )}
                  </Col>
                  <Col xs={6} md={12}>
                    <FormGroup>
                      <label className='text-dark mt-5' htmlFor='exampleInputname'>
                        Full Name
                      </label>
                      <input
                        defaultValue={userData?.fullName}
                        name='fullName'
                        type='text'
                        className='form-control'
                        placeholder=' Full Name'
                        onClick={() => {
                          closeValidateRequiredError('fullName', errorRequired, setErrorRequired)
                        }}
                      />
                    </FormGroup>
                    {errorRequired?.fullName && (
                      <span className='text-danger'>{errorRequired?.fullName}</span>
                    )}
                  </Col>
                  {userData?.roleId === Number(USER_ROLE.Student) && id && (
                    <Col xs={6} md={12}>
                      <FormGroup>
                        <label className='text-dark mt-5' htmlFor='exampleInputname'>
                          Trust Name
                        </label>
                        <input
                          disabled
                          value={userData?.trustNames}
                          name='trustNames'
                          type='text'
                          className='form-control'
                          placeholder=' Trust Name'
                        />
                      </FormGroup>
                    </Col>
                  )}
                  <Col xs={6} md={5}>
                    <label className='text-dark mt-5' htmlFor='exampleInputname'>
                      Packages
                    </label>
                    <SingleSelect
                      data={packages}
                      editData={packagesSelected}
                      setData={setPackagesSelected}
                    />
                  </Col>
                  <Col xs={6} md={5}>
                    <label className='text-dark mt-5' htmlFor='exampleInputname'>
                      Gender
                    </label>
                    <SingleSelect
                      data={GENDER}
                      editData={genderSelected}
                      setData={setGendersSelected}
                    />
                  </Col>
                  {isPackagesRequired && <span className='text-danger'>packages is required</span>}
                  <Col lg={12} md={6}>
                    <FormGroup>
                      <label className='text-dark mt-5' htmlFor='exampleInputname'>
                        Birthday
                      </label>
                      <br />
                      <DateTimePicker
                        className='text-dark'
                        amPmAriaLabel='AM / PM'
                        format='y-MM-dd'
                        value={startValue && startValue}
                        onChange={value => setStartValue(value)}
                      />
                    </FormGroup>
                  </Col>
                  <Col xs={6} md={12}>
                    <FormGroup>
                      <label className='text-dark mt-5' htmlFor='exampleInputname'>
                        Role
                      </label>
                      <select
                        value={selectRoleId}
                        className='form-control text-dark'
                        name='roleId'
                        onChange={e => setSelectRoleId(Number(e?.target?.value))}
                      >
                        {roles?.length > 0 &&
                          roles?.map(role => (
                            <option key={role.id} value={role.id}>
                              {role.name}
                            </option>
                          ))}
                      </select>
                    </FormGroup>
                  </Col>
                  {selectRoleId === Number(USER_ROLE.Teacher) ? (
                    <>
                      <Col xs={6} md={12}>
                        <FormGroup>
                          <label className='text-dark mt-5' htmlFor='exampleInputname'>
                            Position
                          </label>
                          <input
                            defaultValue={userData?.position}
                            name='position'
                            type='text'
                            className='form-control'
                            placeholder='Position'
                            onClick={() => {
                              closeValidateRequiredError(
                                'position',
                                errorRequired,
                                setErrorRequired
                              )
                            }}
                          />
                        </FormGroup>
                      </Col>
                      {errorRequired?.position && (
                        <span className='text-danger'>{errorRequired.position}</span>
                      )}
                    </>
                  ) : null}
                  {selectRoleId === Number(USER_ROLE.Student) ||
                  selectRoleId === Number(USER_ROLE.Teacher) ? (
                    <Col xs={6} md={12}>
                      <label className='text-dark mt-5' htmlFor='exampleInputname'>
                        School
                      </label>
                      <Select
                        options={schoolData}
                        name='singleSelect'
                        value={schoolSelected}
                        styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                        menuPortalTarget={document.body}
                        onChange={newValue => {
                          setSchoolSelected(newValue)
                          setClasses([])
                          setClassSelect(null)
                          setSectionSelect(null)
                          setSection([])
                          setIsRequiredSchool(false)
                        }}
                        placeholder='Select...'
                      />
                      {isRequiredSchool && <span className='text-danger'>school is required</span>}
                    </Col>
                  ) : null}

                  {selectRoleId === Number(USER_ROLE.Student) && schoolSelected?.value ? (
                    <>
                      <Col xs={6} md={12}>
                        <FormGroup>
                          <label className='text-dark mt-5' htmlFor='exampleInputName'>
                            Class
                          </label>
                          <Select
                            options={classes}
                            name='singleSelect'
                            value={classSelect}
                            styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                            menuPortalTarget={document.body}
                            onChange={newValue => {
                              setClassSelect(newValue)
                              setSectionSelect(null)
                              setSection([])
                            }}
                            placeholder='Select...'
                          />
                        </FormGroup>
                        {isClassRequired ? (
                          <span className='text-danger'>class is required</span>
                        ) : null}
                      </Col>
                      {sectionGradesInput?.classId ? (
                        <Col xs={6} md={12}>
                          <FormGroup>
                            <label className='text-dark mt-5' htmlFor='exampleInputname'>
                              Section
                            </label>
                            <SingleSelect
                              data={section}
                              editData={sectionSelect}
                              setData={setSectionSelect}
                            />
                          </FormGroup>
                        </Col>
                      ) : null}
                    </>
                  ) : null}
                  {selectRoleId === Number(USER_ROLE.Student) && (
                    <Col xs={6} md={12}>
                      <FormGroup>
                        <label className='text-dark mt-5' htmlFor='exampleInputname'>
                          School Number
                        </label>
                        <input
                          defaultValue={userData?.schoolInnerId}
                          name='schoolNumber'
                          type='text'
                          className='form-control'
                          placeholder='School Number'
                          onClick={() => {
                            closeValidateRequiredError(
                              'schoolNumber',
                              errorRequired,
                              setErrorRequired
                            )
                          }}
                        />
                      </FormGroup>
                      {errorRequired?.schoolNumber && (
                        <span className='text-danger'>{errorRequired?.schoolNumber}</span>
                      )}
                    </Col>
                  )}
                  <Col xs={6} md={12}>
                    <label className='text-dark mt-5' htmlFor='forTeacher'>
                      Is Active
                    </label>
                    <select
                      value={toggleIsActive() ? 'true' : 'false'}
                      className='form-control text-dark'
                      name='isActive'
                      onChange={() => {
                        setUserData(prev => {
                          return {
                            ...prev,
                            isActive: !userData?.isActive
                          }
                        })
                      }}
                    >
                      <option value={'true'}>true</option>
                      <option value={'false'}>false</option>
                    </select>
                  </Col>
                  {!userData?.googleId && (
                    <>
                      <Col xs={6} md={12}>
                        <FormGroup>
                          <label className='text-dark mt-5' htmlFor='exampleInputname'>
                            Password
                          </label>
                          <input
                            defaultValue={userData?.password}
                            name='password'
                            type='password'
                            className='form-control'
                            placeholder=' Password'
                            onClick={() => {
                              closeValidateRequiredError(
                                'password',
                                errorRequired,
                                setErrorRequired
                              )
                            }}
                          />
                        </FormGroup>
                        {errorRequired?.password && (
                          <span className='text-danger'>{errorRequired?.password}</span>
                        )}
                      </Col>
                      <Col xs={6} md={12}>
                        <FormGroup>
                          <label className='text-dark mt-5' htmlFor='exampleInputname'>
                            Confirm Password
                          </label>
                          <input
                            defaultValue={userData?.confirmPassword}
                            name='confirmPassword'
                            type='password'
                            className='form-control'
                            placeholder='Confirm Password'
                            onClick={() => {
                              closeValidateRequiredError(
                                'confirmPassword',
                                errorRequired,
                                setErrorRequired
                              )
                            }}
                          />
                        </FormGroup>
                        {errorRequired?.confirmPassword && (
                          <span className='text-danger'>{errorRequired?.confirmPassword}</span>
                        )}
                      </Col>
                    </>
                  )}
                </Row>
              </Card.Body>
              <Card.Footer className='text-end'>
                <button type='submit' className='btn btn-success mt-1 me-2'>
                  Save
                </button>
                <Button
                  type='button'
                  className='btn btn-danger mt-1 me-2'
                  onClick={() => navigate(-1)}
                >
                  Cancel
                </Button>
              </Card.Footer>
            </form>
          </Card>
        </Col>
      </Row>
    </div>
  )
}

export default UsersCreate
