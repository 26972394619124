import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { getInCookies, setInCookies, setInCookiesbutCustmTime } from '../../utils/localStorageFunc'
import { IUserData } from '../api/users/model'
import { ISignInRes } from '../api/admin/model'

interface IAdmin {
  admin: IUserData
  accessToken: string
  refreshToken: string
}

const initialState: IAdmin = {
  admin: JSON.parse(getInCookies('admin') ?? '{}'),
  accessToken: getInCookies('access_token') ?? '',
  refreshToken: getInCookies('refresh-token') ?? ''
}

export const adminSlice = createSlice({
  name: 'admin',
  initialState,
  reducers: {
    addAdminInfo(state, action: PayloadAction<ISignInRes>) {
      state.admin = action.payload.userInfo
      setInCookies('admin', JSON.stringify(state.admin))
      state.accessToken = action.payload.access_token
      setInCookies('access_token', state.accessToken)
      state.refreshToken = action.payload.refresh_token
      setInCookiesbutCustmTime('refresh_token', state.refreshToken)
    }
  }
})

export const adminActions = adminSlice.actions
export const adminReducer = adminSlice.reducer
