import { Card, Col, FormGroup, Row } from 'react-bootstrap'
import { useEffect, useState } from 'react'
import { ICreateSurveyData } from '../../../store/api/survey/model'
import {
  useCreateSurveyMutation,
  useGetSurveyByIdQuery,
  useUpdateSurveyMutation
} from '../../../store/api/survey/survey.api'
import { toast } from 'react-toastify'
import { useNavigate, useParams } from 'react-router-dom'
import CreateQuestions from './CreateQuestion/CreateQuestion'
import { DeepDiveMetricsType } from '../../../utils/constants'

const CreateSurvey = (): JSX.Element => {
  const navigate = useNavigate()
  const { id: surveyId } = useParams()
  const { data: surveyById, refetch } = useGetSurveyByIdQuery(surveyId, {
    skip: !surveyId
  })
  const [postCreateSurvey] = useCreateSurveyMutation()
  const [putUpdateSurvey] = useUpdateSurveyMutation()
  const [surveyData, setSurveyData] = useState<ICreateSurveyData>({
    metric: Object.keys(DeepDiveMetricsType)[0]
  } as ICreateSurveyData)

  const changeData = (field: string, value: string): void => {
    setSurveyData({ ...surveyData, [field]: value })
  }

  const createSurvey = async (data: ICreateSurveyData): Promise<void> => {
    try {
      let res
      const { title, ...remainingData } = data
      if (!title?.length || title.trim() === '') {
        toast.error('Please create correct title')
        return
      } else if (surveyId) {
        res = await putUpdateSurvey({ title: title.trim(), ...remainingData })
      } else {
        res = await postCreateSurvey({ title: title.trim(), ...remainingData })
        setSurveyData({ ...surveyData, id: res.data.id })
      }
      toast.success(res.data.message)
    } catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    if (surveyById) {
      setSurveyData({
        id: surveyById.id,
        title: surveyById.title,
        metric: surveyById.metric
      })
    }
  }, [surveyById])

  useEffect(() => {
    if (surveyId) {
      refetch()
    }
  }, [surveyId])

  return (
    <div>
      <Card.Header>
        <div>
          <h2>{surveyId ? 'Edit' : 'Create'} Survey</h2>
        </div>
      </Card.Header>
      <Row>
        <Col lg={12} xl={12} md={12} sm={12}>
          <Card>
            <div>
              <Card.Body>
                <Row>
                  <Col xs={6} md={12} className='mb-5'>
                    <FormGroup>
                      <label className='text-dark mt-5' htmlFor='surveyTitle'>
                        Title
                      </label>
                      <input
                        value={surveyData.title || ''}
                        id='surveyTitle'
                        name='title'
                        type='text'
                        className='form-control'
                        placeholder='Survey Title'
                        onChange={e => changeData(e.target.name, e.target.value)}
                      />
                    </FormGroup>
                  </Col>
                  <Col xs={6} md={2}>
                    <FormGroup>
                      <label className='text-dark' htmlFor='exampleInputname'>
                        Metric
                      </label>
                      <select
                        value={surveyData.metric}
                        className='form-select'
                        onChange={({ target }) =>
                          setSurveyData(prev => ({ ...prev, metric: target.value }))
                        }
                      >
                        {Object.entries(DeepDiveMetricsType).map(([key, value]) => (
                          <option value={key}>{value}</option>
                        ))}
                      </select>
                    </FormGroup>
                  </Col>
                </Row>
                <Col className='text-end'>
                  <button
                    className='btn btn-success mt-1 me-2'
                    onClick={() => createSurvey(surveyData)}
                  >
                    Save
                  </button>
                  <button
                    type='button'
                    className='btn btn-danger mt-1 me-2'
                    onClick={() => navigate('/surveys')}
                  >
                    Cancel
                  </button>
                </Col>
              </Card.Body>
              {surveyData?.id ? (
                <Card.Body className='text-end'>
                  <CreateQuestions surveyId={surveyData.id} surveyData={surveyById} />
                </Card.Body>
              ) : null}
            </div>
          </Card>
        </Col>
      </Row>
    </div>
  )
}
export default CreateSurvey
