import { createApi } from '@reduxjs/toolkit/query/react'
import { baseQueryWithReauth } from '../apiSlice'
import {
  ICreateSurveyData,
  ICreateSurveyRes,
  IStatusUpdateData,
  ISurvey,
  ISurveyById,
  ISurveyQuestion
} from './model'
import { ISearchParams } from '../../../ui/filter/model'

export const surveyApi = createApi({
  reducerPath: 'survey/api',
  baseQuery: baseQueryWithReauth,
  endpoints: build => ({
    getSurvey: build.query<ISurvey, ISearchParams>({
      query: (params: ISearchParams) => ({
        url: '/admin/survey',
        params: { ...params }
      })
    }),
    getSurveyById: build.query<ISurveyById, string>({
      query: (id: string) => ({ url: `/admin/survey/${id}` })
    }),
    createSurvey: build.mutation<ICreateSurveyRes, ICreateSurveyData>({
      query: (data: ICreateSurveyData) => ({
        url: '/admin/survey',
        method: 'POST',
        body: data
      })
    }),
    updateSurvey: build.mutation<ICreateSurveyRes, ICreateSurveyData>({
      query: (data: ICreateSurveyData) => ({
        url: '/admin/survey',
        method: 'PUT',
        body: data
      })
    }),
    updateStatus: build.mutation<void, IStatusUpdateData>({
      query: (data: IStatusUpdateData) => ({
        url: '/admin/survey/status',
        method: 'PUT',
        body: data
      })
    }),
    deleteSurvey: build.mutation<void, string>({
      query: (id: string) => ({
        url: `/admin/survey/${id}`,
        method: 'DELETE'
      })
    }),
    updateSurveyQuestion: build.mutation<void, ISurveyQuestion[]>({
      query: (data: ISurveyQuestion[]) => ({
        url: '/admin/survey/question',
        method: 'PUT',
        body: data
      })
    }),
    deleteSurveyQuestion: build.mutation<void, number>({
      query: (id: number) => ({
        url: `/admin/survey/question/${id}`,
        method: 'DELETE'
      })
    })
  })
})

export const {
  useGetSurveyQuery,
  useGetSurveyByIdQuery,
  useCreateSurveyMutation,
  useUpdateSurveyMutation,
  useUpdateStatusMutation,
  useDeleteSurveyMutation,
  useUpdateSurveyQuestionMutation,
  useDeleteSurveyQuestionMutation
} = surveyApi
