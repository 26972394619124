import 'bootstrap/dist/css/bootstrap.min.css'
import { FC } from 'react'
import Select from 'react-select'
import makeAnimated from 'react-select/animated'
import { IMultiSelect } from './model'

const animatedComponents = makeAnimated()

const MultiSelect: FC<IMultiSelect> = ({
  setData,
  data,
  editData,
  deleteErrormessage,
  field,
  setPageByScroll,
  dataCount
}): JSX.Element => {
  const handleSelectChange = (selectedOptions): void => {
    setData(selectedOptions)
    deleteErrormessage?.(prev => ({ ...prev, [field]: '' }))
  }

  return (
    <Select
      options={data}
      components={animatedComponents}
      isMulti
      onMenuScrollToBottom={() =>
        setPageByScroll?.(prev => {
          const result = Math.ceil(dataCount / 30)
          if (result >= prev) {
            return prev + 1
          }
          return prev
        })
      }
      onChange={handleSelectChange}
      value={editData}
      styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
      menuPortalTarget={document.body}
    />
  )
}

export default MultiSelect
