import { createApi } from '@reduxjs/toolkit/query/react'
import { ISearchParams } from '../../../ui/filter/model'
import { baseQueryWithReauth } from '../apiSlice'
import { ICreateClass, IGetClasses } from './model'
export const classApi = createApi({
  reducerPath: 'class/api',
  baseQuery: baseQueryWithReauth,
  endpoints: build => ({
    getClass: build.query<IGetClasses, ISearchParams | void>({
      query: params => ({
        url: 'admin/class',
        params: { ...params }
      })
    }),

    getClassById: build.query<void, string>({
      query: (id: string) => `/admin/class/${id}`
    }),
    createClass: build.mutation<string, ICreateClass>({
      query: classData => ({
        url: 'admin/class',
        method: 'POST',
        body: classData
      })
    }),
    updateClass: build.mutation<string, ICreateClass>({
      query: classData => ({
        url: 'admin/class',
        method: 'PUT',
        body: classData
      })
    }),
    deleteClass: build.mutation<void, string>({
      query: (id: string) => ({
        url: `/admin/class/${id}`,
        method: 'DELETE'
      })
    })
  })
})
export const {
  useGetClassQuery,
  useGetClassByIdQuery,
  useCreateClassMutation,
  useUpdateClassMutation,
  useDeleteClassMutation
} = classApi
