import { FC, useState } from 'react'
import { stringToDate } from '../../../utils/date'
import { Button, ButtonGroup, OverlayTrigger, Tooltip } from 'react-bootstrap'
import { useNavigate } from 'react-router-dom'
import { useDeleteLessonPlansMutation } from '../../../store/api/lessonplans/lessonplans.api'
import { ILessonPlansTable } from '../../../store/api/lessonplans/model'
import { lessMore } from '../../../ui/lessMore/lessMore'
import { isSelectedDescription } from '../../../utils/IsSelectedDescription'
import { UseToggleModalHOC } from '../../../hooks/toggleModal'
import RemoveItemModal from '../../../utils/RemoveItemModal/removeItemModal'

const LessonTable: FC<ILessonPlansTable> = ({ lessonPlans, refetch }): JSX.Element => {
  const navigate = useNavigate()
  const [deleteLesson] = useDeleteLessonPlansMutation()
  const [selectedDescription, setSelectedDescription] = useState<string[]>([])
  const { isModalVisible, toggleModal } = UseToggleModalHOC()

  const renderMoreLessButton = (text: string): JSX.Element => {
    const isSelected = isSelectedDescription(text, selectedDescription)
    return (
      <button
        className='text-center text-info'
        onClick={() => lessMore(text, selectedDescription, setSelectedDescription)}
      >
        {isSelected ? 'less' : 'more'}
      </button>
    )
  }
  return (
    <tr key={lessonPlans.id} className='text-center align-middle'>
      <td>{lessonPlans.id}</td>
      <td style={{ width: '90px' }}>
        <div
          style={{
            whiteSpace: isSelectedDescription(lessonPlans.name, selectedDescription)
              ? 'normal'
              : 'nowrap',
            maxWidth: '90px'
          }}
          className={
            isSelectedDescription(lessonPlans.name, selectedDescription)
              ? 'text-center'
              : 'd-inline-block text-truncate text-center'
          }
        >
          {lessonPlans.name}
        </div>
        {lessonPlans.name?.length > 14 && renderMoreLessButton(lessonPlans.name)}
      </td>
      <td style={{ width: '150px' }}>
        <div
          style={{
            whiteSpace: isSelectedDescription(lessonPlans.overview, selectedDescription)
              ? 'normal'
              : 'nowrap',
            maxWidth: '150px'
          }}
          className={
            isSelectedDescription(lessonPlans.overview, selectedDescription)
              ? 'text-center'
              : 'd-inline-block text-truncate text-center'
          }
        >
          {lessonPlans.overview}
        </div>
        {lessonPlans.overview?.length > 14 && renderMoreLessButton(lessonPlans.overview)}
      </td>
      <td style={{ width: '150px' }}>
        <div
          style={{
            whiteSpace: isSelectedDescription(lessonPlans.badge, selectedDescription)
              ? 'normal'
              : 'nowrap',
            maxWidth: '150px'
          }}
          className={
            isSelectedDescription(lessonPlans.badge, selectedDescription)
              ? 'text-center'
              : 'd-inline-block text-truncate text-center'
          }
        >
          {lessonPlans.badge && lessonPlans.badge}
        </div>
        {lessonPlans.badge?.length > 14 && renderMoreLessButton(lessonPlans.badge)}
      </td>
      <td style={{ width: '150px' }}>
        <div
          style={{
            whiteSpace: isSelectedDescription(lessonPlans.takeaways, selectedDescription)
              ? 'normal'
              : 'nowrap',
            maxWidth: '150px'
          }}
          className={
            isSelectedDescription(lessonPlans.takeaways, selectedDescription)
              ? 'text-center'
              : 'd-inline-block text-truncate text-center'
          }
        >
          {lessonPlans.takeaways && lessonPlans.takeaways}
        </div>
        {lessonPlans.takeaways?.length > 14 && renderMoreLessButton(lessonPlans.takeaways)}
      </td>
      <td className='align-middle'>{stringToDate(lessonPlans.outcomes)}</td>
      <td className='align-middle'>{stringToDate(lessonPlans.created_at)}</td>
      <td className='align-middle'>
        <span className='text-center align-middle'>
          <ButtonGroup size='sm' className='flex-nowrap'>
            <OverlayTrigger placement='top' overlay={<Tooltip>Edit</Tooltip>}>
              <Button onClick={() => navigate(`/lessons-plans/edit/${lessonPlans.id}`)}>
                Edit
              </Button>
            </OverlayTrigger>
            <OverlayTrigger placement='top' overlay={<Tooltip>View</Tooltip>}>
              <Button onClick={() => toggleModal()}>Delete</Button>
            </OverlayTrigger>
          </ButtonGroup>
        </span>
      </td>
      {isModalVisible && (
        <RemoveItemModal
          id={lessonPlans.id}
          deleteItem={deleteLesson}
          reload={refetch}
          onClose={() => toggleModal()}
        />
      )}
    </tr>
  )
}
export default LessonTable
