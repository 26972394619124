import React, { FC, memo, useEffect, useState } from 'react'
import 'bootstrap/dist/css/bootstrap.min.css'
import CreateChoice from './CreateChoice/CreateChoice'
import { ISurveyQuestion } from '../../../../store/api/survey/model'
import {
  useDeleteSurveyQuestionMutation,
  useUpdateSurveyQuestionMutation
} from '../../../../store/api/survey/survey.api'
import { useNavigate } from 'react-router-dom'
import { ICreateQuestionProps } from '../../model'

export const initialChoice = {
  text: '',
  isCorrect: false,
  score: 1
}

const initialQuestion = {
  text: '',
  type: 2,
  surveyId: 0,
  choices: []
}
const initialLikertScale = [
  { text: 'Strongly Disagree', score: 1, isCorrect: false },
  { text: 'Disagree', score: 2, isCorrect: false },
  { text: 'Neutral', score: 3, isCorrect: false },
  { text: 'Agree', score: 4, isCorrect: false },
  { text: 'Strongly Agree', score: 5, isCorrect: false }
]

const CreateQuestions: FC<ICreateQuestionProps> = ({ surveyId, surveyData }): JSX.Element => {
  const [putUpdateQuestion] = useUpdateSurveyQuestionMutation()
  const [deleteQuestionReq] = useDeleteSurveyQuestionMutation()
  const navigate = useNavigate()
  const [questions, setQuestions] = useState<ISurveyQuestion[]>([{ ...initialQuestion, surveyId }])
  const handleQuestionChange = (qIndex: number, field: string, value: number | string): void => {
    setQuestions?.(prevQuestions =>
      prevQuestions.map((question, index) => {
        if (qIndex !== index) {
          return question
        }
        const updatedQuestion = { ...question, [field]: value }

        if (typeof value === 'number' && value === 1) {
          updatedQuestion.choices = [initialChoice]
        } else if (typeof value === 'number' && value === 2) {
          updatedQuestion.choices = []
        }
        if (typeof value === 'number' && value === 3) {
          updatedQuestion.choices = [...initialLikertScale]
        }
        return updatedQuestion
      })
    )
  }

  const handleAddQuestion = (): void => {
    setQuestions([...questions, { ...initialQuestion, surveyId, text: '' }])
  }

  const handleDeleteQuestion = async (deletedItemIndex: number): Promise<void> => {
    try {
      if (questions[deletedItemIndex]?.id) {
        await deleteQuestionReq(questions[deletedItemIndex]?.id)
      }
      setQuestions?.(prev => [...prev.filter((_, index) => index !== deletedItemIndex)])
    } catch (error) {
      console.log(error)
    }
  }

  const createQuestionReq = async (data: ISurveyQuestion[]): Promise<void> => {
    const newData = data.map(item => ({
      ...item,
      text: item.text.trim(),
      choices: item.choices.map(choice => ({
        ...choice,
        text: choice.text.trim()
      }))
    }))
    try {
      await putUpdateQuestion(newData)
      navigate('/surveys')
    } catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    if (surveyData?.questions?.length) {
      const questionEditData = surveyData.questions.map(question => ({
        id: question.id,
        text: question.text,
        type: question.type,
        surveyId: question.surveyId,
        choices: question.choices?.map(choice => ({
          text: choice.text,
          isCorrect: choice.isCorrect,
          score: choice.score
        }))
      }))
      setQuestions(questionEditData)
    }
  }, [surveyData?.questions])

  const sortedChoice = (questionIndex: number, isSortedChoice: boolean): void => {
    setQuestions(prev => {
      const newQuestion = prev[questionIndex]
      newQuestion.choices.sort((a, b) => {
        if (isSortedChoice) {
          return a.score - b.score
        }
        return b.score - a.score
      })
      prev[questionIndex] = newQuestion
      return [...prev]
    })
  }
  return (
    <div className='container my-4'>
      <div className='mb-4'>
        <button className='btn btn-warning' onClick={handleAddQuestion}>
          Add Question{' '}
        </button>
      </div>
      {questions?.map((question, index) => (
        <div key={index}>
          <div>
            <div>
              <span className='h5'>Question {index + 1}</span>
              <div className='d-flex align-items-center mb-3'>
                <input
                  type='text'
                  value={question.text || ''}
                  placeholder='eg How Are You'
                  className='form-control me-2'
                  onChange={e => handleQuestionChange(index, 'text', e.target.value)}
                />
                {index ? (
                  <svg
                    fill='rgb(17, 17, 17)'
                    height='24'
                    viewBox='0 0 24 24'
                    onClick={() => handleDeleteQuestion(index)}
                    style={{ cursor: 'pointer' }}
                  >
                    <path d='M0 0h24v24H0V0z' fill='none'></path>
                    <path d='M6 19c0 1.1.9 2 2 2h8c1.1 0 2-.9 2-2V7H6v12zM8 9h8v10H8V9zm7.5-5-1-1h-5l-1 1H5v2h14V4z'></path>
                  </svg>
                ) : null}
              </div>
              <div className='d-flex justify-content-between flex-column flex-md-row'>
                <div className='d-flex flex-column gap-2 flex-1'>
                  <div className='d-flex align-items-center'>
                    <input
                      type='checkbox'
                      checked={question.type === 1}
                      onChange={() => handleQuestionChange(index, 'type', 1)}
                    />
                    <label
                      className='ms-2'
                      style={{ opacity: question.type === 2 || question.type === 3 ? 0.5 : 1 }}
                    >
                      checkbox
                    </label>
                  </div>
                  <div className='d-flex align-items-center'>
                    <input
                      type='checkbox'
                      checked={question.type === 2}
                      onChange={() => handleQuestionChange(index, 'type', 2)}
                    />
                    <label
                      className='ms-2'
                      style={{ opacity: question.type === 1 || question.type === 3 ? 0.5 : 1 }}
                    >
                      Open Ended Response
                    </label>
                  </div>
                  <div className='d-flex align-items-center'>
                    <input
                      type='checkbox'
                      checked={question.type === 3}
                      onChange={() => handleQuestionChange(index, 'type', 3)}
                    />
                    <label
                      className='ms-2'
                      style={{ opacity: question.type === 1 || question.type === 2 ? 0.5 : 1 }}
                    >
                      Likert Scale
                    </label>
                  </div>
                </div>
                <div className='d-flex flex-column gap-2 flex-1'>
                  {(question.type === 1 || question.type === 3) && question?.choices?.length ? (
                    <>
                      <select
                        className='form-select w-25 mx-4'
                        value={Number(
                          question.choices.every(
                            (e, i, arr) => i === 0 || arr[i - 1].score <= e.score
                          )
                        )}
                        onChange={e => sortedChoice(index, Number(e.target.value) === 1)}
                      >
                        <option value={1}>ASC</option>
                        <option value={0}>DESC</option>
                      </select>
                      <>
                        {question.choices
                          .sort((a, b) => {
                            if (
                              question.choices.every(
                                (e, i, arr) => i === 0 || arr[i - 1].score <= e.score
                              )
                            ) {
                              return a.score - b.score
                            }
                            return b.score - a.score
                          })
                          .map((choice, i) => (
                            <CreateChoice
                              key={i}
                              choice={choice}
                              choiceIndex={i}
                              questionIndex={index}
                              choicesLength={question.choices?.length}
                              setQuestions={setQuestions}
                            />
                          ))}
                      </>
                    </>
                  ) : null}
                </div>
              </div>
            </div>
          </div>
        </div>
      ))}
      <button className='btn btn-success mt-1 me-2' onClick={() => createQuestionReq(questions)}>
        Save Question
      </button>
    </div>
  )
}

export default memo(CreateQuestions)
