import { FC } from 'react'
import { useNavigate } from 'react-router-dom'
import { Button, ButtonGroup, OverlayTrigger, Tooltip } from 'react-bootstrap'
import { IUserTableProps } from '../../../store/api/users/model'
import { stringToDate } from '../../../utils/date'
import { UseToggleModalHOC } from '../../../hooks/toggleModal'
import { useDeleteUserMutation } from '../../../store/api/users/users.api'
import RemoveItemModal from '../../../utils/RemoveItemModal/removeItemModal'
import { ACTIVATE_TYPE, USER_ROLE } from '../../../utils/constants'
import UpdateActivate from '../../../ui/UpdateActivate/UpdateActivate'

const UsersTable: FC<IUserTableProps> = ({ user, refetch }): JSX.Element => {
  const navigate = useNavigate()
  const [deleteUser] = useDeleteUserMutation()
  const { isModalVisible, toggleModal } = UseToggleModalHOC()

  return (
    <tr key={user.id} className='text-center align-middle'>
      <td className='align-middle'>{user.id}</td>
      <td>{user.fullName}</td>
      <td className='align-middle'>{user.email}</td>
      <td>
        <span style={{ color: user.isActive ? 'green' : 'red' }}>
          {user.isActive ? 'active' : 'draft'}
        </span>
      </td>
      <td>{user?.package?.name}</td>
      <td className='align-middle'>{user.isEmailVerified ? 'verified' : 'not verified'}</td>
      <td className='align-middle'>{stringToDate(user.created_at)}</td>
      <td className='d-flex aline-items-center justify-content-between '>
        <span className='w-15'></span>
        <ButtonGroup size='sm' className='flex-nowrap p-1'>
          <OverlayTrigger placement='top' overlay={<Tooltip>Edit</Tooltip>}>
            <Button onClick={() => navigate(`/users/edit/${user.id}`)}>Edit</Button>
          </OverlayTrigger>
          <OverlayTrigger placement='top' overlay={<Tooltip>View</Tooltip>}>
            <Button onClick={() => toggleModal()}>Delete</Button>
          </OverlayTrigger>
        </ButtonGroup>
        <div className='w-15 d-flex aline-items-center justify-content-between'>
          {user.roleId === Number(USER_ROLE.Student) ? (
            <UpdateActivate
              checked={user.isEmailVerified}
              id={user.id}
              type={ACTIVATE_TYPE.User}
              refetch={refetch}
            />
          ) : null}
        </div>
      </td>
      {isModalVisible && (
        <RemoveItemModal
          id={user.id}
          deleteItem={deleteUser}
          reload={refetch}
          onClose={() => toggleModal()}
        />
      )}
    </tr>
  )
}

export default UsersTable
