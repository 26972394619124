import { useState } from 'react'
import { IGetMood } from '../../store/api/mood/model'

export const useModal = (): { isShowing: boolean; toggle: () => void } => {
  const [isShowing, setIsShowing] = useState(false)

  const toggle = (): void => {
    setIsShowing(!isShowing)
  }

  return {
    isShowing,
    toggle
  }
}

export const modalConditionOpen = (
  setOpenCreateModal: React.Dispatch<React.SetStateAction<boolean>>,
  setEdit: React.Dispatch<React.SetStateAction<IGetMood>>,
  type?: string,
  data?: IGetMood
): void => {
  if (type === 'edit') {
    setEdit(data)
    setOpenCreateModal(true)
  } else {
    setEdit(null)
    setOpenCreateModal(true)
  }
}
