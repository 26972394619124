import { Route, Routes } from 'react-router-dom'
import * as React from 'react'
import Class from '../components/Class/Class'
import App from '../components/App'

export const ClassRoutes = (): React.ReactElement => {
  return (
    <Routes>
      <Route path={'/class'} element={<App />}>
        <Route path='' element={<Class />} />
      </Route>
    </Routes>
  )
}
