import React, { FC } from 'react'
import classes from './Modal.module.css'
import { TModalProps } from './model'
import ReactDOM from 'react-dom'

const Modal: FC<TModalProps> = ({ children }): JSX.Element => {
  return ReactDOM.createPortal(
    <div>
      <div className={classes.darkBG}>
        <div className={classes.centered}>
          <div className={classes.modal}>{children}</div>
        </div>
      </div>
    </div>,
    document.body
  )
}

export default Modal
