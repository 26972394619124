import {
  BaseQueryFn,
  FetchArgs,
  FetchBaseQueryError,
  FetchBaseQueryMeta,
  fetchBaseQuery
} from '@reduxjs/toolkit/dist/query'
import axios from 'axios'
import Cookies from 'js-cookie'
import { BASE_URL } from '../../utils/constants'
import { getInCookies } from '../../utils/localStorageFunc'

export const getBaseQuery = (): BaseQueryFn<
  string | FetchArgs,
  unknown,
  FetchBaseQueryError,
  object,
  FetchBaseQueryMeta
> => {
  return fetchBaseQuery({
    baseUrl: BASE_URL,
    headers: {
      Authorization: `Bearer ${getInCookies('access_token')}`
    }
  })
}
export const baseQueryWithReauth: BaseQueryFn<
  string | FetchArgs,
  unknown,
  FetchBaseQueryError
> = async (args, api, extraOptions) => {
  let result = await getBaseQuery()(args, api, extraOptions)

  if (result.error && result.error.status === 401 && (getInCookies('refresh_token') as string)) {
    const refreshResult = await axios
      .post(`${BASE_URL}auth/refresh-token`, {
        refreshToken: getInCookies('refresh_token') as string
      })
      .then(res => res.data.data)
      .catch(() => window.location.assign('/auth/signin'))
    if (refreshResult.access_token) {
      Cookies.set('access_token', refreshResult.access_token)
      result = await getBaseQuery()(args, api, extraOptions)
      if (result.error) {
        window.location.assign('/auth/signin')
      }
    }
  } else if (!getInCookies('refresh_token')) {
    window.location.assign('/auth/signin')
  }
  return result
}
