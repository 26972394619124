import ReactPaginate from 'react-paginate'
import React, { FC } from 'react'
import './Pagination.css'
import { TPaginationProps } from './model'
const Pagination: FC<TPaginationProps> = ({ setQuery, numPages, query }): JSX.Element => {
  return (
    <div>
      <ReactPaginate
        className='ReactPaginate'
        pageClassName='item'
        previousClassName='item'
        nextClassName='item'
        breakLabel='...'
        breakClassName='item'
        marginPagesDisplayed={2}
        pageRangeDisplayed={5}
        onPageChange={e => {
          // setQuery(prev => {
          //   return {
          //     ...prev,
          //     page: e.selected + 1
          //   }
          // })
          query.set('page', String(e.selected + 1))
          setQuery(query)
        }}
        pageCount={numPages as number}
        activeClassName='active'
        disabledClassName='disabled'
      />
    </div>
  )
}
export default Pagination
