import { Button, Card, Col, Row, Table } from 'react-bootstrap'
import { useEffect } from 'react'
import { useGetGroupsQuery } from '../../store/api/group/group.api'
import { useNavigate } from 'react-router-dom'
import GroupsTable from './GroupsTable/GroupsTable'
import Loading from '../Loading/Loading'

const Groups = (): JSX.Element => {
  const navigation = useNavigate()
  const { data: groups, isLoading, refetch } = useGetGroupsQuery()
  useEffect(() => {
    refetch()
  }, [])

  return (
    <div className='mt-8'>
      {isLoading && <Loading />}
      <Row className=' row-sm'>
        <Col lg={12}>
          <Card className='custom-card'>
            <Card.Header>
              <Col xs={6} md={11}>
                <h3 className='card-title mb-1'>Groups</h3>
              </Col>
              <Col>
                <Button onClick={() => navigation('/groups/create')}>Create Group</Button>
              </Col>
            </Card.Header>
            <Card.Body>
              <div className='table-responsive'>
                <Table className='table table-bordered text-nowrap text-md-nowrap mg-b-0'>
                  <thead>
                    <tr className='text-center'>
                      <th>ID</th>
                      <th>Name</th>
                      <th>Description</th>
                      <th>create at</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {groups?.length > 0 ? (
                      groups?.map(group => {
                        return <GroupsTable key={group.id} group={group} refetch={refetch} />
                      })
                    ) : (
                      <></>
                    )}
                  </tbody>
                </Table>
              </div>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </div>
  )
}

export default Groups
