import { configureStore } from '@reduxjs/toolkit'
import { setupListeners } from '@reduxjs/toolkit/query'
import { characterApi } from './api/ character/character.api'
import { adminApi } from './api/admin/admin.api'
import { categoryApi } from './api/category/category.api'
import { classApi } from './api/class/class.api'
import { groupApi } from './api/group/group.api'
import { lessonApi } from './api/lesson/lesson.api'
import { meditationApi } from './api/meditation/meditation.api'
import { moodApi } from './api/mood/mood.api'
import { profileApi } from './api/profile/profile.api'
import { questionApi } from './api/question/question.api'
import { quizApi } from './api/quiz/quiz.api'
import { resourceApi } from './api/resource/resource.api'
import { schoolApi } from './api/school/school.api'
import { teacherApi } from './api/teacher/teacher.api'
import { usersApi } from './api/users/users.api'
import { welcomeApi } from './api/welcome/welcome.api'
import { adminReducer } from './slice/admin.slice'
import { packagesApi } from './api/packages/packages.api'
import { wellnessCheckinsApi } from './api/WellnessCheckins/WellnessCheckins.api'
import { dashboardAPi } from './api/deshboard/deshboard.api'
import { lessonPlansApi } from './api/lessonplans/lessonplans.api'
import { sectionsApi } from './api/section/section.api'
import { surveyApi } from './api/survey/survey.api'

export const store = configureStore({
  reducer: {
    [usersApi.reducerPath]: usersApi.reducer,
    [adminApi.reducerPath]: adminApi.reducer,
    [classApi.reducerPath]: classApi.reducer,
    [teacherApi.reducerPath]: teacherApi.reducer,
    [lessonApi.reducerPath]: lessonApi.reducer,
    [groupApi.reducerPath]: groupApi.reducer,
    [characterApi.reducerPath]: characterApi.reducer,
    [questionApi.reducerPath]: questionApi.reducer,
    [resourceApi.reducerPath]: resourceApi.reducer,
    [moodApi.reducerPath]: moodApi.reducer,
    [quizApi.reducerPath]: quizApi.reducer,
    [categoryApi.reducerPath]: categoryApi.reducer,
    [profileApi.reducerPath]: profileApi.reducer,
    [schoolApi.reducerPath]: schoolApi.reducer,
    [welcomeApi.reducerPath]: welcomeApi.reducer,
    [meditationApi.reducerPath]: meditationApi.reducer,
    [packagesApi.reducerPath]: packagesApi.reducer,
    [wellnessCheckinsApi.reducerPath]: wellnessCheckinsApi.reducer,
    [dashboardAPi.reducerPath]: dashboardAPi.reducer,
    [lessonPlansApi.reducerPath]: lessonPlansApi.reducer,
    [sectionsApi.reducerPath]: sectionsApi.reducer,
    [surveyApi.reducerPath]: surveyApi.reducer,
    admin: adminReducer
  },
  middleware: getDefaultMiddleware =>
    getDefaultMiddleware().concat(
      usersApi.middleware,
      adminApi.middleware,
      classApi.middleware,
      teacherApi.middleware,
      lessonApi.middleware,
      groupApi.middleware,
      characterApi.middleware,
      questionApi.middleware,
      resourceApi.middleware,
      moodApi.middleware,
      quizApi.middleware,
      categoryApi.middleware,
      profileApi.middleware,
      schoolApi.middleware,
      welcomeApi.middleware,
      meditationApi.middleware,
      packagesApi.middleware,
      wellnessCheckinsApi.middleware,
      dashboardAPi.middleware,
      lessonPlansApi.middleware,
      sectionsApi.middleware,
      surveyApi.middleware
    )
})

setupListeners(store.dispatch)

export type TRootState = ReturnType<typeof store.getState>
