import { createApi } from '@reduxjs/toolkit/query/react'
import { IGetWelcomeQuestion, TCreateWelcomeQuestion } from './model'
import { baseQueryWithReauth } from '../apiSlice'

export const welcomeApi = createApi({
  reducerPath: 'welcome/api',
  baseQuery: baseQueryWithReauth,
  endpoints: build => ({
    getWelcome: build.query<IGetWelcomeQuestion[], void>({
      query: () => ({ url: '/admin/welcome-questions' })
    }),

    getWelcomeById: build.query<IGetWelcomeQuestion, string>({
      query: (id: string) => ({ url: `/admin/welcome-questions/${id}` })
    }),

    createWelcome: build.mutation<void, TCreateWelcomeQuestion>({
      query: (data: TCreateWelcomeQuestion) => ({
        url: '/admin/welcome-question',
        method: 'POST',
        body: data
      })
    }),

    updateWelcome: build.mutation<void, TCreateWelcomeQuestion>({
      query: (data: TCreateWelcomeQuestion) => ({
        url: '/admin/welcome-questions',
        method: 'PUT',
        body: data
      })
    }),
    deleteWelcome: build.mutation<void, string>({
      query: (id: string) => ({ url: `/admin/welcome-questions/${id}`, method: 'DELETE' })
    })
  })
})

export const {
  useGetWelcomeQuery,
  useGetWelcomeByIdQuery,
  useCreateWelcomeMutation,
  useUpdateWelcomeMutation,
  useDeleteWelcomeMutation
} = welcomeApi
