import { useEffect } from 'react'
import { Button, Card, Col, Row, Table } from 'react-bootstrap'
import { useNavigate } from 'react-router-dom'
import { useGetMeditationQuery } from '../../store/api/meditation/meditation.api'
import Loading from '../Loading/Loading'
import MeditationTable from './MeditationTable/MeditationTable'

const Meditation = (): JSX.Element => {
  const { data: Meditation, isLoading, refetch } = useGetMeditationQuery()
  const navigate = useNavigate()

  useEffect(() => {
    refetch()
  }, [])

  return (
    <div>
      {isLoading && <Loading />}
      <Row className='row-sm'>
        <Col lg={12}>
          <Card className='custom-card'>
            <Card.Header>
              <Col xs={6} md={11}>
                <h3 className='card-title mb-1'>Meditation</h3>
              </Col>
              <Col className='p-2'>
                <Button onClick={() => navigate('/meditation/create')}>Create Meditation</Button>
              </Col>
            </Card.Header>
            <Card.Body>
              <div className='table-responsive'>
                <Table className='table table-bordered text-nowrap text-md-nowrap mg-b-0'>
                  <thead>
                    <tr className='text-center'>
                      <th>ID</th>
                      <th>Audio</th>
                      <th>Gif</th>
                      <th>Name</th>
                      <th>Source</th>
                      <th>Duration</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {Meditation?.length
                      ? Meditation?.map(mediation => {
                          return (
                            <MeditationTable
                              key={mediation.id}
                              Meditation={mediation}
                              refetch={refetch}
                            />
                          )
                        })
                      : null}
                  </tbody>
                </Table>
              </div>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </div>
  )
}

export default Meditation
