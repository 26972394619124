import { Route, Routes } from 'react-router-dom'
import * as React from 'react'
import SignIn from '../components/Auth/Login'

export const AuthRoutes = (): React.ReactElement => {
  return (
    <Routes>
      <Route path={'/auth'}>
        <Route path='signin' element={<SignIn />} />
      </Route>
    </Routes>
  )
}
