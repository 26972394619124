import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { ISignIn } from '../../../components/Auth/model'
import { BASE_URL } from '../../../utils/constants'
import { ISignInRes } from './model'

const baseQuery = fetchBaseQuery({
  baseUrl: BASE_URL
})
export const adminApi = createApi({
  reducerPath: 'admin/api',
  baseQuery,
  keepUnusedDataFor: 0,

  endpoints: build => ({
    adminLogin: build.mutation<ISignInRes, ISignIn>({
      query: (adminData: ISignIn) => ({
        url: '/admin/login',
        method: 'POST',
        body: adminData
      })
    }),
    refreshToken: build.mutation<{ access_token: string }, string>({
      query: (refreshToken: string) => ({
        url: '/auth/refresh-token',
        method: 'POST',
        body: { refreshToken }
      })
    })
  })
})

export const { useAdminLoginMutation, useRefreshTokenMutation } = adminApi
