import { useDispatch } from 'react-redux'
import { ActionCreatorWithPayload, bindActionCreators } from '@reduxjs/toolkit'
import { adminActions } from '../store/slice/admin.slice'
import { ISignInRes } from '../store/api/admin/model'

const actions = {
  ...adminActions
}

export const useActions = (): {
  addAdminInfo: ActionCreatorWithPayload<ISignInRes, 'admin/addAdminInfo'>
} => {
  const dispatch = useDispatch()
  return bindActionCreators(actions, dispatch)
}
