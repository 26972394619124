import 'bootstrap/dist/css/bootstrap.min.css'
import { FC } from 'react'
import Select from 'react-select'
import makeAnimated from 'react-select/animated'
import { ISingleSelect } from './model'

const animatedComponents = makeAnimated()

const SingleSelect: FC<ISingleSelect> = ({
  setData,
  data,
  editData,
  deleteErrormessage,
  field,
  changeData
}): JSX.Element => {
  const handleSelectChange = (selectedOptions): void => {
    setData(selectedOptions)
    changeData?.(field, selectedOptions.value)
    deleteErrormessage?.(prev => ({ ...prev, [field]: '' }))
  }
  return (
    <Select
      options={data}
      components={animatedComponents}
      name='singleSelect'
      value={editData}
      onChange={handleSelectChange}
      placeholder='Select...'
      styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
      menuPortalTarget={document.body}
    />
  )
}

export default SingleSelect
