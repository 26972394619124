import React, { FC } from 'react'
import 'bootstrap/dist/css/bootstrap.min.css'
import { ICreateChoice } from '../../../model'

const CreateSurveyQuestionsChoice: FC<ICreateChoice> = ({
  choice,
  choiceIndex,
  questionIndex,
  choicesLength,
  setQuestions
}): JSX.Element => {
  const handleChangeChoice = (
    field: string,
    value: string | number,
    questionIndex: number,
    choiceIndex: number
  ): void => {
    setQuestions?.(prevQuestions =>
      prevQuestions.map((question, qIndex) => {
        if (qIndex === questionIndex && question.choices) {
          const newChoices = question.choices.map((choice, cIndex) => {
            if (field === 'score' && cIndex !== choiceIndex && choice.score === value) {
              const tempScore = question.choices[choiceIndex].score
              question.choices[choiceIndex].score = choice.score
              choice.score = tempScore
            }
            return cIndex === choiceIndex ? { ...choice, [field]: value } : choice
          })
          return {
            ...question,
            choices: newChoices
          }
        }
        return question
      })
    )
  }

  const handleAddChoice = (questionIndex: number): void => {
    setQuestions?.(prevQuestions =>
      prevQuestions.map((question, index) =>
        index === questionIndex
          ? {
              ...question,
              choices: [
                ...(question.choices || []),
                {
                  ...question.choices[question.choices.length - 1],
                  score: Math.max(0, ...(question.choices || []).map(c => c.score)) + 1,
                  text: ''
                }
              ]
            }
          : question
      )
    )
  }
  const handleRemoveChoice = (questionIndex: number, choiceIndex: number): void => {
    setQuestions?.(prevQuestions =>
      prevQuestions.map((question, index) =>
        index === questionIndex && question.choices
          ? {
              ...question,
              choices: question.choices.filter((_, idx) => idx !== choiceIndex)
            }
          : question
      )
    )
  }
  return (
    <div>
      <div className='d-flex align-items-center  mb-3 mx-2'>
        <input
          type='number'
          value={choice.score || ''}
          placeholder='score'
          onChange={e =>
            handleChangeChoice('score', Number(e.target.value), questionIndex, choiceIndex)
          }
          className='form-control mx-2'
          style={{ width: '100px' }}
        />
        <input
          type='text'
          value={choice.text || ''}
          placeholder={` ${choice.score ? 'option' + choice.score : 'Write uniq score'}`}
          onChange={e => handleChangeChoice('text', e.target.value, questionIndex, choiceIndex)}
          className='form-control me-2'
        />
        {!choiceIndex ? (
          <button
            onClick={() => handleAddChoice(questionIndex)}
            className='btn btn-warning btn-sm px-5'
            disabled={Boolean(choicesLength && choicesLength > 5)}
          >
            Add Choice
          </button>
        ) : (
          <svg
            fill='rgb(17, 17, 17)'
            height='30'
            viewBox='0 0 24 24'
            onClick={() => handleRemoveChoice(questionIndex, choiceIndex)}
            style={{ cursor: 'pointer' }}
          >
            <path d='M0 0h24v24H0V0z' fill='none'></path>
            <path d='M6 19c0 1.1.9 2 2 2h8c1.1 0 2-.9 2-2V7H6v12zM8 9h8v10H8V9zm7.5-5-1-1h-5l-1 1H5v2h14V4z'></path>
          </svg>
        )}
      </div>
    </div>
  )
}

export default CreateSurveyQuestionsChoice
