import { createApi } from '@reduxjs/toolkit/dist/query/react'
import { baseQueryWithReauth } from '../apiSlice'
import { IPackage } from './model'

export const packagesApi = createApi({
  reducerPath: 'packages/api',
  baseQuery: baseQueryWithReauth,
  endpoints: build => ({
    getPackages: build.query<IPackage[], void>({
      query: () => ({ url: '/admin/packages' })
    })
  })
})

export const { useGetPackagesQuery } = packagesApi
