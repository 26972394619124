import { createApi } from '@reduxjs/toolkit/query/react'
import { ICreateCategory, IGetCategory } from './model'
import { baseQueryWithReauth } from '../apiSlice'

export const categoryApi = createApi({
  reducerPath: 'category/Api',
  baseQuery: baseQueryWithReauth,
  endpoints: build => ({
    getCategorys: build.query<IGetCategory[], void>({
      query: () => ({
        url: '/admin/category'
      })
    }),
    createCategory: build.mutation<string, ICreateCategory>({
      query: (categoryData: ICreateCategory) => ({
        url: 'admin/category',
        method: 'POST',
        body: categoryData
      })
    }),
    updateCategory: build.mutation<void, ICreateCategory>({
      query: (category: ICreateCategory) => ({
        url: '/admin/category',
        method: 'PUT',
        body: category
      })
    }),
    deleteCategory: build.mutation<void, string>({
      query: (id: string) => ({
        url: `/admin/category/${id}`,
        method: 'DELETE'
      })
    })
  })
})

export const {
  useGetCategorysQuery,
  useCreateCategoryMutation,
  useUpdateCategoryMutation,
  useDeleteCategoryMutation
} = categoryApi
