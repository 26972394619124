import { Route, Routes } from 'react-router-dom'
import * as React from 'react'
import App from '../components/App'
import LessonPlans from '../components/LessonPlans/LessonPlans'
import LessonPlansCreate from '../components/LessonPlans/LessonPlansCreate/LessonPlansCreate'

export const LessonPlansRoutes = (): React.ReactElement => {
  return (
    <Routes>
      <Route path={'/lessons-plans'} element={<App />}>
        <Route path='' element={<LessonPlans />} />
        <Route path={'/lessons-plans/create'} element={<LessonPlansCreate />} />
        <Route path={'/lessons-plans/edit/:id'} element={<LessonPlansCreate />} />
      </Route>
    </Routes>
  )
}
