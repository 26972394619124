import { createRef, useEffect, useState } from 'react'
import { Card, Col, FormGroup, Row } from 'react-bootstrap'
import { useNavigate, useParams } from 'react-router-dom'
import { responseError, validationRequaired } from '../../../validation/validationRequaired'
import { toast } from 'react-toastify'
import {
  useCreateOrUpdateSectionMutation,
  useGetSectionByIdQuery,
  useGetSectionGradesQuery
} from '../../../store/api/section/section.api'
import MultiSelect from '../../../ui/select/MultiSelect'
import { IValidationRequired } from '../../../validation/model'
import { ICreateSectionData } from '../../../store/api/section/model'
import { ISelectData } from '../../../ui/select/model'
import { useGetUsersQuery } from '../../../store/api/users/users.api'
import { USER_ROLE } from '../../../utils/constants'
import { selectedDataTake } from '../../../utils/selectedDataTake/selectedDataTake'
import { useGetSchoolQuery } from '../../../store/api/school/school.api'
import SingleSelect from '../../../ui/select/SingleSelect'
import { closeValidateRequiredError } from '../../../validation/validation'
import Select from 'react-select'
import Loading from '../../Loading/Loading'
import { extractValues } from '../../../utils/extractValues'
import { createSingleSelectData } from '../../../utils/createSingleSelectData'

const SectionCreate = (): JSX.Element => {
  const { id } = useParams()
  const navigate = useNavigate()
  const formRef = createRef<HTMLFormElement>()
  const [schoolSelected, setSchoolSelected] = useState<ISelectData>()
  const { data: schools, isLoading: isSchoolDataLoading } = useGetSchoolQuery()
  const [updateOrCreateData] = useCreateOrUpdateSectionMutation()

  const { data: getSectionGradesData } = useGetSectionGradesQuery(
    {
      schoolId: schoolSelected?.value
    },
    { skip: !schoolSelected?.value }
  )
  const { data: teachers } = useGetUsersQuery(
    {
      pageSize: 200,
      roleId: USER_ROLE.Teacher,
      schoolId: schoolSelected?.value
    },
    { skip: !schoolSelected?.value }
  )
  const { data: students } = useGetUsersQuery(
    {
      pageSize: 200,
      roleId: USER_ROLE.Student,
      schoolId: schoolSelected?.value
    },
    { skip: !schoolSelected?.value }
  )
  const { data: sectionDataById } = useGetSectionByIdQuery(id, {
    skip: !id
  })
  const [schoolData, setSchoolData] = useState<ISelectData[]>([])

  const [classes, setClasses] = useState<ISelectData[]>([])
  const [classSelect, setClassSelect] = useState<ISelectData | null>(null)

  const [selectedTeachers, setSelectedTeachers] = useState<ISelectData[]>([])
  const [dataTeachers, setDataTeachers] = useState<ISelectData[]>([])
  const [selectedStudents, setSelectedStudents] = useState<ISelectData[]>([])
  const [dataStudents, setDataStudents] = useState<ISelectData[]>([])

  const [isClassRequired, setIsClassRequired] = useState<boolean>(false)
  const [errorRequired, setErrorRequired] = useState<IValidationRequired>({})
  const [isRequiredSchool, setIsRequiredSchool] = useState<boolean>(false)
  const [isRequiredTeacher, setIsRequiredTeacher] = useState<boolean>(false)

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>): Promise<void> => {
    event.preventDefault()
    const createSectionData: ICreateSectionData = {
      schoolId: schoolSelected?.value,
      classId: classSelect?.value,
      section: formRef.current?.section?.value,
      teacherIds: extractValues(selectedTeachers)
    }
    if (!classSelect) {
      setIsClassRequired(true)
    }

    if (createSectionData.teacherIds.length === 0) {
      setIsRequiredTeacher(true)
    }

    typeof schoolSelected === 'undefined' && setIsRequiredSchool(true)

    if (selectedStudents.length > 0) {
      createSectionData.studentIds = extractValues(selectedStudents)
    }
    setErrorRequired(validationRequaired(createSectionData))
    const validationErrors = Object.values(validationRequaired(createSectionData))
    if (validationErrors.length === 0) {
      const updatedData = id ? { ...createSectionData, id: Number(id) } : createSectionData

      try {
        const res = await updateOrCreateData(updatedData)

        if ('error' in res) {
          responseError(res)
        } else {
          const successMessage = id ? 'School was updated' : 'School was created'
          toast.success(successMessage)
          navigate(-1)
        }
      } catch (error) {
        responseError(error)
      }
    }
  }

  const setUserData = (userData, setDataFunction): void => {
    if (userData?.data?.length > 0) {
      setDataFunction(() => {
        return userData.data.map(user => ({
          label: (user?.student?.id || user?.teacher?.id) + '. ' + user.fullName,
          value: user?.student?.id || user?.teacher?.id
        }))
      })
    } else {
      setDataFunction([])
    }
  }

  useEffect(() => {
    selectedDataTake(getSectionGradesData?.classes, setClasses)
  }, [getSectionGradesData])

  useEffect(() => {
    if (classSelect?.label) {
      setIsClassRequired(false)
    }
  }, [classSelect])

  useEffect(() => {
    if (selectedTeachers?.length > 0) {
      setIsRequiredTeacher(false)
    }
  }, [selectedTeachers])

  useEffect(() => {
    setUserData(students, setDataStudents)
  }, [students])

  useEffect(() => {
    setUserData(teachers, setDataTeachers)
  }, [teachers])

  useEffect(() => {
    selectedDataTake(schools, setSchoolData)
  }, [schools])

  useEffect(() => {
    if (sectionDataById && id) {
      if (sectionDataById?.schoolId) {
        const findSchool = schools?.find(school => school.id === sectionDataById.schoolId)
        if (findSchool) {
          setSchoolSelected(createSingleSelectData(findSchool.name, findSchool.id))
        }
      }
      if (sectionDataById?.students) {
        setSelectedStudents(() => {
          return sectionDataById.students.map(item =>
            createSingleSelectData(item.user.fullName, item.user.id)
          )
        })
      }
      if (sectionDataById?.class && id) {
        setClassSelect(createSingleSelectData(sectionDataById.class.name, sectionDataById.class.id))
      }
      if (sectionDataById?.teachers) {
        setSelectedTeachers(() => {
          return sectionDataById.teachers.map(item =>
            createSingleSelectData(item.user.fullName, item.user.id)
          )
        })
      }
    }
  }, [sectionDataById, schools])

  return (
    <div>
      {isSchoolDataLoading && <Loading />}
      <Card.Header>
        <div>
          <h2>{id ? 'Edit Section' : 'Create Section'} </h2>
        </div>
      </Card.Header>
      <Row>
        <Col lg={12} xl={12} md={12} sm={12}>
          <Card>
            <form ref={formRef} onSubmit={event => handleSubmit(event)}>
              <Card.Body>
                <Row>
                  <Col xs={6} md={12}>
                    <label className='text-dark' htmlFor='exampleInputname'>
                      School
                    </label>
                    <Select
                      options={schoolData}
                      name='singleSelect'
                      value={schoolSelected}
                      styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                      menuPortalTarget={document.body}
                      onChange={newValue => {
                        setSchoolSelected(newValue)
                        setClasses([])
                        setClassSelect(null)
                        setSelectedStudents([])
                        setSelectedTeachers([])
                        setIsRequiredSchool(false)
                      }}
                      placeholder='Select...'
                    />
                    {isRequiredSchool ? (
                      <span className='text-danger'>school is required</span>
                    ) : null}
                  </Col>
                  {schoolSelected?.value ? (
                    <>
                      <Col xs={6} md={12}>
                        <FormGroup>
                          <label className='text-dark mt-5' htmlFor='exampleInputName'>
                            Class
                          </label>
                          <SingleSelect
                            data={classes}
                            editData={classSelect}
                            setData={setClassSelect}
                          />
                        </FormGroup>
                        {isClassRequired ? (
                          <span className='text-danger'>class is required</span>
                        ) : null}
                      </Col>

                      <Col xs={6} md={12}>
                        <label className='text-dark mt-5' htmlFor='exampleInputname'>
                          Teacher
                        </label>
                        <MultiSelect
                          data={dataTeachers}
                          setData={setSelectedTeachers}
                          editData={selectedTeachers}
                          deleteErrormessage={setErrorRequired}
                          field='teacherId'
                        />
                        {isRequiredTeacher ? (
                          <span className='text-danger'>teacher is required</span>
                        ) : null}
                      </Col>
                      <Col xs={6} md={12}>
                        <label className='text-dark mt-5' htmlFor='exampleInputname'>
                          Student
                        </label>
                        <MultiSelect
                          data={dataStudents}
                          setData={setSelectedStudents}
                          editData={selectedStudents}
                          deleteErrormessage={setErrorRequired}
                          field='studentIds'
                        />
                      </Col>
                    </>
                  ) : null}
                  <Col xs={6} md={12} className='mb-5'>
                    <FormGroup>
                      <label className='text-dark mt-5' htmlFor='section'>
                        Section
                      </label>
                      <input
                        defaultValue={sectionDataById?.name}
                        id='section'
                        name='section'
                        type='text'
                        className='form-control'
                        placeholder='Section'
                        onClick={() => {
                          closeValidateRequiredError('section', errorRequired, setErrorRequired)
                        }}
                      />
                      {errorRequired?.section && (
                        <span className='text-danger'>{errorRequired.section}</span>
                      )}
                    </FormGroup>
                  </Col>
                </Row>
              </Card.Body>
              <Card.Footer className='text-end'>
                <button className='btn btn-success mt-1 me-2' type='submit'>
                  Save
                </button>
                <button
                  type='button'
                  className='btn btn-danger mt-1 me-2'
                  onClick={() => navigate(-1)}
                >
                  Cancel
                </button>
              </Card.Footer>
            </form>
          </Card>
        </Col>
      </Row>
    </div>
  )
}

export default SectionCreate
