import { createApi } from '@reduxjs/toolkit/query/react'
import { baseQueryWithReauth } from '../apiSlice'
import {
  ICreateSectionData,
  IGetSectionGradesInput,
  IGetSectionGradesResponse,
  IGetSections,
  IGetSectionsById,
  IGetStudentSectionApi
} from './model'
import { ISearchParams } from '../../../ui/filter/model'

export const sectionsApi = createApi({
  reducerPath: 'section/api',
  baseQuery: baseQueryWithReauth,
  endpoints: build => ({
    getSections: build.query<IGetSections, ISearchParams | void>({
      query: params => ({
        url: '/admin/sections',
        params: { ...params }
      })
    }),

    getSectionById: build.query<IGetSectionsById, string>({
      query: (id: string) => ({ url: `/admin/section/${id}` })
    }),
    createOrUpdateSection: build.mutation<IGetSectionGradesResponse, ICreateSectionData>({
      query: (data: ICreateSectionData) => ({
        url: '/admin/section',
        method: 'PUT',
        body: data
      })
    }),
    deleteSection: build.mutation<void, string>({
      query: (id: string) => ({ url: `/admin/section/${id}`, method: 'DELETE' })
    }),

    getSectionGrades: build.query<IGetSectionGradesResponse, IGetSectionGradesInput>({
      query: params => {
        if (params.schoolId) {
          return {
            url: '/admin/sections-grades',
            params
          }
        }
      }
    }),
    getSectionStudent: build.query<IGetStudentSectionApi[], number>({
      query: classId => {
        if (classId) {
          return {
            url: '/teacher/student-sections',
            params: { classId }
          }
        }
      }
    })
  })
})

export const {
  useGetSectionsQuery,
  useGetSectionByIdQuery,
  useGetSectionGradesQuery,
  useCreateOrUpdateSectionMutation,
  useDeleteSectionMutation,
  useGetSectionStudentQuery
} = sectionsApi
