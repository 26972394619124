import { Route, Routes } from 'react-router-dom'
import App from '../components/App'
import School from '../components/School/School'
import SchoolCreate from '../components/School/SchoolCreate/SchoolCreate'

export const SchoolsRoutes = (): React.ReactElement => {
  return (
    <Routes>
      <Route path={'/schools'} element={<App />}>
        <Route path='' element={<School />} />
        <Route path='/schools/create' element={<SchoolCreate />} />
        <Route path='/schools/edit/:id' element={<SchoolCreate />} />
      </Route>
    </Routes>
  )
}
