import { Id, toast } from 'react-toastify'
import { ICustomErrorPayload, ICustomResponsePayload } from '../store/api/users/model'

export const CustomMessageSuccsesOrError = (res): Id => {
  if ('error' in res) {
    return toast.error((res as ICustomErrorPayload).error.data.message)
  } else if ('data' in res && 'message' in res.data) {
    const successResponse = res as ICustomResponsePayload
    return toast.success(successResponse.data.message)
  }
  return null
}
