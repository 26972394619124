import { Button, Col, Row } from 'react-bootstrap'
import React, { FC } from 'react'
import { IFilterData } from './model'
import 'bootstrap/dist/css/bootstrap.min.css'

const Filters: FC<IFilterData> = ({ setQuery, query }): JSX.Element => {
  const formRef = React.createRef<HTMLFormElement>()

  const filtration = (event?: React.ChangeEvent<HTMLInputElement>): void => {
    query.set(event.target.name, event.target.value)
    const page = query.get('page')
    if (page !== '1') {
      query.set('page', '1')
    }
    setQuery(query)
  }

  const clearInput = (): void => {
    formRef.current.keyword.value = ''

    const updatedQuery = new URLSearchParams(query)
    updatedQuery.delete('keyword')
    setQuery(updatedQuery)
  }

  return (
    <form ref={formRef}>
      <Row className='mt-5 mb-5'>
        <Col md={6} lg={4} xl={2}>
          <input
            defaultValue={query.get('keyword')}
            type='text'
            name='keyword'
            className='bg-white-light form-control'
            placeholder='Search'
            onChange={e => filtration(e)}
          />
        </Col>

        <Col md={6} xl={6}>
          <Button onClick={() => clearInput()}>Reset</Button>
        </Col>
      </Row>
    </form>
  )
}

export default Filters
