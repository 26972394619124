import { Route, Routes } from 'react-router-dom'
import App from '../components/App'
import Quiz from '../components/Quiz/Quiz'
import QuizCreate from '../components/Quiz/QuizCreate/QuizCreate'

export const QuizRoutes = (): React.ReactElement => {
  return (
    <Routes>
      <Route path={'/quizzes'} element={<App />}>
        <Route path='' element={<Quiz />} />
        <Route path='/quizzes/create' element={<QuizCreate />} />
        <Route path='/quizzes/edit/:id' element={<QuizCreate />} />
      </Route>
    </Routes>
  )
}
