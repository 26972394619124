import { createApi } from '@reduxjs/toolkit/query/react'
import { IUpdateAndGetQuestion } from './model'
import { baseQueryWithReauth } from '../apiSlice'
import { TSendExcelResponsOrError } from '../users/model'

export const questionApi = createApi({
  reducerPath: 'question/api',
  baseQuery: baseQueryWithReauth,
  endpoints: build => ({
    getQuestions: build.query<IUpdateAndGetQuestion[], void>({
      query: () => ({ url: '/admin/question' })
    }),

    getQuestionById: build.query<IUpdateAndGetQuestion, string>({
      query: (id: string) => ({ url: `/admin/question/${id}` })
    }),

    createQuestion: build.mutation<void, IUpdateAndGetQuestion>({
      query: (data: IUpdateAndGetQuestion) => ({
        url: '/admin/question',
        method: 'POST',
        body: data
      })
    }),
    sendExelFile: build.mutation<TSendExcelResponsOrError, FormData>({
      query: (data: FormData) => ({
        url: '/admin/import-questions',
        method: 'POST',
        body: data
      })
    }),

    updateQuestion: build.mutation<void, IUpdateAndGetQuestion>({
      query: (data: IUpdateAndGetQuestion) => ({
        url: '/admin/question',
        method: 'PUT',
        body: data
      })
    }),

    deleteQuestion: build.mutation<void, string>({
      query: (id: string) => ({ url: `/admin/question/${id}`, method: 'DELETE' })
    })
  })
})

export const {
  useGetQuestionsQuery,
  useGetQuestionByIdQuery,
  useCreateQuestionMutation,
  useSendExelFileMutation,
  useUpdateQuestionMutation,
  useDeleteQuestionMutation
} = questionApi
