import { FC } from 'react'

const Categorys: FC<{
  setCategorys: React.Dispatch<React.SetStateAction<string[]>>
  index: number
}> = ({ setCategorys, index }): JSX.Element => {
  const changeCategorys = (value: string, index): void => {
    setCategorys(prev => {
      return prev.map((e, i) => {
        if (i === index) {
          e = value
        }
        return e
      })
    })
  }
  const removeItem = (index: number): void => {
    setCategorys(prev => [...prev.filter((_, i) => i !== index)])
  }
  return (
    <div className='d-flex align-items-center mb-3'>
      <input
        id='image'
        name='image'
        type='text'
        className='form-control'
        placeholder='category'
        onChange={e => changeCategorys(e.target.value, index)}
      />
      <i onClick={() => removeItem(index)} className={'side-menu__icon fa fa-minus mx-2'}></i>
    </div>
  )
}
export default Categorys
