import { FC } from 'react'
import { IGetLessonsStats } from '../../../store/api/deshboard/model'

const NewLessonsStats: FC<{ lessonsStat: IGetLessonsStats }> = ({ lessonsStat }): JSX.Element => {
  return (
    <li>
      <i className='task-icon bg-secondary'></i>
      <h6>{lessonsStat.name}</h6>
      <p className='fs-12'>
        has been assigned to
        <b className='text-dark'> {lessonsStat.classes} </b>
        classes and
        <b className='text-dark'> {lessonsStat.studentsCount} </b>
        students.
      </p>
    </li>
  )
}

export default NewLessonsStats
