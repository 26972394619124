import { ChangeEvent, useEffect, useRef } from 'react'
import { Button, Card, Col, Row, Table } from 'react-bootstrap'
import {
  useGetLessonQuery,
  useSendExelFileLessonsMutation
} from '../../store/api/lesson/lesson.api'
import { useNavigate, useSearchParams } from 'react-router-dom'
import LessonTable from './LessonTable/LessonTable'
import Pagination from '../../ui/Pagination/Pagination'
import Loading from '../Loading/Loading'
import { searchParamsData } from '../../utils/searchParamsData'
import Filters from '../../ui/filter/Filters'
import { CustomMessageSuccsesOrError } from '../../utils/CustomMessageSuccsesOrError'
import { DefaultParams, EXCEL_FORMAT, PAGESIZE } from '../../utils/constants'

const Lesson = (): JSX.Element => {
  const navigate = useNavigate()
  const [searchParams, setSearchParams] = useSearchParams(DefaultParams)
  const inputRef = useRef<HTMLInputElement>()
  const {
    isLoading,
    data: lessons,
    refetch
  } = useGetLessonQuery(searchParamsData(searchParams, {}))
  const [sendExel] = useSendExelFileLessonsMutation()

  const handleFileChange = async (event: ChangeEvent<HTMLInputElement>): Promise<void> => {
    const selectedFile = event.target.files?.[0]
    const formData = new FormData()
    if (selectedFile) {
      formData.append('file', selectedFile)
      const res = await sendExel(formData)
      CustomMessageSuccsesOrError(res)
      refetch()
    }
  }

  useEffect(() => {
    refetch()
  }, [searchParams])

  return (
    <div>
      {isLoading && <Loading />}
      <Filters setQuery={setSearchParams} query={searchParams} />
      <Row className='row-sm'>
        <Col lg={12}>
          <Card className='custom-card'>
            <Card.Header>
              <Col xs={6} md={10}>
                <h3 className='card-title mb-1'>Lessons</h3>
              </Col>
              <Col className='d-flex'>
                <div className='mx-3'>
                  <input
                    ref={inputRef}
                    onChange={e => handleFileChange(e)}
                    className='d-none'
                    type='file'
                    accept={EXCEL_FORMAT}
                  />
                  <button
                    type='button'
                    onClick={() => inputRef?.current.click()}
                    className='btn btn-success'
                  >
                    <span>
                      <i className='fe fe-log-in'></i>&nbsp;
                    </span>
                    Import
                  </button>
                </div>
                <Button onClick={() => navigate('/lesson/create')}>Create Lesson</Button>
              </Col>
            </Card.Header>
            <Card.Body>
              <div className='table-responsive'>
                <Table className='table table-bordered text-nowrap text-md-nowrap mg-b-0'>
                  <thead>
                    <tr className='text-center'>
                      <th>order</th>
                      <th>Name</th>
                      <th>Photo</th>
                      <th>Description</th>
                      <th>Duration</th>
                      <th>Url</th>
                      <th>Start Date</th>
                      <th>Created at</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {lessons?.data.length
                      ? lessons?.data.map(lesson => {
                          return <LessonTable key={lesson.id} lesson={lesson} refetch={refetch} />
                        })
                      : null}
                  </tbody>
                </Table>
              </div>
            </Card.Body>
            <Pagination
              setQuery={setSearchParams}
              numPages={Math.ceil(lessons?.count / PAGESIZE)}
              query={searchParams}
            />
          </Card>
        </Col>
      </Row>
    </div>
  )
}

export default Lesson
