import { IValidationRequired, TKeyValueObj, TValidationFunc } from './model'

export const regex = {
  email: /[a-z0-9]+@[a-z]+.[a-z]{1}/,
  duration: /^[a-zA-Z0-9]{2}/,
  description: /^[a-zA-Z0-9]{2}/,
  name: /^[a-zA-Z0-9]{2}/,
  surname: /^[a-zA-Z0-9]{2}/,
  password: /^[a-zA-Z0-9]{6}/,
  roleId: /^[0-9]$/,
  id: /^[0-9]$/
}

export const validationFunc = (data: TValidationFunc): TKeyValueObj => {
  const errors = {}
  Object.keys(data).forEach(key => {
    const a = regex[key].test(data[key])
    if (!a) {
      errors[key] = a
    }
  })
  return errors
}

export const closeValidateRequiredError = (
  field: string,
  errorMessage: IValidationRequired,
  setErrorMessage: React.Dispatch<React.SetStateAction<IValidationRequired>>
): void => {
  if (errorMessage[field]) {
    setErrorMessage(prev => {
      return {
        ...prev,
        [field]: ''
      }
    })
  }
}

export const closeValidateSintaxError = (
  setValidationSintaxError: React.Dispatch<React.SetStateAction<TKeyValueObj>>
): void => {
  setValidationSintaxError(prev => {
    return {
      ...prev,
      name: true
    }
  })
}

export const isUrlValid = (string: string): boolean => {
  const urlPattern = /^(?:\w+:)?\/\/([^\s.]+\.\S{2}|localhost[:?\d]*)\S*$/
  return urlPattern.test(string)
}

export const inValidDateTime = (startDateTimeValue: Date, endDateTimeValue: Date): boolean => {
  if (startDateTimeValue > endDateTimeValue) {
    return false
  } else {
    return true
  }
}
