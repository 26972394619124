import { toast } from 'react-toastify'
import { IValidationRequired, TErrorResponse, TValidationFunc } from '../validation/model'

export const validationRequaired = (data: TValidationFunc): IValidationRequired => {
  const error: IValidationRequired = {}

  Object.keys(data).forEach(key => {
    if (!data[key] || data[key].length < 1) {
      error[`${key}`] = `${key} is required`
    }
    if (key === 'password' && data[key].length < 6) {
      error[`${key}`] = `${key} must be longer than or equal to 6 characters`
    }
    if (key === 'password' && data[key].length > 20) {
      error[`${key}`] = `${key} must be shorter than or equal to 20 characters`
    }
  })
  return error
}

export const responseError = (res: TErrorResponse): void => {
  if ('error' in res) {
    if ('data' in res.error && typeof res.error.data === 'object' && 'message' in res.error.data) {
      const errorMessage = res.error.data.message
      if (Array.isArray(errorMessage)) {
        toast.error(errorMessage[0])
      } else if (typeof errorMessage === 'string') {
        toast.error(errorMessage)
      }
    }
  }
}
