import { Route, Routes } from 'react-router-dom'
import * as React from 'react'
import App from '../components/App'
import Welcome from '../components/Welcome/Welcome'
import WelcomeCreate from '../components/Welcome/WelcomeCreate/WelcomeCreate'
import WelcomeChoiceList from '../components/Welcome/WelcomeChoiceList/WelcomeChoiceList'

export const WelcomeRoutes = (): React.ReactElement => {
  return (
    <Routes>
      <Route path={'/welcome'} element={<App />}>
        <Route path='' element={<Welcome />} />
        <Route path='/welcome/create' element={<WelcomeCreate />} />
        <Route path='/welcome/edit/:id' element={<WelcomeCreate />} />
        <Route path='/welcome/:id/choice' element={<WelcomeChoiceList />} />
      </Route>
    </Routes>
  )
}
