import { useEffect, useState } from 'react'
import { Button, Card, Col, Row, Table } from 'react-bootstrap'
import { useSearchParams } from 'react-router-dom'
import { useGetClassQuery } from '../../store/api/class/class.api'
import { IGetClassesData } from '../../store/api/class/model'
import Pagination from '../../ui/Pagination/Pagination'
import Filters from '../../ui/filter/Filters'
import { searchParamsData } from '../../utils/searchParamsData'
import Loading from '../Loading/Loading'
import ClassCreate from './ClassCreate/ClassCreate'
import ClassTable from './ClassTable/ClassTable'
import { DefaultParams, PAGESIZE } from '../../utils/constants'

const Class = (): JSX.Element => {
  const [isOpenCreateModal, setOpenCreateModal] = useState<boolean>(false)
  const [editClass, setEditClass] = useState<IGetClassesData>()
  const [searchParams, setSearchParams] = useSearchParams(DefaultParams)
  const { isLoading, data: classes, refetch } = useGetClassQuery(searchParamsData(searchParams, {}))
  useEffect(() => {
    if (!isOpenCreateModal) {
      refetch()
    }
  }, [isOpenCreateModal])

  useEffect(() => {
    refetch()
  }, [searchParams])

  return (
    <div>
      {isLoading && <Loading />}
      <Filters setQuery={setSearchParams} query={searchParams} />
      <Row className=' row-sm'>
        <Col lg={12}>
          <Card className='custom-card'>
            <Card.Header>
              <Col xs={6} md={11}>
                <h3 className='card-title mb-1'>Classes</h3>
              </Col>
              <Col>
                <Button
                  onClick={() => {
                    setOpenCreateModal(true)
                    setEditClass(null)
                  }}
                >
                  Create Classes
                </Button>
              </Col>
            </Card.Header>
            <Card.Body>
              <div className='table-responsive'>
                <Table className='table table-bordered text-nowrap text-md-nowrap mg-b-0'>
                  <thead>
                    <tr className='text-center'>
                      <th>ID</th>
                      <th>Name</th>
                      <th>Description</th>
                      <th>Section</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {classes?.data.map(clas => (
                      <ClassTable
                        key={clas.id}
                        clas={clas}
                        refetch={refetch}
                        setOpenCreateModal={setOpenCreateModal}
                        setEditClass={setEditClass}
                      />
                    ))}
                  </tbody>
                </Table>
              </div>
            </Card.Body>
            <Pagination
              setQuery={setSearchParams}
              numPages={Math.ceil(classes?.count / PAGESIZE)}
              query={searchParams}
            />
          </Card>
        </Col>
      </Row>
      {isOpenCreateModal && (
        <ClassCreate onClose={() => setOpenCreateModal(false)} editClass={editClass} />
      )}
    </div>
  )
}

export default Class
