import { createApi } from '@reduxjs/toolkit/query/react'
import { ICreateQuiz, IGetQuiz } from './model'
import { baseQueryWithReauth } from '../apiSlice'

export const quizApi = createApi({
  reducerPath: 'quiz/api',
  baseQuery: baseQueryWithReauth,
  endpoints: build => ({
    getQuiz: build.query<IGetQuiz[], void>({
      query: () => ({ url: '/admin/quiz' })
    }),

    getQuizById: build.query<IGetQuiz, string>({
      query: (id: string) => ({ url: `/admin/quiz/${id}` })
    }),

    createQuiz: build.mutation<void, ICreateQuiz>({
      query: (data: ICreateQuiz) => ({
        url: '/admin/quiz',
        method: 'POST',
        body: data
      })
    }),

    updateQuiz: build.mutation<void, ICreateQuiz>({
      query: (data: ICreateQuiz) => ({
        url: '/admin/quiz',
        method: 'PUT',
        body: data
      })
    }),

    deleteQuiz: build.mutation<void, string>({
      query: (id: string) => ({ url: `/admin/quiz/${id}`, method: 'DELETE' })
    })
  })
})

export const {
  useGetQuizQuery,
  useGetQuizByIdQuery,
  useCreateQuizMutation,
  useUpdateQuizMutation,
  useDeleteQuizMutation
} = quizApi
