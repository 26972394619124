import React, { FC } from 'react'
import { Breadcrumb } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import { IPageHeaderProps } from '../model'

const PageHeader: FC<IPageHeaderProps> = (props): JSX.Element => {
  return (
    <div>
      <div className='page-header '>
        <div>
          <h1 className='page-title'>{props.componentName}</h1>
          <Breadcrumb className='breadcrumb'>
            <Breadcrumb.Item className='breadcrumb-item' href='#'>
              {props.staticName}
            </Breadcrumb.Item>
            <Breadcrumb.Item className='breadcrumb-item active breadcrumds' aria-current='page'>
              {props.activePage}
            </Breadcrumb.Item>
          </Breadcrumb>
        </div>
        {props.createPage && props.createPageUrl ? (
          <div className='ms-auto pageheader-btn'>
            <Link to={props.createPageUrl} className='btn btn-primary btn-icon text-white me-3'>
              <span>
                <i className='fe fe-plus'></i>&nbsp;
              </span>
              {props.createPage}
            </Link>
          </div>
        ) : null}
      </div>
    </div>
  )
}
export default PageHeader
